import React, { useState, useEffect } from 'react';
import { BuscadorFiltros, ModuleNews, PaginatorSimple } from 'React/components';

const RelevantFactsList = ({ title, type, hideCombo }) => {
    const [newsListState, setNewsListState] = useState([]);
    const [actualPageState, setActualPageState] = useState(1);
    const [yearFilterState, setYearFilterState] = useState('2020');
    const [actualPagesList, setActualPagesList] = useState([]);
    const [pagesListState, setPagesListState] = useState([]);


    const itemPerPage = 9;

    const yearsCombo = ["2020", "2019", "2018", "2017", "2016", "2015", "2014", "2013", "2012", "2011", "2010"];

    const getRelevantFactsList = (culture, year) => {
        var relevantFactsList = [];

        var url = `/api/CmsController/GetRelevantFacts?language=${culture}&filter=${type}&year=${year}`;
        const xhrPages = new XMLHttpRequest();
        xhrPages.open('get', url, true);
        xhrPages.onload = function (e) {
            if (xhrPages.readyState === 4) {
                if (xhrPages.status === 200) {
                    relevantFactsList = JSON.parse(xhrPages.responseText);

                    setActualPagesList(relevantFactsList);
                    
                    var pagesList = [];
                    var listSize = Math.ceil(relevantFactsList.length / itemPerPage);

                    for (var i = 0; i !== listSize; i++) {
                        var page = { num: i + 1 };
                        pagesList.push(page);
                    }

                    var downloadsList = paginate(relevantFactsList, itemPerPage, actualPageState);

                    if (downloadsList.length < itemPerPage) {
                        var ej1 = {
                            title: "",
                            file: "",
                            date: "",
                            thumbnail: "",
                            recordNumber: ""
                        };
                        var size = itemPerPage - downloadsList.length;
                        for (var j = 0; j < size; j++) {
                            downloadsList.push(ej1);
                        }
                    }

                    setNewsListState(downloadsList);
                    setPagesListState(pagesList);

                } else {
                    console.error(xhrPages.statusText);
                }
            }
        }.bind(this);

        xhrPages.send(null);
    }

    function paginate(array, page_size, page_number) {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const changePage = (page) => {
        setActualPageState(page);

        var downloadsList = paginate(actualPagesList, itemPerPage, page);

        if (downloadsList.length < itemPerPage) {
            var ej1 = {
                title: "",
                file: "",
                date: "",
                thumbnail: "",
                recordNumber: ""
            };
            var size = itemPerPage - downloadsList.length;
            for (var j = 0; j < size; j++) {
                downloadsList.push(ej1);
            }
        }

        setNewsListState(downloadsList);
    };

    const nextPage = () => {
        if (actualPageState < pagesListState.length) {
            setActualPageState(actualPageState + 1);
            var downloadsList = paginate(actualPagesList, itemPerPage, actualPageState + 1);

            if (downloadsList.length < itemPerPage) {
                var ej1 = {
                    title: "",
                    file: "",
                    date: "",
                    thumbnail: "",
                    recordNumber: ""
                };
                var size = itemPerPage - downloadsList.length;
                for (var j = 0; j < size; j++) {
                    downloadsList.push(ej1);
                }
            }

            setNewsListState(downloadsList);
        }
    };

    const prevPage = () => {
        if (actualPageState > 1) {
            setActualPageState(actualPageState - 1);
            var downloadsList = paginate(actualPagesList, itemPerPage, actualPageState - 1);

            if (downloadsList.length < itemPerPage) {
                var ej1 = {
                    title: "",
                    file: "",
                    date: "",
                    thumbnail: "",
                    recordNumber: ""
                };
                var size = itemPerPage - downloadsList.length;
                for (var j = 0; j < size; j++) {
                    downloadsList.push(ej1);
                }
            }

            setNewsListState(downloadsList);
        }
    };

    const setFilter = (event) => {
        setYearFilterState(event.target.value);
        setActualPageState(1);

        var culture = "es";

        getRelevantFactsList(culture, event.target.value);
    };

    useEffect(() => {
        var culture = "es";

        getRelevantFactsList(culture, yearFilterState);

    }, [setPagesListState]);

    return (
        <div className='pagination-list-container'>
            <BuscadorFiltros type='noticias' yearFilter={setFilter} title={`${title} (${actualPagesList.length})`} yearsCombo={yearsCombo} yearSelected={yearFilterState} hideCombo={hideCombo} />
            <ModuleNews type='hechos' content={newsListState} />
            {pagesListState.length > 1 &&
                <PaginatorSimple pagesList={pagesListState} actualPage={actualPageState} changePage={changePage} nextPage={nextPage} prevPage={prevPage} />
            }
        </div>
    );
}

export default RelevantFactsList;