import React, { useState, useEffect } from 'react';
import { useSliderSwipe, useSlowFade } from 'React/custom_hooks';

import './style.scss';
import { TagTitle, Link, EnlaceAgrupador, InputRangeSlider } from 'React/components';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';
import { rndKeyGen } from 'React/helpers';

// icons
import { ReactComponent as ArrowRight } from 'Icons/arrow_circled.svg';
import { ReactComponent as Energia } from 'Icons/wind-turbine-24.svg';
import { ReactComponent as Water } from 'Icons/water-24.svg';

const Proyectos = ({ type, tagTitle, cta, frames }) => {
    const swipableComponent = React.createRef();
    const [activeFrame, setActiveFrame] = useState(1);

    const prevButton = React.createRef();
    const nextButton = React.createRef();
    let moduleRef = React.createRef();
    let ghostRight = React.createRef();
    let ghostLeft = React.createRef();

    const solutionsIconsMap = {
        energía: <Energia width="18" height="18" viewBox="0 0 22 22" />,
        agua: <Water width="18" height="18" viewBox="0 0 22 22" />,
    };

    useEffect(() => {
        var nodes = document.querySelectorAll('.component_proyectos');
        var last = nodes[nodes.length - 1];
        last.classList.add('last-item');

        if (frames.length > 1 && parseInt(activeFrame) === frames.length && ghostRight.current != null) {
            ghostRight.current.classList.remove('ghost-frame-button');
        } else {
            if (ghostRight.current != null) {
                ghostRight.current.classList.add('ghost-frame-button');
            }
        }

        if (parseInt(activeFrame) <= 1 && ghostLeft.current != null) {
            ghostLeft.current.classList.remove('ghost-frame-button');
        } else {
            if (ghostLeft.current != null) {
                ghostLeft.current.classList.add('ghost-frame-button');
            }
        }
    });

    const nextHover = () => {
        nextButton.current.classList.add('is-animated');
        prevButton.current.classList.remove('is-animated');
    };

    const prevHover = () => {
        prevButton.current.classList.add('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const removeHover = () => {
        prevButton.current.classList.remove('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const nextFrame = () => {
        if (activeFrame < frames.length) {
            setActiveFrame(parseInt(activeFrame) + 1);
        }
    };

    const prevFrame = () => {
        if (activeFrame > 1) {
            setActiveFrame(parseInt(activeFrame) - 1);
        }
    };

    useSlowFade(moduleRef);
    useSliderSwipe(swipableComponent, setActiveFrame, frames.length);
    if (typeof window != 'undefined') {
    if (type === 'group') {
        return (
            <div ref={moduleRef} className="module grid component_proyectos">
                {tagTitle && (
                    <div className="proyecto-header grid-container slow--y">
                        <TagTitle title={tagTitle} />
                        {cta && <Link className="slow--y" type="arrow" label={cta} />}
                    </div>
                )}
                <div className="component_proyectos--group">
                    {frames.length > 1 && (
                        <>
                            <button
                                className="ghost-frame-button ghost-frame-button--left"
                                onMouseOver={prevHover}
                                onMouseOut={removeHover}
                                onClick={prevFrame}
                            >
                                Slider anterior
              </button>
                            <button
                                className="ghost-frame-button ghost-frame-button--right"
                                onMouseOver={nextHover}
                                onMouseOut={removeHover}
                                onClick={nextFrame}
                            >
                                Slider siguiente
              </button>
                        </>
                    )}
                    <div className="main-container" data-active-frame={activeFrame} ref={swipableComponent}>
                        <div className="backgrounds-slider" style={{ width: `${100 * frames.length}vw` }}>
                            {frames.map(({ desktopImage, mobileImage }, frame, idx) => {
                                const imageUrl = useWindowWidth() >= 768 ? desktopImage : mobileImage;

                                return (
                                    <div className="bg-frame" key={rndKeyGen()} style={{ backgroundImage: `url(${imageUrl})` }}></div>
                                );
                            })}
                        </div>

                        <div className="content-overlay grid">
                            {frames
                                .filter((frame, idx) => idx === activeFrame - 1)
                                .map((frame, idx) => {
                                    const { cifra, unit, titleLeft, caption, claim } = frame.content;

                                    return (
                                        <div className="content-main slow--y" key={rndKeyGen()}>
                                            {titleLeft && (
                                                <div className="left slow--y">
                                                    <h3 className="title--m slow--y">{titleLeft}</h3>
                                                </div>
                                            )}
                                            <div className="right slow--y">
                                                {cifra && (
                                                    <>
                                                        <p className="cifras--l slow--y">
                                                            {cifra} <small>{unit}</small>
                                                        </p>
                                                        <p className="title--xs slow--y">{caption}</p>
                                                    </>
                                                )}

                                                {claim && (
                                                    <>
                                                        <h3 className="title--m slow--y">{claim}</h3>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            {frames.length > 1 && (
                                <div className="slider-controls slow--y">
                                    <button ref={prevButton} className="prev-button" onClick={prevFrame}>
                                        <IconArrowRightLong />
                                    </button>
                                    <button ref={nextButton} className="next-button" onClick={nextFrame}>
                                        <IconArrowRightLong />
                                    </button>
                                </div>
                            )}

                            <InputRangeSlider
                                framesCount={frames.length}
                                activeFrame={parseInt(activeFrame)}
                                changeHandler={setActiveFrame}
                                noIndicator={true}
                                whiteThumb={true}
                            />

                            {frames
                                .filter((frame, idx) => idx === activeFrame - 1)
                                .map((frame, idx) => {
                                    const { name, nameIcon, type, location, link } = frame.project;
                                    return (
                                        <div className="content-footer" key={`content-footer-${idx}`}>
                                            <div className="project-details">
                                                {name && !nameIcon && (
                                                    <h4 className="title--m slow--y">
                                                        <a href="#">{name}</a>
                                                    </h4>
                                                )}
                                                {nameIcon === true && name && (
                                                    <h4 className="title--m slow--y">
                                                        <Energia viewBox="0 0 24 24" />
                                                        <a href="#">{name}</a>
                                                    </h4>
                                                )}
                                                <p className="project-type taglabel slow--y">
                                                    <a href="#">
                                                        {solutionsIconsMap[type]}
                                                        <span>{type}</span>
                                                    </a>
                                                    {location && (
                                                        <span className="location">
                                                            <a href="">{location}</a>
                                                        </span>
                                                    )}
                                                </p>
                                            </div>

                                            {link && (
                                                <Link path={link.link} className={link.type} target={link.target}>
                                                    <ArrowRight viewBox="0 0 94 94" />
                                                </Link>
                                            )}

                                            {typeof link.path !== 'string' && <EnlaceAgrupador links={link.path} box_type="round-button" />}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div ref={moduleRef} className="module grid component_proyectos">
                {tagTitle && (
                    <div className="proyecto-header grid-container slow--y">
                        <TagTitle title={tagTitle} />
                        {cta && <Link type="arrow" label={cta.caption} path={cta.link} target={cta.target} />}
                    </div>
                )}
                {frames.length > 1 && (
                    <>
                        <button
                            ref={ghostLeft}
                            className="ghost-frame-button ghost-frame-button--left"
                            onMouseOver={prevHover}
                            onMouseOut={removeHover}
                            onClick={prevFrame}
                        >
                            Slider anterior
            </button>
                        <button
                            ref={ghostRight}
                            className="ghost-frame-button ghost-frame-button--right"
                            onMouseOver={nextHover}
                            onMouseOut={removeHover}
                            onClick={nextFrame}
                        >
                            Slider siguiente
            </button>
                    </>
                )}
                <div className="main-container" data-active-frame={activeFrame} ref={swipableComponent}>
                    <div className="backgrounds-slider" style={{ width: `${100 * frames.length}vw` }}>
                        {frames.map(({ desktopImage, mobileImage }, frame, idx) => {
                            const imageUrl = useWindowWidth() >= 768 ? desktopImage : mobileImage;

                            return <div className="bg-frame" key={rndKeyGen()} style={{ backgroundImage: `url(${imageUrl})` }}></div>;
                        })}
                    </div>

                    <div className="content-overlay grid">
                        {frames
                            .filter((frame, idx) => idx === activeFrame - 1)
                            .map((frame, idx) => {
                                const { cifra, unit, titleLeft, caption, claim } = frame.content;

                                return (
                                    <div className="content-main" key={rndKeyGen()}>
                                        {titleLeft && (
                                            <div className="left">
                                                <h3 className="title--m">{titleLeft}</h3>
                                            </div>
                                        )}
                                        <div className="right slow--y">
                                            {cifra && (
                                                <>
                                                    <p className="cifras--l">
                                                        {cifra} <small>{unit}</small>
                                                    </p>
                                                    <p className="title--xs">{caption}</p>
                                                </>
                                            )}

                                            {claim && (
                                                <>
                                                    <h3 className="title--m slow--y">{claim}</h3>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        {frames.length > 1 && (
                            <div className="slider-controls slow--y">
                                <button ref={prevButton} className="prev-button" onClick={prevFrame}>
                                    <IconArrowRightLong />
                                </button>
                                <button ref={nextButton} className="next-button" onClick={nextFrame}>
                                    <IconArrowRightLong />
                                </button>
                            </div>
                        )}
                        <InputRangeSlider
                            framesCount={frames.length}
                            activeFrame={parseInt(activeFrame)}
                            changeHandler={setActiveFrame}
                            noIndicator={true}
                            whiteThumb={true}
                        />

                        {frames
                            .filter((frame, idx) => idx === activeFrame - 1)
                            .map((frame, idx) => {
                                const { name, nameIcon, type, location, link } = frame.project;
                                return (
                                    <div className="content-footer" key={`content-footer-${idx}`}>
                                        <div className="project-details slow--y">
                                            {name && !nameIcon && (
                                                <h4 className="title--m slow--y">
                                                    <a href="#" className="slow--y">
                                                        {name}
                                                    </a>
                                                </h4>
                                            )}
                                            {nameIcon === true && name && (
                                                <h4 className="title--m slow--y">
                                                    <Energia viewBox="0 0 24 24" />
                                                    <a href="#">{name}</a>
                                                </h4>
                                            )}
                                            <p className="project-type taglabel slow--y">
                                                <a href="#" className="slow--y">
                                                    {solutionsIconsMap[type]}
                                                    <span>{type}</span>
                                                </a>
                                                {location && (
                                                    <span className="location slow--y">
                                                        <a href="">{location}</a>
                                                    </span>
                                                )}
                                            </p>
                                        </div>

                                        {typeof link.path === 'string' && (
                                            <Link path={link.path} className={link.type}>
                                                <ArrowRight viewBox="0 0 94 94" />
                                            </Link>
                                        )}

                                        {typeof link.path !== 'string' && <EnlaceAgrupador links={link.path} box_type="round-button" />}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        );
        }
    }
    else {
        return (<div className="module grid component_proyectos">
            {tagTitle && (
                <div className="proyecto-header grid-container slow--y">
                    <TagTitle title={tagTitle} />
                    {cta && <Link type="arrow" label={cta.caption} path={cta.link} target={cta.target} />}
                </div>
            )}
        </div>)
    }
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};

export default Proyectos;
