// button.component.js
import React, { useEffect } from 'react';
import { Link } from 'React/components';
import { ButtonCta } from 'React/components';
import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';
import { ReactComponent as IconSearch } from 'Icons/searchlense.svg';
import { ReactComponent as IconEnergy } from 'Icons/energia-24.svg';

import './Styles.scss';
import { useFade } from 'React/custom_hooks';

const Breadcrumbs = ({
    noresults,
    searchFail,
    search,
    cta,
    cargo,
    icon,
    download,
    downloadText,
    className,
    type,
    descriptiveText,
    suscribe,
    label,
    sector,
    jobDate,
    jobPlace,
    pageTitle,
    firstLevel,
    secondLevel,
    thirdLevel,
    fourthLevel,
    fithLevel,
    backLink,
    anchors,
}) => {
    let moduleRef = React.createRef();

    useFade(moduleRef);

    useEffect(() => {

        const headerNav = document.querySelector('.breadcrumbs--fixed');
        const appWrapper = document.querySelector('.app--layout');

        const shareSocial = document.querySelector('.module-sharesocial');
        const appScrollTop = appWrapper.scrollTop;

        appWrapper.addEventListener('scroll', function () {
            if (headerNav != null) {
                const headerNavHeight = headerNav.outerHeight;
                let headerNavOffset = headerNav.offsetTop;
                let elHeight = headerNavOffset;


                if (document.querySelector('.module-wysiwyg') != null) {
                    let wysiwygPos = document.querySelector('.module-wysiwyg').getBoundingClientRect();

                    if (wysiwygPos.top < 0) {
                        headerNav.classList.add('is-fixed');
                    }

                    if (wysiwygPos.top > 0) {
                        headerNav.classList.remove('is-fixed');
                    }
                }

                if (shareSocial !== null && headerNav !== undefined) {
                    if (appWrapper.scrollTop > shareSocial.offsetTop - 600) {
                        headerNav.classList.add('is-done');
                    } else {
                        headerNav.classList.remove('is-done');
                    }
                }
            }
        });

        const headerNavFooter = document.querySelector('.breadcrumbs--fixed-footer');

        if (headerNavFooter != null) {
            const headerNavOffset = headerNavFooter.offsetTop;
            let elHeight = headerNavOffset;
        }

        appWrapper.addEventListener('scroll', function () {
            let mainHeight = document.getElementById('main').getBoundingClientRect().height;
            let headerHeight = document.getElementById('header').getBoundingClientRect().height;

            if (document.getElementById('breadcrumbs') != null) {
                let winScroll = appWrapper.scrollTop || appWrapper.scrollTop;
                let breadcrumbsBottom = document.getElementById('breadcrumbs').getBoundingClientRect().bottom;

                if (breadcrumbsBottom < '-100') {
                    headerNavFooter.classList.add('is-fixed');
                } else {
                    headerNavFooter.classList.remove('is-fixed');
                }

                if (winScroll >= mainHeight) {
                    headerNavFooter.classList.add('is-done');
                } else {
                    headerNavFooter.classList.remove('is-done');
                }

                if (breadcrumbsBottom > '-100') {
                    headerNavFooter.classList.add('is-sticky');
                } else {
                    headerNavFooter.classList.remove('is-sticky');
                }

                let scrolled = (winScroll / mainHeight) * 100;
                document.getElementById('progress-bar').style.width = scrolled + '%';
            }

            if (shareSocial !== null) {
                if (appWrapper.scrollTop > shareSocial.offsetTop - 600) {
                    headerNav.classList.add('is-done');
                } else {
                    headerNav.classList.remove('is-done');
                }
            }
        });
    });

    if (type === 'fixed--empleo') {
        return (
            <div ref={moduleRef} className="module grid breadcrumbs breadcrumbs--fixed">
                <div className="breadcrumbs__container">
                    <div className="breadcrumbs__nav">
                        <nav>
                            {firstLevel && (
                                <a className="taglabel" href={firstLevel.link}>
                                    {firstLevel.name}
                                </a>
                            )}
                            {secondLevel && (
                                <a className="taglabel" href={secondLevel.link}>
                                    {secondLevel.name}
                                </a>
                            )}
                            {thirdLevel && (
                                <a className="taglabel" href={thirdLevel.link}>
                                    {thirdLevel.name}
                                </a>
                            )}
                            {fourthLevel && (
                                <a className="taglabel" href={fourthLevel.link}>
                                    {fourthLevel.name}
                                </a>
                            )}
                            {fithLevel && (
                                <a className="taglabel" href={fithLevel.link}>
                                    {fithLevel.name}
                                </a>
                            )}
                        </nav>
                        {backLink && <Link type="back" label={'Volver'} path={backLink} />}
                    </div>
                    <div className="header-cta">
                        <div className="header-cta__subheader">
                            <h1 className="title--m">{pageTitle}</h1>
                            {sector && (
                                <div className="job-subheader">
                                    <span className="taglabel">
                                        <a className="job-sector" title={sector} href="#">
                                            {icon && <IconEnergy />}
                                            {sector}
                                        </a>
                                    </span>
                                    {jobPlace && (
                                        <span className="taglabel">
                                            <a href="#" className="job-sector">
                                                {jobPlace}
                                            </a>
                                        </span>
                                    )}
                                    <span className="taglabel">
                                        <IconCalendar />
                                        {jobDate}{' '}
                                    </span>
                                </div>
                            )}
                            {cargo && <div className="taglabel mt-2 cargo">{cargo}</div>}
                        </div>

                        {anchors && (
                            <div className="anchors">
                                {anchors.map((anchor, idx) => {
                                    return (
                                        <a href={anchor.link} key={`anchor-${idx}`} className="taglabel">
                                            {anchor.caption}
                                        </a>
                                    );
                                })}
                            </div>
                        )}
                        {label && <ButtonCta label={label} />}
                    </div>
                </div>
            </div>
        );
    } else if (type === 'fixed') {
        return (
            <>
                <div ref={moduleRef} id="breadcrumbs" className={`module grid breadcrumbs breadcrumbs--fixed ${className}`}>
                    <div className="breadcrumbs__container">
                        <div className="breadcrumbs__nav">
                            <nav>
                                {thirdLevel && (
                                    <a className="taglabel" href="/">
                                        {thirdLevel.name}
                                    </a>
                                )}
                                {fourthLevel && (
                                    <a className="taglabel" href={fourthLevel.link}>
                                        {fourthLevel.name}
                                    </a>
                                )}
                                {fithLevel && (
                                    <a className="taglabel" href={fithLevel.link}>
                                        {fithLevel.name}
                                    </a>
                                )}
                            </nav>
                            {backLink && <Link type="back" label={typeof window !== 'undefined' ? (window.location.hostname !== 'annualreport2019.acciona.com' && window.location.hostname !== 'annualreport2019-test.acciona.com' ? 'Volver' : 'Back') : ''} path="/" />}
                        </div>
                        <div className="header-cta">
                            <div className="header-cta__subheader">
                                <h1 className="title--m">{pageTitle}</h1>
                                {sector && (
                                    <div className="job-subheader">
                                        <span className="taglabel">
                                            <a className="job-sector" title={sector} href="#">
                                                {icon && <IconEnergy />}
                                                {sector}
                                            </a>
                                        </span>
                                        <span className="taglabel">
                                            <a href="#" className="job-sector">
                                                {jobPlace}
                                            </a>
                                        </span>
                                        <span className="taglabel">
                                            <IconCalendar />
                                            {jobDate}{' '}
                                        </span>
                                    </div>
                                )}
                            </div>

                            {anchors && (
                                <div className="anchors">
                                    {anchors.map((anchor, idx) => {
                                        return (
                                            <a href={anchor.link} key={`anchor-${idx}`} className="taglabel">
                                                {anchor.caption}
                                            </a>
                                        );
                                    })}
                                    {cta && (
                                        <ButtonCta label={cta.name} path={cta.url} target={cta.target ? cta.target : ''} />

                                    )}
                                </div>
                            )}
                            {download && (
                                <div>
                                    <ButtonCta label={downloadText ? downloadText : ''} path={download.umbracoFile ? download.umbracoFile : download.file} target="_blank" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="progress-bar" id="progress-bar-desktop"></div>
                </div>
                <div
                    id="breadcrumbs-footer"
                    className={`module grid breadcrumbs breadcrumbs--fixed breadcrumbs--fixed-footer ${className}`}
                >
                    <div className="breadcrumbs__container">
                        <div className="breadcrumbs__nav">
                            <nav>
                                {firstLevel && (
                                    <a className="taglabel" href={firstLevel.link}>
                                        {firstLevel.name}
                                    </a>
                                )}
                                {secondLevel && (
                                    <a className="taglabel" href={secondLevel.link}>
                                        {secondLevel.name}
                                    </a>
                                )}
                                {thirdLevel && (
                                    <a className="taglabel" href={thirdLevel.link}>
                                        {thirdLevel.name}
                                    </a>
                                )}
                                {fourthLevel && (
                                    <a className="taglabel" href={fourthLevel.link}>
                                        {fourthLevel.name}
                                    </a>
                                )}
                                {fithLevel && (
                                    <a className="taglabel" href={fithLevel.link}>
                                        {fithLevel.name}
                                    </a>
                                )}
                            </nav>
                            {backLink && <Link type="back" label={'Volver'} path={backLink} />}
                        </div>
                        <div className="header-cta">
                            <div className="header-cta__subheader">
                                <h1 className="title--m">{pageTitle}</h1>
                                {sector && (
                                    <div className="job-subheader">
                                        <span className="taglabel">
                                            <a className="job-sector" title={sector} href="#">
                                                {icon && <IconEnergy />}
                                                {sector}
                                            </a>
                                        </span>
                                        {jobPlace && (
                                            <span className="taglabel">
                                                <a href="#" className="job-sector">
                                                    {jobPlace}
                                                </a>
                                            </span>
                                        )}
                                        <span className="taglabel">
                                            <IconCalendar />
                                            {jobDate}{' '}
                                        </span>
                                    </div>
                                )}
                            </div>

                            {anchors && cta && (
                                <div className="anchors">
                                    {anchors.map((anchor, idx) => {
                                        return (
                                            <a href={anchor.link} key={`anchor-${idx}`} className="taglabel">
                                                {anchor.caption}
                                            </a>
                                        );
                                    })}
                                    {cta && (
                                        <div>
                                            <ButtonCta label={cta.name} path={cta.url} target={cta.target ? cta.target : ''} />
                                        </div>
                                    )}
                                </div>
                            )}
                            {cta && !anchors && (
                                <div>
                                    <ButtonCta label={cta.name} path={cta.url} target={cta.target ? cta.target : ''} />
                                </div>
                            )}
                            {download && (
                                <div>
                                    <ButtonCta label={downloadText ? downloadText : ''} path={download.umbracoFile ? download.umbracoFile : download.file} target="_blank" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="progress-bar" id="progress-bar"></div>
                </div>{' '}
            </>
        );
    }
    if (type === 'detalle-noticia') {
        return (
            <div ref={moduleRef} className="module grid breadcrumbs">
                <div className="breadcrumbs__container">
                    <div className="breadcrumbs__nav">
                        <nav>
                            {firstLevel && (
                                <a className="taglabel" href={firstLevel.link}>
                                    {firstLevel.name}
                                </a>
                            )}
                            {secondLevel && (
                                <a className="taglabel" href={secondLevel.link}>
                                    {secondLevel.name}
                                </a>
                            )}
                            {thirdLevel && (
                                <a className="taglabel" href={thirdLevel.link}>
                                    {thirdLevel.name}
                                </a>
                            )}
                            {fourthLevel && (
                                <a className="taglabel" href={fourthLevel.link}>
                                    {fourthLevel.name}
                                </a>
                            )}
                            {fithLevel && (
                                <a className="taglabel" href={fithLevel.link}>
                                    {fithLevel.name}
                                </a>
                            )}
                        </nav>
                        {backLink && <Link type="back" label={'Volver'} path={backLink} />}
                    </div>
                    {pageTitle && (
                        <div className="header-cta">
                            <div className="header-cta__subheader">
                                <h1 className="title--m">{pageTitle}</h1>
                                <div className="job-subheader">
                                    {sector &&
                                        <span className="taglabel">
                                            <a className="job-sector" title={sector} href="#">
                                                {icon && <IconEnergy />}
                                                {sector}
                                            </a>
                                        </span>
                                    }
                                    {jobPlace && (
                                        <span className="taglabel">
                                            <a href="#" className="job-sector">
                                                {jobPlace}
                                            </a>
                                        </span>
                                    )}
                                    <span className="taglabel">
                                        <IconCalendar />
                                        {jobDate}{' '}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {descriptiveText && (
                    <div className="intro grid-container">
                        <div className="body--l slow" dangerouslySetInnerHTML={{ __html: descriptiveText }}></div>
                    </div>
                )}
                {suscribe && (
                    <div className="mt-3 mobile-visible">
                        <Link type="subscribe" label="subscribirse" />
                    </div>
                )}
            </div>
        );
    } else {
        return (
            <div ref={moduleRef} className="module grid breadcrumbs">
                <div className="breadcrumbs__container slow">
                    <div className="breadcrumbs__nav slow">
                        <nav>
                            {thirdLevel && (
                                <a className="taglabel" href="/">
                                    {thirdLevel.name}
                                </a>
                            )}
                            {fourthLevel && (
                                <a className="taglabel" href={fourthLevel.link}>
                                    {fourthLevel.name}
                                </a>
                            )}
                            {fithLevel && (
                                <a className="taglabel" href={fithLevel.link}>
                                    {fithLevel.name}
                                </a>
                            )}
                        </nav>
                        {backLink && <Link type="back" label={typeof window !== 'undefined' ? (window.location.hostname !== 'annualreport2019.acciona.com' && window.location.hostname !== 'annualreport2019-test.acciona.com' ? 'Volver' : 'Back') : ''} path="/" />}
                    </div>
                    <h1 className="title--s slow">
                        {pageTitle}
                        {suscribe && (
                            <div className="mobile-hidden slow">
                                <Link type="subscribe" label="subscribirse" />
                            </div>
                        )}
                    </h1>
                    {sector && (
                        <div className="header-cta">
                            <div className="header-cta__subheader">
                                <div className="job-subheader">
                                    <span className="taglabel">
                                        <a className="job-sector" title={sector} href="#">
                                            {icon && <IconEnergy />}
                                            {sector}
                                        </a>
                                    </span>
                                    {jobPlace && (
                                        <span className="taglabel">
                                            <a href="#" className="job-sector">
                                                {jobPlace}
                                            </a>
                                        </span>
                                    )}
                                    <span className="taglabel">
                                        <IconCalendar />
                                        {jobDate}{' '}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {descriptiveText && (
                    <div className="intro grid-container slow">
                        <div className="body--l slow" dangerouslySetInnerHTML={{ __html: descriptiveText }}></div>
                    </div>
                )}
                {suscribe && (
                    <div className="mt-3 mobile-visible">
                        <Link type="subscribe" label="subscribirse" />
                    </div>
                )}
                {search && (
                    <div className="input-wrapper grid-container slow">
                        <div className="input-btn">
                            <input type="text" className="search" defaultValue="Agua" />
                            <button className="search-btn">
                                <IconSearch />
                            </button>
                        </div>
                    </div>
                )}
                {searchFail && (
                    <div className="input-wrapper grid-container">
                        <div className="input-btn">
                            <input type="text" className="search" defaultValue="fffhgh" />
                            <button className="search-btn">
                                <IconSearch />
                            </button>
                        </div>
                    </div>
                )}
                {noresults && (
                    <div className="intro grid-container">
                        <p className="body--l slow--y">{noresults}</p>
                    </div>
                )}
            </div>
        );
    }
};

export default Breadcrumbs;
