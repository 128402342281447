import React, { useState, useEffect } from 'react';
import { Breadcrumbs, WysiwygEmpleo } from 'React/components';

const WorkDayDetalleEmpleo = (model) => {


    useEffect(() => {
     
    });

    return (
        <div className=''>
            <Breadcrumbs type='fixed--empleo' cta={model.cta ? model.cta : ''} backLink={model.backLink ? model.backLink : ''} firstLevel={model.firstLevel ? model.firstLevel : ''} secondLevel={model.secondLevel ? model.secondLevel : ''} thirdLevel={model.thirdLevel ? model.thirdLevel : ''} pageTitle={model.pageTitle ? model.pageTitle : ''} label='Solicitar empleo'/>
            <WysiwygEmpleo/>
        </div>
    );

};

export default WorkDayDetalleEmpleo;







