import React from 'react';
import { ReactComponent as IconCivil } from 'Icons/soluciones/ciudad.svg';
import { ReactComponent as IconLeaf } from 'Icons/leaf.svg';
import { ReactComponent as IconEnergy } from 'Icons/soluciones/energia.svg';
import { ReactComponent as IconMovilidad } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as IconAgua } from 'Icons/soluciones/agua.svg';
import { ReactComponent as IconSocial } from 'Icons/soluciones/social.svg';
import { ReactComponent as IconInmo } from 'Icons/soluciones/inmobiliaria.svg';
import { ReactComponent as IconCapital } from 'Icons/soluciones/capital.svg';
import { ReactComponent as IconIndustrial } from 'Icons/industrial-24.svg';
import { ReactComponent as IconOtros } from 'Icons/otrosnegocios-24.svg';

export default {
    Civil: <IconCivil height="16" width="16" viewBox="0 0 24 24" />,
    MedioAmbiente: <IconLeaf height="16" width="16" viewBox="0 0 24 24" />,
    Energia: <IconEnergy height="16" width="16" viewBox="0 0 24 24" />,
    Movilidad: <IconMovilidad height="16" width="16" viewBox="0 0 24 24" />,
    Agua: <IconAgua height="16" width="16" viewBox="0 0 24 24" />,
    Social: <IconSocial height="16" width="16" viewBox="0 0 24 24" />,
    Inmobiliaria: <IconInmo height="16" width="16" viewBox="0 0 24 24" />,
    Capital: <IconCapital height="16" width="16" viewBox="0 0 24 24" />,
    Industrial: <IconIndustrial height="16" width="16" viewBox="0 0 24 24" />,
    Otros: <IconOtros height="16" width="16" viewBox="0 0 24 24" />,
};
