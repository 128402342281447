import React from 'react';
import { Link } from 'React/components';
import IconsMap from './_iconsMap';

import Fade from 'react-reveal/Fade';

import './styles.scss';
import { TagTitle } from '..';

const SolucionesBotonera = ({ title, body, cta, activeSolutions, anchor }) => {
    return (
        <div className="module grid soluciones-botonera" id="soluciones-botonera">
            <Fade bottom cascade>
                <div className="grid-container grid full-width soluciones-botonera__header">
                    <h2 className="title title--l" id={anchor}>{title}</h2>
                    <p className="body body--m">{body}
                        {cta &&
                            <span className="cta grid-container mobile-hidden">
                                <Link type="arrow" label={cta.caption} path={cta.link} target={cta.target} />
                            </span>
                        }
                    </p>
                    {cta &&
                        <div className="cta grid-container mobile-visible">
                        <Link type="arrow" label={cta.caption} path={cta.link} target={cta.target}/>
                        </div>
                    }
                </div>
            </Fade>
            <Fade bottom cascade>
                <div className="grid-container grid full-width soluciones-botonera__content">
                    <Fade bottom cascade>
                        <ul className="grid-container">
                            {activeSolutions.map((solution, idx) => {
                                const { icon, menuText, _contentPath } = solution;

                                return (
                                    <li className="title--s" key={`solution-button-${idx}`}><a href={_contentPath}>{IconsMap[icon]}{menuText}</a></li>
                                 );
                            })}
                        </ul>
                    </Fade>
                </div>
            </Fade>
        </div>
    )
};

export default SolucionesBotonera;
