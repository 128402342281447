// button.component.js
import React from 'react';

import './Styles.scss';

const CheckboxGroup = ({ name = 'checkItems', label, type, check1, check2, check3, event }) => {

    if (type === 'contacto') {
        return (
            <div className={`checkbox ${name}`}>
                <div className="checkbox__container">
                    {check1 && (
                        <label className="checkbox_item taglabel">
                            <input name={name} type="checkbox" onChange={() => event(check1)} />
                            <span className="checkbox-ghost"></span>
                            <span className="checkbox_label">{check1}</span>
                        </label>
                    )}
                    {check2 && (
                        <label className="checkbox_item taglabel" >
                            <input name={name} type="checkbox" onChange={() => event(check2)} />
                            <span className="checkbox-ghost"></span>
                            <span className="checkbox_label">{check2}</span>
                        </label>
                    )}
                    {check3 && (
                        <label className="checkbox_item taglabel">
                            <input name={name} type="checkbox" onChange={() => event(check3)} />
                            <span className="checkbox-ghost"></span>
                            <span className="checkbox_label">{check3}</span>
                        </label>
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <div className={`checkbox ${name}`}>
                <div className="checkbox__container">
                    {label &&
                        <div><label className="taglabel">{label}</label></div>
                    }
                    <div className="checkbox__content">
                        {check1 && (
                            <label className="checkbox_item taglabel">
                                <input name={name} type="checkbox" onChange={() => event(check1)} />
                                <span className="checkbox-ghost"></span>
                                <span className="checkbox_label">{check1}</span>
                            </label>
                        )}
                        {check2 && (
                            <label className="checkbox_item taglabel" >
                                <input name={name} type="checkbox" onChange={() => event(check2)} />
                                <span className="checkbox-ghost"></span>
                                <span className="checkbox_label">{check2}</span>
                            </label>
                        )}
                        {check3 && (
                            <label className="checkbox_item taglabel">
                                <input name={name} type="checkbox" onChange={() => event(check3)} />
                                <span className="checkbox-ghost"></span>
                                <span className="checkbox_label">{check3}</span>
                            </label>
                        )}
                    </div>
                </div>
            </div>
        );
    }


};

export default CheckboxGroup;
