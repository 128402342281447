import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { TagTitle, InputRangeSlider, Link } from 'React/components';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';

import { useSliderSwipe, useSlowFade } from 'React/custom_hooks';

const TestimoniosSlider = ({ title, subModules, cta, anchor }) => {
    const [activeFrame, setActiveFrame] = useState(1);
    const [activeAudio, setActiveAudio] = useState(null);

    // slider functionality
    const thisModule = React.createRef();

    const prevButton = React.createRef();
    const nextButton = React.createRef();

    const nextHover = () => {
        nextButton.current.classList.add('is-animated');
        prevButton.current.classList.remove('is-animated');
    };

    const prevHover = () => {
        prevButton.current.classList.add('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const removeHover = () => {
        prevButton.current.classList.remove('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const nextFrame = () => {
        if (activeFrame < subModules.length) {
            setActiveFrame(activeFrame + 1);
        }
    };

    const prevFrame = () => {
        if (activeFrame > 1) {
            setActiveFrame(activeFrame - 1);
        }
    };

    useSlowFade(thisModule);

    if (typeof window != 'undefined') {
        const sliderWidth = (framesCount) => {
            if (typeof window != undefined && window.innerWidth < 1024) return `${100 * framesCount}vw`;
            return `${100 * framesCount}%`;
        };

        const sliderOffset = (framesCount) => {
            if (typeof window != undefined && window.innerWidth < 1024)
                return `translateX(${100 * (activeFrame - 1) * -1}vw)`;
            return `translateX(${(100 / framesCount) * (activeFrame - 1) * -1}%)`;
        };

        const framesContainerStyle = {
            width: sliderWidth(subModules.length),
            transform: sliderOffset(subModules.length),
        };

        // audio controls
        const audioElementRefs = subModules.map((element) => React.createRef());
        const audioStatsRefs = subModules.map((element) => React.createRef());

        const togglePlay = (frameId) => {
            setActiveAudio((oldState) => (oldState === frameId ? null : frameId));
        };

        useEffect(() => {
            audioElementRefs.forEach((ref) =>
                ref.current !== null ? ref.current.pause() : null

            );

            if (activeAudio !== null) {
                audioElementRefs[activeAudio - 1].current.play();
            }
        }, [activeAudio]);

        // audio stats
        const formatedAudioTime = (timeFloat) => {
            if (timeFloat === Infinity) return '<i>∞</i>';

            const seconds = parseInt(timeFloat);
            if (seconds < 60) return `0’${seconds}’’`;

            if (seconds >= 60) return `${parseInt(seconds / 60)}’${seconds % 60}’’`;

            return timeFloat;
        };

        useEffect(() => {
            audioElementRefs.forEach((audioEl, idx) => {
                if (audioEl.current !== null) {
                    const statsElement = audioStatsRefs[idx].current;
                    const [oldTime, oldDuration] = statsElement.innerText.split('/');

                    audioEl.current.addEventListener('loadedmetadata', (event) => {
                        const { duration } = event.target;

                        statsElement.innerHTML = `${oldTime} / ${formatedAudioTime(duration)}`;
                    });

                    audioEl.current.addEventListener('timeupdate', (event) => {
                        const { currentTime, duration } = event.target;

                        statsElement.innerHTML = `${formatedAudioTime(
                            currentTime
                        )} / ${formatedAudioTime(duration)}`;
                    });
                }

            });
            return () => {
                audioElementRefs.forEach((audioEl) => {
                    if (audioEl.current !== null) {
                        audioEl.current.removeEventListener('loadedmetadata');
                        audioEl.current.removeEventListener('timeupdate');
                    }
                });
            };

        }, []);

        useSliderSwipe(thisModule, setActiveFrame, subModules.length);


        return (
            <div className="module grid testimonios with-slider" data-active-frame={activeFrame} ref={thisModule}>
                {subModules.length > 1 && (
                    <>
                        <button
                            className="ghost-frame-button ghost-frame-button--left"
                            onMouseOver={prevHover}
                            onMouseOut={removeHover}
                            onClick={prevFrame}
                        >
                            Slider anterior
                        </button>
                        <button
                            className="ghost-frame-button ghost-frame-button--right"
                            onMouseOver={nextHover}
                            onMouseOut={removeHover}
                            onClick={nextFrame}
                        >
                            Slider siguiente
                        </button>
                    </>
                )}
                <div className="slow--y">
                    {title && <TagTitle title={title} />}
                    <div className="frames-container" style={framesContainerStyle}>
                        {subModules.map((frame, idx) => {
                            const { blockquote, personImage, personName, audio, hideQuotemark } = frame;
                            return (
                                <div
                                    className="frame grid"
                                    data-frame-id={idx + 1}
                                    key={`tecno-frame-${idx}`}
                                    style={{ width: `${100 / subModules.length}%` }}
                                >
                                    <div className={`blockquote ${hideQuotemark ? '' : 'with-quote'}`}>
                                        <blockquote>
                                            {blockquote}
                                            <div className="person-data">
                                                <div className="person-content">
                                                    {personImage && (
                                                        <div className="person-picture">
                                                            <img src={personImage.url} />
                                                        </div>
                                                    )}
                                                    {personName && (
                                                        <div className="person-name title--xs">
                                                            {personName}
                                                            {audio && (
                                                                <div className="player-content">
                                                                    <div className="audio title--xs">
                                                                        <audio id="player" ref={audioElementRefs[idx]}>
                                                                            <source src={audio.link} type="audio/mpeg" />
                                                                        </audio>

                                                                        {audio.caption}
                                                                        <button
                                                                            id="player-button"
                                                                            onClick={() => togglePlay(idx + 1)}
                                                                        >
                                                                            <img src="/static/icons/video.svg" />
                                                                        </button>
                                                                        <span
                                                                            className="audio-stats"
                                                                            ref={audioStatsRefs[idx]}
                                                                        >
                                                                            0'0'' / --
                                </span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>
                                </div>
                            );
                        })}
                        <span className="custom-anchor" id={anchor} />
                    </div>
                    {subModules.length > 1 && (
                        <>
                            <div className="slider-controls--container grid full-width">
                                <div className="slider-controls slow--y">
                                    <button ref={prevButton} className="prev-button" onClick={prevFrame}>
                                        <IconArrowRightLong />
                                    </button>
                                    <button ref={nextButton} className="next-button" onClick={nextFrame}>
                                        <IconArrowRightLong />
                                    </button>
                                </div>
                            </div>

                            <InputRangeSlider
                                changeHandler={setActiveFrame}
                                noIndicator
                                framesCount={subModules.length}
                                activeFrame={parseInt(activeFrame)}
                            />
                        </>
                    )}
                    {cta && <Link className="cta slow--y" type="arrow" path={cta.link} label={cta.caption} target={cta.target} />}
                </div>
            </div>
        );
    }
    else {
        return (
            <div
                className="module grid testimonios with-slider"
                data-active-frame={activeFrame}
                ref={thisModule}
            >
            </div>
        );
    }
};

TestimoniosSlider.propTypes = {
    title: PropTypes.string,
    subModules: PropTypes.array.isRequired,
};

export default TestimoniosSlider;
