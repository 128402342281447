import window from 'global'

import React, { useState } from 'react';

import { ReactComponent as Arrow } from 'Icons/ArrowLong.svg';
import { ReactComponent as Chevron } from 'Icons/chevron.svg';
import { ReactComponent as Check } from 'Icons/valid-16.svg';
import './SubscriptionForm.scss';

const SubscriptionForm = ({ action, title, descr, checkboxLabel, confirmationMsg }) => {
    const [checkboxState, setCheckboxState] = useState(false);
    const [formStep, setFormStep] = useState('initial');

    const handleCheckboxChange = (event) => {
        setCheckboxState(event.target.checked);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const { email_to_subscribe, conditions_accepted } = event.target.elements;
        const subscribtionFormData = {
            targetURL: action,
            email_to_subscribe: email_to_subscribe.value,
            conditions_accepted: conditions_accepted.value,
        };

        // TODO: submit the form data to the API
        setFormStep('submited');
        console.log(subscribtionFormData);
    };

    return (
        <form action={action} onSubmit={handleSubmit} className="subscribtion-form">
            <div className="form-container grid">
                <div className="grid-container grid full-width">
                    <div className="grid-left">
                        <h3 className="form-title title--m uppercase">{title}</h3>
                        <p className="form-descr body--m">{descr}</p>
                    </div>
                    <div className="grid-right">
                        <div className="input-button">
                            <input type="text" name="email_to_subscribe" placeholder="Escribe tu email" />
                            <button className="form-submit" type="submit" disabled={!checkboxState && 'disabled'}>
                                <Arrow />
                            </button>
                        </div>
                        <hr />

                        <div className="faux-flex">
                            <input
                                type="checkbox"
                                id="conditions_accepted"
                                value="true"
                                onChange={handleCheckboxChange}
                                name="conditions_accepted"
                            />
                            <div className="faux-checkbox" aria-hidden="true">
                                <Chevron className="checkbox-tick" />
                            </div>
                            <label
                                className="body--s"
                                htmlFor="conditions_accepted"
                                dangerouslySetInnerHTML={{ __html: checkboxLabel }}
                            ></label>
                        </div>
                        <div className="confirmation-message" data-is-visible={formStep === 'submited'}>
                            <Check width="16" height="16" />
                            <p className="body--m">{confirmationMsg}</p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default SubscriptionForm;
