import React, { useState, useEffect } from 'react';
import { BuscadorFiltrosProyectos, ResultadosProyectos, Paginator } from 'React/components';

const ProjectsList = ({ isList }) => {
    const [projectListState, setProjectListState] = useState([]);
    const [pagesListState, setPagesListState] = useState([]);
    const [actualPageState, setActualPageState] = useState(1);
    const [yearFilterState, setYearFilterState] = useState('');
    const [actualPagesList, setActualPagesList] = useState([]);

    const itemsByPage = 6;

    const solutionsCombo = [
        { name: 'Agua', value: 'Agua' },
        { name: 'Capital', value: 'Capital' },
        { name: 'Ciudades', value: 'Ciudades' },
        { name: 'Energía', value: 'Energia' },
        { name: 'Inmobiliaria', value: 'Inmobiliaria' },
        { name: 'Social', value: 'Social' },
        { name: 'Transporte', value: 'Transporte' }
    ];

    const energyAreasCombo = [
        { name: 'Biomasa', value: 'Biomasa' },
        { name: 'Eólica', value: 'Eolica' },
        { name: 'Fotovoltaica', value: 'Fotovoltaica' },
        { name: 'Hidroeléctrica', value: 'Hidroelectrica' },
        { name: 'Termosolar', value: 'Termosolar' }
    ];

    const waterAreasCombo = [
        { name: 'Géstión', value: 'Gestion' },
        { name: 'Tratamiento', value: 'Tratamiento' }
    ];

    const getProjectsList = (culture, page) => {
        if (isList) {
            var url = `/api/CmsController/GetProjectList?language=${culture}&itemsByPage=${itemsByPage}&pageIndex=${page}`;
            const xhrPages = new XMLHttpRequest();
            xhrPages.open('get', url, true);
            xhrPages.onload = function (e) {
                if (xhrPages.readyState === 4) {
                    if (xhrPages.status === 200) {
                        var responsePaginated = JSON.parse(xhrPages.responseText);

                        const sidePages = 7;

                        var nextList = [];
                        var befList = [];
                        nextList = responsePaginated.pagePagination.pagesList.slice(page - 1, page + sidePages);
                        befList = responsePaginated.pagePagination.pagesList.slice((page - (sidePages + 1) < 0 ? 0 : page - (sidePages + 1)), page);

                        if (page == 1) {
                            setActualPagesList(nextList);
                        }
                        else if (page == responsePaginated.pagePagination.pagesList[responsePaginated.pagePagination.pagesList.length - 1].num) {
                            setActualPagesList(befList);
                        }
                        else if (page == actualPagesList[actualPagesList.length - 1].num) {
                            setActualPagesList(nextList);
                        }
                        else if (page == responsePaginated.pagePagination.pagesList[responsePaginated.pagePagination.pagesList.length - 1].num || page == actualPagesList[0].num) {
                            setActualPagesList(befList);
                        }
                        else if (page < actualPagesList[0].num) {
                            befList = responsePaginated.pagePagination.pagesList.slice(((page + 1) - (sidePages + 1) < 0 ? 0 : (page + 1) - (sidePages + 1)), page + 1);
                            setActualPagesList(befList);
                        }
                        else if (page > responsePaginated.pagePagination.pagesList[responsePaginated.pagePagination.pagesList.length - 1].num) {
                            nextList = responsePaginated.pagePagination.pagesList.slice(((page - 1) - (sidePages + 1) < 0 ? 0 : (page - 1) - (sidePages + 1)), page - 1);
                            setActualPagesList(nextList);
                        }


                        if (responsePaginated.projectList.length < itemsByPage) {
                            var ej1 = {
                                title: "",
                                link: "",
                                intro: "",
                                divisiones: "",
                                soluciones: [],
                                date: "",
                                photo: "",
                                featuredNote: false
                            };

                            var size = itemsByPage - responsePaginated.projectList.length;
                            for (var i = 0; i < size; i++) {
                                responsePaginated.projectList.push(ej1);
                            }

                        }

                        setProjectListState(responsePaginated.projectList);
                        setPagesListState(responsePaginated.pagePagination.pagesList);

                    } else {
                        console.error(xhrPages.statusText);
                    }
                }
            }.bind(this);

            xhrPages.send(null);
        }
    }

    const changePage = (page) => {
        setActualPageState(page);
        var culture = 'es';
        getProjectsList(culture, page);
    };

    const nextPage = () => {
        if (actualPageState < pagesListState.length) {
            setActualPageState(actualPageState + 1);
            var culture = 'es';
            getProjectsList(culture, actualPageState + 1);
        }
    };

    const prevPage = () => {
        if (actualPageState > 1) {
            setActualPageState(actualPageState - 1);
            var culture = 'es';
            getProjectsList(culture, actualPageState - 1);
        }
    };

    const setFilter = (event) => {
        setYearFilterState(event.target.value);
        setActualPageState(1);
        var culture = 'es';
        getProjectsList(culture, 1, event.target.value);
    };

    useEffect(() => {
        var culture = 'es';
        getProjectsList(culture, 1);

    }, [setPagesListState]);

    return (
        <div className=''>
            <BuscadorFiltrosProyectos type='proyectos' searchFilter={setFilter} />
            <ResultadosProyectos frames={projectListState}/>
            {pagesListState.length > 1 &&
                <Paginator pagesList={pagesListState} actualPage={actualPageState} changePage={changePage} nextPage={nextPage} prevPage={prevPage} actualPagesList={actualPagesList} />
            }
        </div>
    );
}

export default ProjectsList;