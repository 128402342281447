import React from 'react';

import { ReactComponent as IconEnergy } from 'Icons/soluciones/energia.svg';
import { ReactComponent as IconSocial } from 'Icons/soluciones/social.svg';
import { ReactComponent as IconMovilidad } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as IconCivil } from 'Icons/soluciones/civil.svg';
import { ReactComponent as IconAgua } from 'Icons/soluciones/agua.svg';
import { ReactComponent as IconEconomia } from 'Icons/soluciones/economiacircular.svg';
import { ReactComponent as IconInmo } from 'Icons/soluciones/inmobiliaria.svg';

import './Styles.scss';


const mapaWeb = () => {
    return (
        <div className="module-mapa-web module grid">
            <div className="grid-container">
                <div className="item">
                    <div>
                        <h2 className="title--s"><a href="">Home</a></h2>
                    </div>
                </div>
                <div className="item">
                    <div className="item-container">
                        <h2 className="title--s"><a href="">Sobre ACCIONA</a></h2>
                    </div>
                    <div className="item-container">
                        <h3 className="title--xs"><a href="">Nuestro propósito</a></h3>
                        <h3 className="title--xs"><a href="">Historia</a></h3>
                        <h3 className="title--xs"><a href="">Sostenibilidad</a></h3>
                        <nav>
                            <p className="body--s"><a href="">Nuestra estrategia</a></p>
                            <p className="body--s"><a href="">Objetivos de Desarrollo Sostenible</a></p>
                            <p className="body--s"><a href="">Índices de Sostenibilidad</a></p>
                            <p className="body--s"><a href="">Alianzas para el Desarrollo Sostenible</a></p>
                            <p className="body--s"><a href="">Presencia de ACCIONA en las COP</a></p>
                        </nav>
                        <h3 className="title--xs"><a href="">Innovación</a></h3>
                        <nav><p className="body--s"><a href="">Centros de I+D</a></p></nav>
                    </div>
                    <div className="item-container">
                        <h3 className="title--xs"><a href="">Por qué trabajar con nosotros</a></h3>
                        <nav>
                            <p className="body--s"><a href="">Ofertas de empleo</a></p>
                            <p className="body--s"><a href="">Formación</a></p>
                            <p className="body--s"><a href="">Programa de graduados y becas</a></p>
                            <p className="body--s"><a href="">Diversidad, igualdad e inclusión</a></p>
                            <p className="body--s"><a href="">Seguridad, salud y bienestar</a></p>
                        </nav>
                        <h3 className="title--xs"><a href="">Nuestra fundación</a></h3>
                        <h3 className="title--xs"><a href="">Iniciativas que apoyamos</a></h3>
                    </div>
                </div>

                <div className="item">
                    <h2 className="title--s item-container"><a href="">Soluciones</a></h2>
                    <ul className="item-container">
                        <li className="title--xs">
                            <a href="#">
                                <IconEnergy height="24" width="24" viewBox="0 0 24 24" />
                                Energía
								</a>
                        </li>
                        <li className="title--xs">
                            <a href="#">
                                <IconCivil height="24" width="24" viewBox="0 0 24 24" />
                                Obra Civil
								</a>
                        </li>
                        <li className="title--xs">
                            <a href="#">
                                <IconMovilidad height="24" width="24" viewBox="0 0 24 24" />
                                Movilidad
								</a>
                        </li>
                        <li className="title--xs">
                            <a href="#">
                                <IconAgua height="24" width="24" viewBox="0 0 24 24" />
                                Agua
								</a>
                        </li>
                    </ul>
                    <ul className="item-container">

                        <li className="title--xs">
                            <a href="#">
                                <IconSocial height="24" width="24" viewBox="0 0 24 24" />
                                Social
								</a>
                        </li>
                        <li className="title--xs">
                            <a href="#">
                                <IconEconomia height="24" width="24" viewBox="0 0 24 24" />
                                Economía Circular
								</a>
                        </li>
                        <li className="title--xs">
                            <a href="#">
                                <IconInmo height="24" width="24" viewBox="0 0 24 24" />
                                Inmobiliaria
								</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )

};

export default mapaWeb;