import React from 'react';

import 'Styles/_texts.scss';
import './style.scss';

const WysiwygNoticia = ({ media, body }) => {

    return (
        <div className="module module-wysiwyg">
            <div className="grid">
                <div className="image-featured"><div className="image-content"><img src={media} /></div></div>
                <div className="text-container" dangerouslySetInnerHTML={{ __html: body }}></div>
            </div>
        </div>
    )
};

export default WysiwygNoticia;
