import React from 'react';
import { PropTypes as pt } from 'prop-types';

import { rndKeyGen } from 'React/helpers';
import './styles.scss';
import { Link } from 'React/components';
import { ReactComponent as PhoneIcon } from 'Icons/phone.svg';
import { ReactComponent as EnvelopeIcon } from 'Icons/email.svg';
// solution icons
import { ReactComponent as Energy } from 'Icons/energia-viento.svg';
import { ReactComponent as Movilidad } from 'Icons/movilidad-24.svg';
import { ReactComponent as Social } from 'Icons/social-24.svg';
import { ReactComponent as Inmo } from 'Icons/inmobiliaria-24.svg';
import { ReactComponent as Civil } from 'Icons/civil.svg';
import { ReactComponent as Agua } from 'Icons/water-24.svg';
import { ReactComponent as Industrial } from 'Icons/industrial-24.svg';

const ContactInfo = ({ hq, departments, solutions }) => {

    return (
        <div className="module contact-info grid">
            {hq && (
                <div className="contact-info__block grid contact-info__hq">
                    <h2 className="title title--m">Sede corporativa</h2>
                    <div className="sub-block">
                        <p className="address body--m">{hq.address}</p>
                        <Link className="map-link" label="Cómo llegar" path={hq.link} type="arrow" target={hq.target} />
                    </div>
                    <div className="sub-block">
                        <NumberLink number={hq.tel} type="tel" large={true} />
                        <NumberLink number={hq.fax} type="fax" large={true} />
                        <DataHandlingInfoLink />
                    </div>
                </div>
            )}

            {departments && (
                <div className="contact-info__block contact-info__departments">
                    <h2 className="title title--m">Departamentos</h2>
                    <ul className="emails-list grid">
                        {departments.map((dept, idx) => (
                            <li key={rndKeyGen()} className="sub-block">
                                <a className="email-link" href={`mailto:${dept.email}`}>
                                    <EnvelopeIcon />
                                    <span className="taglabel">{dept.name}</span>
                                    <span className="body--m email">{dept.email}</span>
                                </a>
                            </li>
                        ))}
                    </ul>
                    <DataHandlingInfoLink />
                </div>
            )}

            {solutions && (
                <div className="contact-info__block grid contact-info__solutions">
                    {solutions.map((solution, idx) => (
                        <SolutionBusinessCard solution={solution} className="sub-block" key={rndKeyGen()} />
                    ))}
                </div>
            )}
        </div>
    );
};

// SUB COMPONENTS
// ==============

const NumberLink = ({ number, email, type, large = false }) => {
    // remove all non-integers and prefix with '00'
    const urlNumber = '00' + number.replace(/[^0-9]+/g, '');

    return (
        <a href={`tel:${urlNumber}`} className={`number-link ${type}`}>
            {type === 'tel' ? <PhoneIcon /> : <EnvelopeIcon />}
            <p className={large ? 'title--s' : 'link'}>{number}</p>
        </a>
    );
};

const DataHandlingInfoLink = () => (
    <p className="body--s data-handling-link">
        Información sobre <a href="#url_to_data_policy">protección de datos</a>
    </p>
);

const SolutionBusinessCard = ({ solution, className }) => {
    const { name, address, tel, fax, map_url } = solution;
    const solutionsIconsMap = {
        Energy,
        Movilidad,
        Social,
        Inmo,
        Civil,
        Agua,
        Industrial,
    };
    const IconComponent = solutionsIconsMap[name];

    return (
        <div className={`solution-business-card ${className}`}>
            <h3 className="name title--s">
                <IconComponent />
                {name}
            </h3>
            <p className="address body--m">{address}</p>
            <div className="number-links-block">
                <NumberLink number={tel} type="tel" />
                <NumberLink number={fax} type="fax" />
            </div>
            <DataHandlingInfoLink />
            <Link className="map-link" label="Cómo llegar" path={map_url.link} type="external" target={map_url.target} />
        </div>
    );
};

ContactInfo.propTypes = {
    hq: pt.shape({
        address: pt.string.isRequired,
        map_url: pt.string.isRequired,
        tel: pt.string.isRequired,
        fax: pt.string.isRequired,
    }),
    departments: pt.arrayOf(
        pt.shape({
            name: pt.string.isRequired,
            email: pt.string.isRequired,
        })
    ),
    solutions: pt.arrayOf(
        pt.shape({
            name: pt.string.isRequired,
            address: pt.string.isRequired,
            tel: pt.string.isRequired,
            fax: pt.string.isRequired,
            map_url: pt.string.isRequired,
        })
    ),
};

export default ContactInfo;
