import React, { useState, useEffect, useContext } from 'react';
import { PropTypes as pt } from 'prop-types';

import { TagTitle, Link } from 'React/components';
import Map from './Map';
import { iconsMap, iconsMapLarge } from './solucionesIconsMap';
import './styles.scss';
import { ReactComponent as CircledArrow } from 'Icons/arrow_circle-68.svg';
import { ReactComponent as CloseIcon } from 'Icons/cross.svg';
import { rndKeyGen } from 'React/helpers';

const solutionsList = ['energía', 'civil', 'movilidad', 'agua', 'social', 'inmobiliaria', 'economia circular'];

const ProjectsMap = ({ title, initMapCenter, projectsList }) => {

    projectsList = [
        {
            lat: 33.5724108,
            lng: -7.6570318,
            location: 'Casablanca',
            solution: 'energía',
            name: 'Casablanca Project',
            image: '/static/formatos/formato_proyectos_S.jpg',
            url: '#project_path_Casablanca',
            info: 'Nuestro impacto positivo en el planeta',
        },
        {
            lat: 34.566535,
            lng: 133.9779692,
            location: 'Okayama',
            solution: 'agua',
            name: 'Okayama Project',
            image: '/static/formatos/formato_proyectos_S.jpg',
            url: '#project_path_Seul',
            info: 'Nuestro impacto positivo en el planeta',
        },
        {
            lat: 39.6777642,
            lng: 135.4160251,
            location: 'Sea of Japan',
            solution: 'movilidad',
            name: 'Sea Project',
            image: '/static/formatos/formato_proyectos_S.jpg',
            url: '#project_path_Seul',
            info: 'Nuestro impacto positivo en el planeta',
        },
        {
            lat: 34.6777642,
            lng: 135.4160251,
            location: 'Osaca',
            solution: 'energía',
            name: 'Osaca Project',
            image: '/static/formatos/formato_proyectos_S.jpg',
            url: '#project_path_Osaca',
            info: {
                cifra: '234.33',
                unit: 'kg',
                caption: 'support text',
            },
        },
        {
            lat: 47.5546492,
            lng: 7.5594407,
            location: 'Bazel',
            solution: 'economia circular',
            name: 'Bazel Project',
            image: '/static/formatos/formato_proyectos_S.jpg',
            url: '#project_path_Bazel',
            info: 'Nuestro impacto positivo en el planeta',
        },
        {
            lat: 59.965,
            lng: 30.235,
            location: 'St.Petersburg',
            solution: 'agua',
            name: 'St.Petersburg Project',
            image: '/static/formatos/formato_proyectos_S.jpg',
            url: '#project_path_St',
            info: {
                cifra: '234.33',
                unit: 'kg',
                caption: 'support text',
            },
        },
        {
            lat: -1.2863888888889,
            lng: 36.817222222222,
            location: 'Kenya',
            solution: 'social',
            name: 'Kenya Project',
            image: '/static/formatos/formato_proyectos_S.jpg',
            url: '#project_path_Kenya',
            info: 'Nuestro impacto positivo en el planeta',
        },
        {
            lat: 12.97194,
            lng: 77.59369,
            location: 'Bangalore',
            solution: 'energía',
            name: 'Bangalore Project',
            image: '/static/formatos/formato_proyectos_S.jpg',
            url: '#project_path_Bangalore',
            info: 'Nuestro impacto positivo en el planeta',
        },
    ];

    const baseCenter = { lat: 40.4167754, lng: -3.7037902 };
    const mapCenter =
        typeof initMapCenter === 'undefined'
            ? baseCenter
            : typeof initMapCenter === 'number'
                ? projectsList[initMapCenter]
                : initMapCenter;

    // component state
    const [centerPoint, setCenterPoint] = useState(mapCenter);
    const [zoomValue, setZoomValue] = useState(2);
    const [selectedSolution, setSelectedSolution] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [filteredList, setFilteredList] = useState(projectsList);


    // subset of solution types present in the projects list
    const solutionsOnMap = [...new Set(projectsList.map((project) => project.solution))];

    // filtering
    useEffect(() => {
        const projects = projectsList.map((project) => {
            project.id = rndKeyGen();
            return project;
        });

        if (selectedSolution === null) {
            setFilteredList(projects);
        } else {
            setFilteredList(projects.filter((project) => project.solution === selectedSolution));
        }
    }, [selectedSolution]);

    const handleSolutionClick = (solution) => {
        setSelectedSolution((oldState) => {
            if (oldState === solution) return null;

            if (!solutionsOnMap.includes(solution)) return null;

            return solution;
        });

        // center on base point
        if (solution === selectedSolution) {
            setCenterPoint({ lat: 40.4167754, lng: -3.7037902 }); // Madrid, Spain
            setZoomValue(2);
        }
    };

    const handleCloseOverlayClick = () => {
        setSelectedProject(null);
        if (filteredList.length > 0) {
            setFilteredList((oldState) =>
                oldState.map((project) => {
                    project.selected = false;
                    return project;
                })
            );
        }
    };

    // selected project details
    const changeSelectedPoint = (projectId) => {
        setSelectedProject({
            key: projectId,
            ...filteredList.find((project) => project.id === projectId),
        });
    };

    useEffect(() => {
        if (selectedProject === null) {
            return;
        }

        setCenterPoint(() => {
            const { lat, lng } = selectedProject;
            return { lat, lng };
        });

        // red Marker for selected project
        setFilteredList((oldState) =>
            oldState.map((project) => {
                if (project.id == selectedProject.id) {
                    project.selected = true;
                } else {
                    project.selected = false;
                }
                return project;
            })
        );
    }, [selectedProject]);

    return (
        <div className="module grid projects-map module__projects-map">
            <div className="map-header">
                <TagTitle title={title} />
            </div>

            <ul className="soluciones-selector" data-details-overlay-visible={selectedProject !== null}>
                {solutionsList.map((solution, idx) => {
                    return (
                        <li
                            key={`solution-key-${idx}`}
                            onClick={() => handleSolutionClick(solution)}
                            data-selected-solution={selectedSolution === solution}
                            data-solution-not-on-map={!solutionsOnMap.includes(solution)}
                        >
                            <button>
                                {typeof window != 'undefined' && window.innerWidth < 1280 ? iconsMap[solution] : iconsMapLarge[solution]}
                                <span className="title--xs">{solution}</span>
                            </button>
                        </li>
                    );
                })}
            </ul>

            <div className="map-container" data-detail-overlay-is-visible={selectedProject !== null}>
                <Map
                    pointsList={filteredList}
                    selectedSolution={selectedSolution}
                    changeSelectedPoint={changeSelectedPoint}
                    mapCenter={centerPoint}
                    changeMapCenter={setCenterPoint}
                    mapZoomValue={zoomValue}
                    changeZoomValue={setZoomValue}
                />

                <ProjectDetailsOverlay project={selectedProject} closeAction={handleCloseOverlayClick} />
                <div className="project-details-close-overlay" onClick={handleCloseOverlayClick} />
            </div>
        </div>
    );
};

const ProjectDetailsOverlay = ({ project, closeAction }) => {
    const { name, solution, location, image, url, info } = project || {};
    const _self = React.createRef();

    return (
        <div className="project-details-overlay grid" ref={_self} data-is-visible={project !== null}>
            <button className="close-overlay" onClick={() => closeAction(null)}>
                <CloseIcon />
            </button>
            {project && (
                <>
                    <div className="image-container">
                        <img src={image} />
                    </div>

                    <div className="detail-body" data-content-type={typeof info === 'string' ? 'text' : 'cifra'}>
                        {typeof info === 'string' && <h4 className="title--m">{info}</h4>}

                        {typeof info === 'object' && (
                            <>
                                <p className="cifras--m">
                                    {info.cifra}
                                    <small>{info.unit}</small>
                                </p>
                                <p className="title--xs">{info.caption}</p>
                            </>
                        )}
                    </div>

                    <div className="details-footer">
                        <span className="params">
                            <h3 className="name title--s">{name}</h3>
                            <span className="solution-location title--tag">
                                <Link path="#solution_path">
                                    {iconsMap[solution]}
                                    <i>{solution}</i>
                                </Link>
                                |<i>{location}</i>
                            </span>
                        </span>
                        {url && (
                            <Link path={url} className="link">
                                {typeof window  != 'undefined' && window.innerWidth < 1680 ? (
                                    <CircledArrow width="68" height="68" viewBox="0 0 68 68" />
                                ) : (
                                        <CircledArrow width="120" height="120" viewBox="0 0 68 68" />
                                    )}
                            </Link>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

ProjectsMap.propTypes = {
    title: pt.string,
    initMapCenter: pt.oneOfType([
        pt.shape({
            lat: pt.number.isRequired,
            lng: pt.number.isRequired,
        }),
        pt.number, // index of a project in the projectsList
    ]),
    projectsList: pt.arrayOf(
        pt.shape({
            lat: pt.number.isRequired,
            lng: pt.number.isRequired,
            location: pt.string.isRequired,
            solution: pt.oneOf(solutionsList).isRequired,
            name: pt.string.isRequired,
            image: pt.string,
            url: pt.string,
            info: pt.oneOfType([
                pt.string,
                pt.shape({
                    cifra: pt.string.isRequired,
                    unit: pt.string,
                    caption: pt.string.isRequired,
                }),
            ]),
        })
    ).isRequired,
};

export default ProjectsMap;
