// button.component.js
import React, { useState } from 'react';
import './styles.scss';
import { CheckboxGroup, TagTitle } from 'React/components';
import { ReactComponent as IconSearch } from 'Icons/searchlense.svg';
import { ReactComponent as IconShow } from 'Icons/filteropen-16.svg';
import { ReactComponent as IconHide } from 'Icons/filterclose-16.svg';
import { useFade } from '../../custom_hooks';

const BuscadorFiltrosProyectos = ({ type, title, searchFilter,
    hideCombo, areasCombo, divisionsCombo, countriesCombo,
    relationsFilter, countrySelected, divisionSelected,
    areaSelected, jobTypeSelected, getListOffers}) => {

    const [filterState, setFilterState] = useState(false);
    const [filterAreaState, setFilterAreaState] = useState('');
    const [filterTerritoryState, setFilterTerritoryState] = useState('');
    const _btnIcon = () => (filterState === true ? <IconShow /> : <IconHide />);
    const _btnLabel = () => (filterState === true ? 'Mostrar filtros' : 'Ocultar filtros');

    const setFilterArea = (event) => {
        setFilterAreaState(event.target.value);
    };

    const setFilterTerritory = (event) => {
        setFilterTerritoryState(event.target.value);
    };

    let moduleRef = React.createRef();

    useFade(moduleRef);

    if (type === 'proyectos') {
        return (
            <div
                ref={moduleRef}
                className={`grid module module-buscadorfiltros--proyectos module-buscadorfiltros ${
                    filterState === true ? 'hide' : 'show'
                    }`}
            >
                <div className="grid-container">
                    <button id="button-filters" onClick={() => setFilterState((oldState) => !oldState)}>
                        <span className="taglabel">
                            <_btnIcon /> <_btnLabel />
                        </span>
                    </button>
                </div>
                <div className="grid-container">
                    <form className="filters-container">
                        <div className="selects-container">
                            <div className="select-container">
                                <div className="select">
                                    <select name="slct" id="slct" defaultValue={'DEFAULT'}>
                                        <option value="DEFAULT" disabled>
                                            Solución
                    </option>
                                        <option value="1">Pure CSS</option>
                                        <option value="2">No JS</option>
                                        <option value="3">Nice!</option>
                                    </select>
                                    <label className="taglabel">Solución</label>
                                </div>
                                <div className="select">
                                    <select name="slct" id="slct" defaultValue={'DEFAULT'}>
                                        <option value="DEFAULT" disabled>
                                            Área de actividad
                    </option>
                                        <option value="1">Pure CSS</option>
                                        <option value="2">No JS</option>
                                        <option value="3">Nice!</option>
                                    </select>
                                    <label className="taglabel">Área de actividad</label>
                                </div>
                                <div className="select">
                                    <select name="slct" id="slct" defaultValue={'DEFAULT'}>
                                        <option value="DEFAULT" disabled>
                                            País
                    </option>
                                        <option value="1">Pure CSS</option>
                                        <option value="2">No JS</option>
                                        <option value="3">Nice!</option>
                                    </select>
                                    <label className="taglabel">País</label>
                                </div>
                            </div>
                        </div>
                        <div className="checkbox-filters">
                            <div className="checkbox-filter">
                                <CheckboxGroup label="Tipo de proyecto" check1="innovación" check2="sostenibilidad" />
                            </div>
                            <div className="checkbox-filter">
                                <CheckboxGroup label="Estado" check1="Instalada" check2="En construcción" />
                            </div>
                            <div className="checkbox-filter">
                                <CheckboxGroup label="Titularidad" check1="En propiedad" check2="Cliente" />
                            </div>
                        </div>
                    </form>
                    <button type="button" className="search-btn">
                        <IconSearch />{' '}
                    </button>
                </div>
            </div>
        );
    }

    if (type === 'proyectos-simple') {
        return (
            <div
                ref={moduleRef}
                className={`slow grid module module-buscadorfiltros--proyectos module-buscadorfiltros--proyectos-simple module-buscadorfiltros ${
                    filterState === true ? 'hide' : 'show'
                    }`}
            >
                <div className="grid-container">
                    <form className="filters-container ">
                        <div className="selects-container select-proyectos-simple">
                            <div className="select-container">
                                <div className="select">
                                    <select name="slct" id="slct" defaultValue={'DEFAULT'}>
                                        <option value="DEFAULT" disabled>
                                            Solución
                    </option>
                                        <option value="1">Pure CSS</option>
                                        <option value="2">No JS</option>
                                        <option value="3">Nice!</option>
                                    </select>
                                    <label className="taglabel">Solución</label>
                                </div>
                                <div className="select">
                                    <select name="slct" id="slct" defaultValue={'DEFAULT'}>
                                        <option value="DEFAULT" disabled>
                                            País
                    </option>
                                        <option value="1">Pure CSS</option>
                                        <option value="2">No JS</option>
                                        <option value="3">Nice!</option>
                                    </select>
                                    <label className="taglabel">País</label>
                                </div>
                            </div>
                            <div className="checkbox-filters">
                                <div className="checkbox-filter">
                                    <CheckboxGroup label="Tipo de proyecto" check1="innovación" check2="sostenibilidad" />
                                </div>
                            </div>
                        </div>
                    </form>
                    <button type="button" className="search-btn">
                        <IconSearch />{' '}
                    </button>
                </div>
            </div>
        );
    }
};

const changeFilterButton = (e) => {
    let textContent = e.currentTarget.textContent;

    if (textContent === 'Mostrar filtros') {
        e.currentTarget.textContent = 'Ocultar filtros';
    }
    if (textContent === 'Ocultar filtros') {
        e.currentTarget.textContent = 'Mostrar filtros';
    }
};

export default BuscadorFiltrosProyectos;
