import React, { useEffect, useRef } from 'react';
//import { gsap } from 'gsap';
import { useHeroParallax } from 'React/custom_hooks';
import { ReactComponent as IconAgua } from 'Icons/water-24.svg';
import { ReactComponent as IconCivil } from 'Icons/civil.svg';

import './styles.scss';

const HeroImage = ({
    className,
    media,
    secondCategoryProject,
    countryProject,
    titleProject,
    cifraProject,
    unitProject,
    descriptionProject,
    categoryProject,
    title,
    body,
    cta,
    combined,
}) => {
    const imgContainer = React.createRef();

    let app = useRef(null);
    let heading = useRef(null);
    let text = useRef(null);
    let button = useRef(null);
    let separator = useRef(null);



    useHeroParallax('.component__hero-image');

    if (typeof window !== 'undefined') {

        const tl = gsap.timeline();

        useEffect(() => {
            if (app != null) {
                if (media && media.url) {
                    let img = new Image();
                    img.src = `${media.url}`;

                    // controlamos con esto que la imagen est� cargada y entonces la desvelamos
                    img.onload = function () {
                        if (app != null) {
                            app.classList.add('is-loaded');
                            gsap.to(app, { visibility: 'visible' }, 1);

                            tl.fromTo(imgContainer.current, 3, { scale: 2, x: 200 }, { scale: 1, x: 0 })
                                .from(heading, 1, { opacity: 0, y: 40, delay: 2 }, 0.2)
                                .to(heading, 2, { opacity: 1, y: 40, delay: 2 }, 0.2)
                                .from(text, 1, { opacity: 0, y: 40, delay: 2 }, 0.2)
                                .to(text, 2, { opacity: 1, y: 40, delay: 2 }, 0.2)
                                .from(button, 1, { opacity: 0, y: 40, delay: 2 }, 0.6)
                                .to(button, 2, { opacity: 1, y: 40, delay: 2 }, 0.6)
                                .from(separator, 1, { width: '0', delay: 2 }, 0.6)
                                .to(separator, 2, { width: '100%', delay: 2 }, 0.6);
                        }
                    };

                    if (img.complete) img.onload();
                }
            }
        }),
            [gsap];
    }


    return (
        <div
            ref={(el) => (app = el)}
            className={`module grid full-width component__hero-image ${className}`}
            data-combined={combined}
        >
            {media && media.url && (
                <div ref={imgContainer} className="backgrounds full-width" style={{ backgroundImage: `url(${media.url})` }}>
                    <div className="img-container"></div>
                </div>
            )}

            {media && media.videoId && <div className="video-container"></div>}
            <div className="content-blocks">
                {title && (
                    <h2 ref={(el) => (heading = el)} className="title title--xl">
                        {title}
                    </h2>
                )}
                {body && (
                    <div ref={(el) => (text = el)} className="body body--l" dangerouslySetInnerHTML={{ __html: body }}>
                    </div>
                )}
                {cta && (
                    <a href={cta.link} className="circled-arrow-link" ref={(el) => (button = el)} target={cta.target}>
                        <svg width="94" height="94" version="1.1" viewBox="0 0 94 94" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M56.25,40 L63.6746212,47.4246212 L56.25,54.8492424 L55.5428932,54.1421356 L62.01,47.674 L33,47.6746212 L33,46.6746212 L61.51,46.674 L55.5428932,40.7071068 L56.25,40 Z"
                                id="Combined-Shape"
                                fill="#FFFFFF"
                                fillRule="nonzero"
                            ></path>

                            <circle fill="none" id="circle1" strokeWidth="1" stroke="#FFFFFF" cx="48" cy="47" r="44"></circle>
                            <circle fill="none" id="circle2" strokeWidth="3" stroke="#FFFFFF" cx="48" cy="47" r="44"></circle>
                        </svg>
                    </a>
                )}
                {cifraProject && (
                    <h2 className="cifras--xl">
                        {cifraProject}
                        <span className="cifras--m">{unitProject}</span>
                    </h2>
                )}
                {descriptionProject && <p className="title--xs">{descriptionProject}</p>}
                <div className="separator" ref={(el) => (separator = el)}></div>

                {titleProject && (
                    <div className="project-detail-footer">
                        <h2 className="title--xl">{titleProject}</h2>
                        <div className="project-detail-footer__desc">
                            <span className="category-project taglabel">
                                <IconAgua />
                                {categoryProject}
                            </span>
                            {countryProject && <span className="country-project taglabel">{countryProject}</span>}
                            {secondCategoryProject && (
                                <span className="country-project taglabel">
                                    <IconCivil width="24" height="24" viewBox="0 0 24 24" />
                                    {secondCategoryProject}
                                </span>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default HeroImage;
