import React, { useEffect } from 'react';
import Hammer from '@egjs/hammerjs';

function useSliderSwipe(
    containerRef,
    frameSetMethod,
    framesLength,
    screenSizeLimit = 99999
) {

    // change frame position on swipe left or right
    useEffect(() => {
        if (screenSizeLimit <= window.innerWidth) {
            return;
        }

        const sliderEl = containerRef.current;
        const swipeManager = new Hammer.Manager(sliderEl);
        // if the direction is not set to horizontal it can block page scroll on touch screens
        const SliderSwipe = new Hammer.Swipe().set({
            direction: Hammer.DIRECTION_HORIZONTAL,
        });

        swipeManager.add(SliderSwipe);

        const framesList = sliderEl.querySelectorAll('.data-point');

        framesList.forEach((frame, idx) => {

            if (idx + 1 === parseInt(sliderEl.dataset.activeFrame)) {
                frame.classList.remove('prev-frame');
                frame.classList.remove('next-frame');
                frame.classList.add('frame-active');

                if (frame.nextElementSibling != null) {
                    frame.nextElementSibling.classList.add('next-frame');
                }

                if (frame.previousElementSibling != null) {
                    frame.previousElementSibling.classList.add('prev-frame');
                }

                if (frame.nextElementSibling != null) {
                    frame.nextElementSibling.classList.add('next-frame');
                } else {
                    frame.classList.remove('next-frame');
                }

            } else {
                frame.classList.remove('frame-active');
                frame.classList.remove('prev-frame');
            }
        });

        const currentFrame = parseInt(sliderEl.dataset.activeFrame);

        if (document.querySelector('.next-frame') != null) {

            document.querySelector('.next-frame').addEventListener('click',
                () => {
                    if (currentFrame < framesLength) {
                        frameSetMethod(currentFrame + 1);
                    }

                    if (currentFrame > 1) {
                        frameSetMethod(currentFrame + 1);
                    }
                }
            );
        }

        swipeManager.on(
            'swipeleft swiperight',
            (event) => {
                const direction = event.offsetDirection;
                const currentFrame = parseInt(sliderEl.dataset.activeFrame);
                if (direction === 2 && currentFrame < framesLength) {
                    frameSetMethod(currentFrame + 1);
                } else if (direction === 4 && currentFrame > 1) {
                    frameSetMethod(currentFrame - 1);
                }
            },
            []
        );

        return () => {
            // remove listener and kill manager when component is removed
            swipeManager.remove(SliderSwipe);
            swipeManager.destroy();
        };
    });

}

export default useSliderSwipe;
