import React, { useEffect } from 'react';

function useHeroParallax(imagesParentSelector) {

    // this sets --scroll_offset custom property related to the scroll position
    // which can be used to transform corresponding element via CSS

    useEffect(() => {

        const appWrapper = document.querySelector('.app--layout');
        const imagesParent = document.querySelector(imagesParentSelector);
        imagesParent.style.setProperty('--scroll_offset', 0);

        const scrollHandler = () => {
            // parallax effect on HeroImage
            const offset = appWrapper.scrollTop / 3;
            imagesParent.style.setProperty('--scroll_offset', `${offset}px`);
        };

        appWrapper
            .addEventListener('scroll', scrollHandler, { capture: false, passive: true });

        return () => {
            appWrapper
                .removeEventListener('scroll', scrollHandler, { capture: false, passive: true });
        }
    }, []);
}

export default useHeroParallax;
