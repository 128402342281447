import React, { useState, useEffect } from 'react';
import { BuscadorFiltros, ModuleNews, Paginator } from 'React/components';

const NewsList = () => {
    const [newsListState, setNewsListState] = useState([]);
    const [pagesListState, setPagesListState] = useState([]);
    const [actualPageState, setActualPageState] = useState(1);
    const [yearFilterState, setYearFilterState] = useState('');
    const [actualPagesList, setActualPagesList] = useState([]);

    const yearsCombo = ["2020", "2019", "2018", "2017", "2016", "2015"];

    const getNewsList = (route, culture, page, year) => {
        //year = year ? year : yearFilterState;
        var url = `/api/CmsController/GetNewsList?pressRoomRoute=${route}&language=${culture}&pageIndex=${page}&includeFeaturedItems=true${year ? '&year=' + year : ''}`;
        const xhrPages = new XMLHttpRequest();
        xhrPages.open('get', url, true);
        xhrPages.onload = function (e) {
            if (xhrPages.readyState === 4) {
                if (xhrPages.status === 200) {
                    var responseNewsListPaginated = JSON.parse(xhrPages.responseText);

                    const sidePages = 7;

                    var nextList = [];
                    var befList = [];
                    nextList = responseNewsListPaginated.pagePagination.pagesList.slice(page - 1, page + sidePages);
                    befList = responseNewsListPaginated.pagePagination.pagesList.slice((page - (sidePages + 1) < 0 ? 0 : page - (sidePages + 1)), page);


                    if (actualPagesList.length === 0 && page !== 1) {

                        setActualPagesList(nextList);
                    }

                    else {

                        if (page === 1) {
                            setActualPagesList(nextList);
                        }
                        else if (page === responseNewsListPaginated.pagePagination.pagesList[responseNewsListPaginated.pagePagination.pagesList.length - 1].num) {
                            setActualPagesList(befList);
                        }
                        else if (actualPagesList.length > 0 && page === actualPagesList[actualPagesList.length - 1].num) {
                            setActualPagesList(nextList);
                        }
                        else if (page === responseNewsListPaginated.pagePagination.pagesList[responseNewsListPaginated.pagePagination.pagesList.length - 1].num || page === actualPagesList[0].num) {
                            setActualPagesList(befList);
                        }
                        else if (actualPagesList.length > 0 && page < actualPagesList[0].num) {
                            befList = responseNewsListPaginated.pagePagination.pagesList.slice(((page + 1) - (sidePages + 1) < 0 ? 0 : (page + 1) - (sidePages + 1)), page + 1);
                            setActualPagesList(befList);
                        }
                        else if (page > responseNewsListPaginated.pagePagination.pagesList[responseNewsListPaginated.pagePagination.pagesList.length - 1].num) {
                            nextList = responseNewsListPaginated.pagePagination.pagesList.slice(((page - 1) - (sidePages + 1) < 0 ? 0 : (page - 1) - (sidePages + 1)), page - 1);
                            setActualPagesList(nextList);
                        }
                    }


                    if (responseNewsListPaginated.noteList.length < 9) {
                        var ej1 = {
                            title: "",
                            link: "",
                            intro: "",
                            divisiones: "",
                            soluciones: [],
                            date: "",
                            photo: "",
                            featuredNote: false
                        };

                        var size = 9 - responseNewsListPaginated.noteList.length;
                        for (var i = 0; i < size; i++) {
                            responseNewsListPaginated.noteList.push(ej1);
                        }

                    }

                    setNewsListState(responseNewsListPaginated.noteList);
                    setPagesListState(responseNewsListPaginated.pagePagination.pagesList);

                } else {
                    console.error(xhrPages.statusText);
                }
            }
        }.bind(this);

        xhrPages.send(null);


    };

    const changePage = (page) => {

        document.getElementById("header").scrollIntoView();

        setActualPageState(page);
        var route = '/actualidad/noticias';
        var culture = 'es';
        getNewsList(route, culture, page);
    };

    const nextPage = () => {

        document.getElementById("header").scrollIntoView();
     
        if (actualPageState < pagesListState.length) {
            setActualPageState(actualPageState + 1);
            var route = '/actualidad/noticias';
            var culture = 'es';
            getNewsList(route, culture, actualPageState + 1);
        }
       
        if (window !== null) {
            var url = window.location.href;
            var urlPageNumber = url.match('#(.+)');
            if (urlPageNumber !== null && urlPageNumber.length > 0) {

                var urlNumber = parseInt(urlPageNumber[1]) !== pagesListState.length ? urlPageNumber[1] : parseInt(urlPageNumber[1] - 1);
                var newurl = url.replace(urlNumber, (actualPageState + 1).toString());
                window.location.href = newurl;
            }
            else {
                window.location.href = url + '#' + (actualPageState + 1);
            }
        }
    };

    const prevPage = () => {

        document.getElementById("header").scrollIntoView();

        if (actualPageState > 1) {
            setActualPageState(actualPageState - 1);
            var route = '/actualidad/noticias';
            var culture = 'es';
            getNewsList(route, culture, actualPageState - 1);
        }

        if (window !== null) {
            var url = window.location.href;
            var urlPageNumber = url.match('#(.+)');
            if (urlPageNumber !== null && urlPageNumber.length > 0) {

                var urlNumber = urlPageNumber[0] !== '#1' ? urlPageNumber[0] : '#2';
                var newurl = url.replace(urlNumber, '#' + (actualPageState - 1).toString());

                window.location.href = newurl;
            }
            else {
                window.location.href = url + '#' + (actualPageState - 1);
            }
        }
    };

    const setFilter = (event) => {
        setYearFilterState(event.target.value);
        setActualPageState(1);
        var route = '/actualidad/noticias';
        var culture = 'es';
        getNewsList(route, culture, 1, event.target.value);
    };

    useEffect(() => {
        var route = '/actualidad/noticias';
        var culture = 'es';
     
        if (window !== null) {
            if (window.location.href.match('/en/')) {
                route = '/actuality/news';
                culture = 'en';
            }
            var urlPageNumber = window.location.href.match('#(.+)');
            var pageNumber = urlPageNumber ? urlPageNumber[1] : '1';

            setActualPageState(parseInt(pageNumber));
            getNewsList(route, culture, parseInt(pageNumber), "");
        }
        else {
            getNewsList(route, culture, 1, "");
        }

    }, [setPagesListState]);

    return (
        <div className=''>
            <BuscadorFiltros type='noticias' yearFilter={setFilter} yearsCombo={yearsCombo} />
            <ModuleNews content={newsListState} />
            {pagesListState.length > 1 &&
                <Paginator pagesList={pagesListState} actualPage={actualPageState} changePage={changePage} nextPage={nextPage} prevPage={prevPage} actualPagesList={actualPagesList} />
            }
        </div>
    );
}

export default NewsList;