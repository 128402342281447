import React, { useState, useEffect, useRef } from 'react';

import './Footer.scss';

import LogoFull from 'Images/logos/logo_full.svg';

import { SubscriptionForm } from 'React/layout';
import { Link } from 'React/components';
import { useSlowFade } from 'React/custom_hooks';

import { ReactComponent as LinkedinIcon } from 'Icons/linkedin.svg';
import { ReactComponent as FacebookIcon } from 'Icons/facebook.svg';
import { ReactComponent as TwitterIcon } from 'Icons/twitter.svg';
import { ReactComponent as InstagramIcon } from 'Icons/instagram.svg';
import { ReactComponent as YoutubeIcon } from 'Icons/youtube.svg';

// soluciones icons
import { ReactComponent as EnergyIcon } from 'Icons/soluciones/energia.svg';
import { ReactComponent as CiudadesIcon } from 'Icons/soluciones/ciudad.svg';
import { ReactComponent as MovilidadIcon } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as AguaIcon } from 'Icons/soluciones/agua.svg';
import { ReactComponent as SocialIcon } from 'Icons/soluciones/social.svg';
import { ReactComponent as CapitalIcon } from 'Icons/soluciones/capital.svg';
import { ReactComponent as InmobiliariaIcon } from 'Icons/soluciones/inmobiliaria.svg';

const Footer = () => {
    let moduleRef = React.createRef();

    useSlowFade(moduleRef);

    const onHover = (event) => {
        event.currentTarget.parentNode.parentNode.classList.add('is-hovered');
    };

    const notHover = (event) => {
        event.currentTarget.parentNode.parentNode.classList.remove('is-hovered');
    };

    if (typeof window !== 'undefined') {
    return (
        <footer ref={moduleRef} className="page-footer slow--y" id="page-footer">
            <SubscriptionForm {...subscriptionFormProps} />
            <section className="module module-news grid slow--y">
                <div className="grid-container content-wrapper">
                    <div className="module-news__col slow--y">
                        <a
                            href="#"
                            className="link"
                            title="ACCIONA participa en la Jornada sobre instalaciones de saneamiento, abastecimiento y depuración en Bilbao"
                            data-ref-id="0"
                        >
                            <h2 className="title--s" onMouseOut={notHover} onMouseOver={onHover}>
                                BUSINESS AS UNUSUAL COMO FILOSOFÍA
              </h2>
                        </a>
                        <div>
                            <p className="body--m">
                                Hacemos negocio de manera sostenible, contribuyendo positivamente al desarrollo sostenible de la sociedad la sociedad y el planeta.
              </p>
                        </div>
                        <Link type="arrow" className="arrow" />
                    </div>
                    <div className="module-news__col slow--y">
                        <a
                            href="#"
                            className="link"
                            title="ACCIONA OPERARÁ LA RED DE ABASTECIMIENTO EN ALTA DEL CONSORCIO DE AGUAS DE BILBAO-BIZKAIA"
                            data-ref-id="1"
                        >
                            <h2 className="title--s" onMouseOut={notHover} onMouseOver={onHover}>
                               EXPERTOS EN DISEÑAR UN PLANETA MEJOR
              </h2>
                        </a>
                        <div>
                            <p className="body--m">
                               Un siglo de progreso, sostenibilidad e innovación para diseñar los proyectos que resolverán los principales retos del planeta.
              </p>
                            <Link type="arrow" className="arrow" />
                        </div>
                    </div>
                    <div className="module-news__col slow--y">
                        <a href="#" className="link" title="Descubre nuestras ofertas de empleo" data-ref-id="2">
                            <h2 className="title--s" onMouseOut={notHover} onMouseOver={onHover}>
                                TRABAJA CON NOSOTROS 
              </h2>
                        </a>
                        <div>
                            <p className="body--m">
                               Si quieres dejar una huella positiva en tu entorno y hacer del planeta un lugar mejor, únete al cambio. Consulta nuestras ofertas de empleo.
              </p>
                        </div>
                        <Link type="arrow" className="arrow" />
                    </div>
                </div>
            </section>
            <div className="module grid soluciones-botonera slow--y">
                <div className="grid-container grid full-width soluciones-botonera__content">
                    <ul className="grid-container slow--y">
                        <li className="title--s slow--y">
                            <a href="#">
                                <EnergyIcon />
                                Energía
              </a>
                        </li>
                        <li className="title--s slow--y">
                            <a href="#">
                                <MovilidadIcon />
                                Transporte
              </a>
                        </li>
                        <li className="title--s slow--y">
                            <a href="#">
                                <AguaIcon />
                                Agua
              </a>
                        </li>
                        <li className="title--s slow--y">
                            <SocialIcon />
                            <a href="#">Social</a>
                        </li>
                        <li className="title--s slow--y">
                            <a href="#">
                                <CiudadesIcon />
                                Ciudades
              </a>
                        </li>
                        <li className="title--s slow--y">
                            <a href="#">
                                <InmobiliariaIcon />
                                Inmobiliaria
              </a>
                        </li>
                        <li className="title--s slow--y">
                            <a href="#">
                                <CapitalIcon />
                                Capital
              </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="nav-footer grid slow--y">
                <div className="grid-container nav-footer-container">
                    <Link className="slow--y logo-home-link" path="/">
                        <img className="logo" src={LogoFull} alt="Logo Acciona" />
                    </Link>
                    <div className="nav-footer-links slow--y">
                        <a href="#" className="taglabel">
                            Nota legal
            </a>
                        <a href="#" className="taglabel">
                            Política de privacidad
            </a>
                        <a href="#" className="taglabel">
                            Cookies
            </a>
                        <a href="#" className="taglabel">
                            Mapa web
            </a>
                        <a href="#" className="taglabel">
                            Webs de ACCIONA
            </a>
                        <span className="copyright-notice">Copyright © 2030 ACCIONA</span>
                    </div>
                    <div className="nav-footer-social slow--y">
                        {useWindowWidth() < 1280 ? (
                            <>
                                <a href="#">
                                    <LinkedinIcon width="16" height="16" viewBox="0 0 13 13" />
                                </a>
                                <a href="#">
                                    <YoutubeIcon width="20" height="20" viewBox="0 0 16 11" />
                                </a>
                                <a href="#">
                                    <TwitterIcon width="16" height="16" viewBox="0 0 13 13" />
                                </a>
                                <a href="#">
                                    <InstagramIcon width="16" height="16" viewBox="0 0 13 13" />
                                </a>
                                <a href="#">
                                    <FacebookIcon width="16" height="16" viewBox="0 0 13 13" />
                                </a>
                            </>
                        ) : (
                                <>
                                    <a href="#">
                                        <LinkedinIcon width="12" height="12" viewBox="0 0 13 13" />
                                    </a>
                                    <a href="#">
                                        <YoutubeIcon width="16" height="16" viewBox="0 0 16 11" />
                                    </a>
                                    <a href="#">
                                        <TwitterIcon width="12" height="12" viewBox="0 0 13 13" />
                                    </a>
                                    <a href="#">
                                        <InstagramIcon width="12" height="12" viewBox="0 0 13 13" />
                                    </a>
                                    <a href="#">
                                        <FacebookIcon width="12" height="12" viewBox="0 0 13 13" />
                                    </a>
                                </>
                            )}
                    </div>
                </div>
            </div>
            <div className="acciona-values slow--y">
                <h3 className="text">
                    "No sólo impulsamos el <a href="#ods">desarrollo sostenible</a> y la lucha contra el{' '}
                    <a href="#cambio-climatico">cambio climático</a>. Creamos conciencia para qué tú también te unas.
          <br /> Si quieres saber más sobre <a href="#ods">energías renovables</a>,{' '}
                    <a href="#ods">tratamiento de agua</a>, o <a href="#ods">temas relacionados</a>."
        </h3>
            </div>
        </footer>
        );
    }
    else {
        return (
            <footer className="page-footer" id="page-footer slow--y">
                <SubscriptionForm {...subscriptionFormProps} />
                <section className="module module-news grid slow--y">
                    <div className="grid-container content-wrapper">
                        <div className="module-news__col slow--y">
                            <a
                                href="#"
                                className="link"
                                title="ACCIONA participa en la Jornada sobre instalaciones de saneamiento, abastecimiento y depuración en Bilbao"
                                data-ref-id="0"
                            >
                                <h2 className="title--s" onMouseOut={notHover} onMouseOver={onHover}>
                                    Nuestra misión: BUSINESS AS UNUSUAL
                  </h2>
                            </a>
                            <div>
                                <p className="body--m">
                                    Buscamos hacer negocio de manera sostenible, contribuyendo positivamente a la sociedad al planeta.
                  </p>
                            </div>
                            <Link type="arrow" className="arrow" />
                        </div>
                        <div className="module-news__col slow--y">
                            <a
                                href="#"
                                className="link"
                                title="ACCIONA OPERARÁ LA RED DE ABASTECIMIENTO EN ALTA DEL CONSORCIO DE AGUAS DE BILBAO-BIZKAIA"
                                data-ref-id="1"
                            >
                                <h2 className="title--s" onMouseOut={notHover} onMouseOver={onHover}>
                                    Expertos en diseñar un planeta mejor
                  </h2>
                            </a>
                            <div>
                                <p className="body--m">
                                    Desde hace más de 100 años venimos desarrollando proyectos en más de 65 países de todo el mundo.
                  </p>
                                <Link type="arrow" className="arrow" />
                            </div>
                        </div>
                        <div className="module-news__col slow--y">
                            <a href="#" className="link" title="Descubre nuestras ofertas de empleo" data-ref-id="2">
                                <h2 className="title--s" onMouseOut={notHover} onMouseOver={onHover}>
                                    Descubre nuestras ofertas de empleo
                  </h2>
                            </a>
                            <div>
                                <p className="body--m">
                                    En ACCIONA buscamos a los mejores profesionales, personas que quieren diseñar un planeta mejor.
                  </p>
                            </div>
                            <Link type="arrow" className="arrow" />
                        </div>
                    </div>
                </section>
            </footer>
        );
    }
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};

const subscriptionFormProps = {
    action: '/subscription-API-endpoint',
    newsletterType: 'generic',
    title: 'Suscríbete a nuestra newsletter',
    descr:
        'Recibe cada mes en tu email las novedades de ACCIONA: noticias, ofertas de empleo o artículos de innovación y sostenibilidad.',
    checkboxLabel:
        'Entiendo la <a href="#privacy-policy" class="link--white">Política de Privacidad</a> y <a class="link--white" href="#cookies-management">Cookies</a> y acepto recibir comunicaciones comerciales personalizadas.',
    confirmationMsg: '¡Gracias! Nos pondremos lo antes posible en contacto contigo',
};

export default Footer;
