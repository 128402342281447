import React from 'react';
import { TagTitle } from 'React/components';
import { ReactComponent as IconEnergy } from 'Icons/soluciones/energia.svg';
import { ReactComponent as IconSocial } from 'Icons/soluciones/social.svg';
import { ReactComponent as IconMovilidad } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as IconCivil } from 'Icons/soluciones/civil.svg';
import { ReactComponent as IconAgua } from 'Icons/soluciones/agua.svg';
import { ReactComponent as IconEconomia } from 'Icons/soluciones/economiacircular.svg';
import { ReactComponent as IconInmo } from 'Icons/soluciones/inmobiliaria.svg';

import './styles.scss';

const SolucionesMosaico = (anchor) => {
    return (
        <div className='module grid module-soluciones-mosaico'>
            <div className="grid-container" id={anchor}>
                <div className="mosaico-row">
                    <div className="mosaico-cell mosaico-cell--1">
                        <div className="energia"><IconEnergy /></div>
                        <div className="mosaico-cell--half">
                            <div className="social"><IconSocial /></div>
                            <div className="movilidad"><IconMovilidad /></div>
                        </div>
                    </div>
                    <div className="mosaico-cell--2 mosaico-cell civil">
                        <IconCivil />
                    </div>
                </div>
            </div>
            <div className="grid-container">
                <div className="mosaico-row">
                    <div className="mosaico-cell-3 agua"><IconAgua /></div>
                    <div className="mosaico-cell-4 economia"><IconEconomia /></div>
                    <div className="mosaico-cell-5 inmobiliaria"><IconInmo /></div>
                </div>
            </div>
        </div>
    )
};

export default SolucionesMosaico;
