import React, { useState } from 'react';

import { ReactComponent as Arrow } from 'Icons/ArrowLong.svg';
import { ReactComponent as Chevron } from 'Icons/chevron.svg';
import { ReactComponent as Check } from 'Icons/valid-16.svg';
import { ReactComponent as Cross } from 'Icons/cross.svg';

import Fade from 'react-reveal/Fade';

import './Styles.scss';

const SubscriptionForm = ({ action, title, descr, checkboxLabel, confirmationMsg, color = 'red' }) => {
    action = '/subscription-API-endpoint';
    title = 'Suscríbete a nuestra newsletter';
    descr =
        'Recibe cada mes en tu email las novedades de ACCIONA: noticias, ofertas de empleo o artículos de innovación y sostenibilidad.';
    checkboxLabel =
        'Entiendo la <a href="#privacy-policy" class="link--white">Política de Privacidad</a> y <a class="link--white" href="#cookies-management">Cookies</a> y acepto recibir comunicaciones comerciales personalizadas.';
    confirmationMsg = '¡Gracias! Nos pondremos lo antes posible en contacto contigo';

    const [checkboxState, setCheckboxState] = useState(false);
    const [formStep, setFormStep] = useState('initial');

    const toggleCheckbox = () => {
        setCheckboxState((oldState) => !oldState);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const { email_to_subscribe, conditions_accepted } = event.target.elements;
        const subscribtionFormData = {
            targetURL: action,
            email_to_subscribe: email_to_subscribe.value,
            conditions_accepted: conditions_accepted.value,
        };

        if (checkboxState !== true || email_to_subscribe.value.trim() === '') {
            setFormStep('error');
            return;
        }

        // TODO: submit the form data to the API
        setFormStep('submited');
    };

    return (
        <form
            action={action}
            onSubmit={handleSubmit}
            className={`subscribtion-form module ${color === 'white' ? 'white-form' : ''}`}
        >
            <div className="form-container grid">
                <div className="grid-container grid full-width">
                    <div className="grid-left">
                        <h3 className="form-title title--m uppercase">{title}</h3>
                        <p className="form-descr body--m">{descr}</p>
                    </div>
                    <div className="grid-right">
                        <div className="input-button">
                            <input type="text" name="email_to_subscribe" title="suscríbete" placeholder="Escribe tu email" />
                            <button className="form-submit" type="submit">
                                <Arrow />
                            </button>
                        </div>
                        <hr />

                        <div className="faux-flex">
                            <input
                                type="checkbox"
                                id="conditions_accepted"
                                value="true"
                                checked={checkboxState === true}
                                onChange={toggleCheckbox}
                                name="conditions_accepted"
                            />
                            <div className="faux-checkbox" onClick={toggleCheckbox} aria-hidden="true">
                                <Chevron className="checkbox-tick" />
                            </div>
                            <label
                                className="body--s for-terms-checkbox"
                                dangerouslySetInnerHTML={{ __html: checkboxLabel }}
                                onClick={toggleCheckbox}
                            ></label>
                        </div>
                        <div className="feedback-message" data-is-visible={formStep === 'submited'}>
                            <Check width="16" height="16" />
                            <p className="body--m">{confirmationMsg}</p>
                        </div>
                        <div className="feedback-message" data-is-visible={formStep === 'error'}>
                            <Cross className="validation-fail" width="16" height="16" />
                            <p className="body--m">
                                Por favor indique su correo electronico y el acuerdo con nuestra politica de privacidad.
                  </p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default SubscriptionForm;
