import React from 'react';
import { ReactComponent as IconCivil } from 'Icons/civil.svg';
import { ReactComponent as IconLeaf } from 'Icons/leaf.svg';
import { ReactComponent as IconEnergy } from 'Icons/energia-viento.svg';
import { ReactComponent as IconMovilidad } from 'Icons/movilidad-24.svg';
import { ReactComponent as IconAgua } from 'Icons/water-24.svg';
import { ReactComponent as IconSocial } from 'Icons/social-24.svg';
import { ReactComponent as IconInmo } from 'Icons/inmobiliaria-24.svg';
import { ReactComponent as IconCapital } from 'Icons/capital-24.svg';
import { ReactComponent as IconIndustrial } from 'Icons/industrial-24.svg';
import { ReactComponent as IconOtros } from 'Icons/otrosnegocios-24.svg';

export default {
    Civil: <IconCivil />,
    MedioAmbiente: <IconLeaf />,
    Energia: <IconEnergy />,
    Movilidad: <IconMovilidad/>,
    Agua: <IconAgua />,
    Social: <IconSocial  />,
    Inmobiliaria: <IconInmo  />,
    Capital: <IconCapital  />,
    Industrial: <IconIndustrial />,
    Otros: <IconOtros  />,
};
