import React, { useState, useEffect } from 'react';
import { useSlowFade } from 'React/custom_hooks';

import './styles.scss';

import { TagTitle, InputRangeSlider, Link, Button, Lightbox } from 'React/components';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';

import { useSliderSwipe, useWindowSize } from 'React/custom_hooks';

import { ReactComponent as CloseIcon } from 'Icons/cross.svg';


import ReactMediumImg from 'react-medium-zoom';

const LogosSlider = ({ module_title, module_description, module_cta, subModules, isLightbox, anchor }) => {

    const [activeFrame, setActiveFrame] = useState(1);
    const thisModule = React.createRef();

    useSlowFade(thisModule);

    // onresize render hook
    const size = useWindowSize();

    const prevButton = React.createRef();
    const nextButton = React.createRef();

    // slider functionality
    const calculateFrames = (logosCount) => {
        const arr = [];

        const getLength = () => {
            if (size.width < 680) return logosCount;
            if (size.width < 1024) return logosCount / 2;
            if (logosCount <= 4) return 1;
            if (logosCount % 4 !== 0) return parseInt(logosCount / 4) + 1;
            if (logosCount % 4 === 0) return parseInt(logosCount) / 4;
        };

        for (var i = 0; i < getLength(); i++) {
            arr.push('x');
        }

        return arr;
    };

    const frames = calculateFrames(subModules.length);
    const frameLogoSets = subModules.reduce((acc, logo, idx) => {
        const logosPerFrame = size.width < 680 ? 1 : size.width < 1024 ? 2 : 4;

        const chunkIdx = Math.floor(idx / logosPerFrame);

        if (!acc[chunkIdx]) {
            acc[chunkIdx] = [];
        }
        acc[chunkIdx].push(logo);

        return acc;
    }, []);

    const sliderWidth = (framesCount) => {
        if (size.width < 1024) return `${100 * framesCount}vw`;

        const spanCount = framesCount % 3 === 0 ? framesCount / 3 : parseInt(framesCount / 3) + 1;

        return `${100 * spanCount}%`;
    };

    const sliderOffset = () => {
        if (size.width < 1024) return `translateX(${100 * (activeFrame - 1) * -1}vw)`;

        return `translateX(${100 * (activeFrame - 1) * -1}%)`;
    };

    const frameWidth = (framesCount) => {
        if (size.width < 1024) return `${100 / 1}vw`;

        return `${100 / 1}%`;
    };

    const framesContainerStyle = {
        width: sliderWidth(frames.length),
        transform: sliderOffset(frames.length),
    };

    const nextHover = () => {
        nextButton.current.classList.add('is-animated');
        prevButton.current.classList.remove('is-animated');
    };

    const prevHover = () => {
        prevButton.current.classList.add('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const removeHover = () => {
        prevButton.current.classList.remove('is-animated');
        nextButton.current.classList.remove('is-animated');
    };

    const nextFrame = () => {
        if (activeFrame < frames.length) {
            setActiveFrame(activeFrame + 1);
        }
    };

    const prevFrame = () => {
        if (activeFrame > 1) {
            setActiveFrame(activeFrame - 1);
        }
    };


    const [modalIsVisible, setModalVisibility] = useState(false);

    const showLightbox = (frameId = 1) => {
        setModalVisibility(true);
        setActiveFrame(frameId);
        document.querySelector('.app--layout').dataset.canScroll = false;
    };

    const hideLightbox = () => {
        setModalVisibility(false);
        setActiveFrame(1);
        document.querySelector('.app--layout').dataset.canScroll = true;
    };

    useEffect(() => {
    }, [activeFrame]);


    useSliderSwipe(thisModule, setActiveFrame, frames.length);

    return (
        <div className="module grid logos-slider with-slider" data-active-frame={activeFrame} ref={thisModule}>
            {frames.length > 1 && (
                <>
                    <button
                        className="ghost-frame-button ghost-frame-button--left"
                        onMouseOver={prevHover}
                        onMouseOut={removeHover}
                        onClick={prevFrame}
                    >
                        Slider anterior
          </button>
                    <button
                        className="ghost-frame-button ghost-frame-button--right"
                        onMouseOver={nextHover}
                        onMouseOut={removeHover}
                        onClick={nextFrame}
                    >
                        Slider siguiente
          </button>
                </>
            )}
            <div className="slow--y">
                <div className="header grid">
                    <TagTitle title={module_title} />
                    {module_cta && size.width >= 1024 && (
                        <Link className="module-cta" path={module_cta.link} type={module_cta.type} label={module_cta.caption} target={module_cta.target} />
                    )}
                    <div className="module-body body--m" dangerouslySetInnerHTML={{ __html: module_description }}></div>
                </div>

                <div className="frames-container" style={framesContainerStyle}>
                    {frames.map((frame, idx) => {
                        return (
                            <div
                                className="frame grid"
                                data-frame-id={idx + 1}
                                key={`logo-frame-${idx}`}
                                style={{ width: frameWidth(subModules.length) }}
                            >
                                {frameLogoSets[idx].map((logo, idx) => {
                                    const { image, alt_text, description, cta, isLightbox } = logo;
                                   
                                    return (
                                        <div className="logo-container" key={`logo-container-${idx}`}>
                                            <img className="logo-image" src={image ? image.url : ''} alt={image ? image.name : ''} />
                                            {description && <p className="description body--m">{description}</p>}
                                            {cta && isLightbox ?
                                                <div onClick={() => showLightbox()}>
                                                  <Link className="logo-cta iconplus-link" type={isLightbox ? 'lightbox' : cta.type} label={cta.caption} target={cta.target} />
                                                </div>
                                                : <Link className="logo-cta iconplus-link" path={cta ? cta.link : ''} type={cta ? cta.type : '' } label={cta ? cta.caption : ''} target={ cta? cta.target : ''} />
                                            }
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                    <span className="custom-anchor-small" id={anchor} />
                </div>


                <InputRangeSlider
                    changeHandler={setActiveFrame}
                    framesCount={frames.length}
                    activeFrame={parseInt(activeFrame)}
                    noIndicator={true}
                />
                <div className="slider-controls slow--y">
                    <button ref={prevButton} className="prev-button" onClick={prevFrame}>
                        <IconArrowRightLong />
                    </button>
                    <button ref={nextButton} className="next-button" onClick={nextFrame}>
                        <IconArrowRightLong />
                    </button>
                </div>
                {module_cta && size.width < 1024 && (
                    <Link className="module-cta slow--y" path={module_cta.link} type={module_cta.type} label={module_cta.caption} target={module_cta.target} />
                )}
            </div>
        </div>
    );
};

export default LogosSlider;
