import React, { useEffect } from 'react';

import { ReactComponent as IconCivil } from 'Icons/civil.svg';
import { ReactComponent as IconEnergy } from 'Icons/energia-viento.svg';
import { ReactComponent as IconMovilidad } from 'Icons/movilidad-24.svg';
import { ReactComponent as IconAgua } from 'Icons/water-24.svg';
import { ReactComponent as IconSocial } from 'Icons/social-24.svg';
import { ReactComponent as IconInmo } from 'Icons/inmobiliaria-24.svg';
import { ReactComponent as IconIndustrial } from 'Icons/industrial-24.svg';

import './styles.scss';

const SolucionesFixed = () => {

    useEffect(() => {

        const appWrapper = document.querySelector('.app--layout');

        appWrapper.addEventListener('scroll', function () {
            let mainHeight = document.getElementById('main').getBoundingClientRect().height;
            let winScroll = appWrapper.scrollTop || appWrapper.scrollTop;
            let solucionesBotonera = document.getElementById('soluciones-botonera').getBoundingClientRect().bottom;
            let solucionesFixed = document.getElementById('soluciones-fixed');

            if (solucionesBotonera < -10) {
                solucionesFixed.classList.add('is-fixed');
            } else {
                solucionesFixed.classList.remove('is-fixed');
            }

            if (winScroll > mainHeight) {
                solucionesFixed.classList.add('is-done');
            } else {
                solucionesFixed.classList.remove('is-done');
            }

        });
    });

    return (
        <div className="soluciones-fixed" id="soluciones-fixed">
            <ul>
                <li className="title--xs"><a href="#"><IconEnergy viewBox="0 0 24 24" />Energía</a></li>
                <li className="title--xs"><a href="#"><IconCivil viewBox="0 0 24 24" />Civil</a></li>
                <li className="title--xs"><a href="#"><IconMovilidad viewBox="0 0 24 24" />Movilidad</a></li>
                <li className="title--xs"><a href="#"><IconAgua viewBox="0 0 24 24" />Agua</a></li>
                <li className="title--xs"><a href="#"><IconSocial viewBox="0 0 24 24" />Social</a></li>
                <li className="title--xs"><a href="#"><IconIndustrial viewBox="0 0 24 24" />Economía circular</a></li>
                <li className="title--xs"><a href="#"><IconInmo viewBox="0 0 24 24" />Inmobiliaria</a></li>
            </ul>
        </div>
    )
};

export default SolucionesFixed;
