import React, { useEffect, useRef } from 'react';
import { useSlowFade } from 'React/custom_hooks';
import { TagTitle, Link, ProjectsMapStatic, Wysywyg, EnlaceAgrupador } from 'React/components';
import { ReactComponent as DownloadIcon } from 'Icons/download.svg';

import './styles.scss';

const BloqueTexto = ({
    map,
    tagLine,
    title,
    leadText,
    leadTextColumnRight,
    leadTextColumnLeft,
    mainTextColumnLeft,
    mainTextColumnRight,
    leadSmallText,
    mainText,
    children,
    list,
    listType,
    downloads,
    moduleCta,
    groupLink,
    anchor
}) => {
    const leadTextClass = leadText ? 'with-lead-text' : '';

    const moduleRef = React.createRef();

    useSlowFade(moduleRef);

    return (
        <div ref={moduleRef} className={`module grid bloque-texto ${leadTextClass}`}>
            {tagLine && <TagTitle style="slow--y" title={tagLine} />}

            <div className="title ">
                <h2 className="title--l slow--y">{title}</h2>
                {map && <ProjectsMapStatic projectsList={[{ lat: -23.6948347, lng: 133.8793945 }]} />}
            </div>

            {leadText && (
                <div className="lead-text-container">
                    <div className="lead-text body--l slow--y" dangerouslySetInnerHTML={{ __html: leadText }}></div>

                    {leadSmallText && <div dangerouslySetInnerHTML={{ __html: leadSmallText }} className="lead-small-text mt-2 body--m slow--y"></div>}
                </div>
            )}
            <span className="custom-anchor" id={anchor} />
            {mainText && (
                <div className="main-text">
                    <div className="body--m main-text-paragraph slow--y" dangerouslySetInnerHTML={{ __html: mainText }}></div>
                    {children}
                </div>
            )}

            {mainTextColumnRight && (
                <div className="main-text main-text--columns grid full-width">
                    <div className="main-text-paragraph">
                        <div className="body--l slow--y" dangerouslySetInnerHTML={{ __html: leadTextColumnLeft }}></div>
                        <div className="body--m slow--y" dangerouslySetInnerHTML={{ __html: mainTextColumnLeft }}></div>
                    </div>
                    <div className="main-text-paragraph">
                        <div className="body--l slow--y" dangerouslySetInnerHTML={{ __html: leadTextColumnRight }}></div>
                        <div className="body--m slow--y" dangerouslySetInnerHTML={{ __html: mainTextColumnRight }}></div>
                    </div>
                </div>
            )}

            {leadTextColumnRight && !mainTextColumnRight && (
                <div className="main-text main-text--lead grid full-width">
                    <div className="main-text-paragraph">
                        <div className="body--l slow--y" dangerouslySetInnerHTML={{ __html: leadTextColumnLeft }}></div>
                    </div>
                    <div className="main-text-paragraph">
                        <div className="body--l slow--y" dangerouslySetInnerHTML={{ __html: leadTextColumnRight }}></div>
                    </div>
                </div>
            )}

            {moduleCta && (
                <div className="module--cta">
                    <Link className="module--cta slow--y" type="arrow" path={moduleCta.link} target={moduleCta.target}>
                        {moduleCta.caption}
                    </Link>
                </div>
            )}

            {list && listType == 'Desordenada' && (
                <ul className="list">
                    {list.map((item, idx) => {
                        return (
                            <li key={`text-p-${idx}`} className="slow--y body--s main-text-paragraph">
                                {item}
                            </li>
                        );
                    })}
                </ul>
            )}

            {list && listType != 'Desordenada' && (
                <ol className="list list-ordered">
                    {list.map((item, idx) => {
                        return (
                            <li key={`text-p-${idx}`} className="slow--y body--s main-text-paragraph">
                                <span>{item}</span>
                            </li>
                        );
                    })}
                </ol>
            )}

            {downloads && !groupLink && (
                <ul className="list list-downloads">
                    {downloads.map((item, idx) => {
                        return (
                            <li key={`text-p-${idx}`} className="link--download main-text-paragraph">
                                <Link type="download" path={item.file} label={item.title} bytes={item.umbracoBytes} extension={item.umbracoExtension} />
                            </li>
                        );
                    })}
                </ul>
            )}
            {downloads && groupLink && (
                <div><EnlaceAgrupador links={downloads} box_label={groupLink} /></div>
            )}
        </div>
    );
};

export default BloqueTexto;
