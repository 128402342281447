// button.component.js
import React, { useEffect } from 'react';
import './styles.scss';
import { Link } from 'React/components';

import { ReactComponent as IconPhone } from 'Icons/phone.svg';
import { ReactComponent as IconEmail } from 'Icons/email.svg';
import { useSlowFade } from '../../custom_hooks';

const Banners = ({ moduleTitle, type, subModules, anchor }) => {
    const moduleRef = React.createRef();

    useSlowFade(moduleRef);

    if (type === 'motosharing') {
        const { title, description, phone, email, fax, schedule, cta, isLightbox } = subModules[0];
        return (
            <div ref={moduleRef} className="module module-banners slow--y">
                <div className="grid">
                    <div className="banner-container slow--y">
                        <div className="banner-item slow--y">
                            <h2 className="title--m mb-2 slow--y">{title}</h2>
                            {description && <p className="body--m slow--y">{description}</p>}
                            {cta && <Link type={isLightbox ? "lightbox" : "arrow"} target={cta.target} path={cta.link} className="slow--y" label={cta.caption} />}
                        </div>
                        <div className="banner-item slow--y">
                            <div className="banner-contact">
                                <div>
                                    <a className="title--s slow--y" href={"tel:" + phone}>
                                        <IconPhone />
                                        TEL {phone}{' '}
                                    </a>
                                </div>
                                <div>
                                    <a className="title--s slow--y" href={"fax:" + fax}>
                                        <IconPhone />
                                        FAX {fax}
                                    </a>
                                </div>
                                {email &&
                                    <a href={"mailto:" + email.link} className="item-email body--l slow--y">
                                        <IconEmail />
                                        <span className="body--m slow--y">
                                            <span className="link--black">{email.caption}</span>
                                        </span>
                                    </a>
                                }

                                <p className="body--m mt-2 slow--y">
                                    Información sobre{' '}
                                    <a href="#" className="link--red">
                                        protección de datos
                  </a>
                                </p>
                                {schedule &&
                                    <div className="horario mt-4 slow--y">
                                        <div className="taglabel">Horario</div>
                                        {schedule.map((item, idx) => {
                                            return (
                                                <p className="body--m" key={"horario-" + idx}>{item}</p>
                                            );
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <span className="custom-anchor" id={anchor} />
                </div>
            </div>
        );
    }
    if (type === 'emails') {
        return (
            <div className="banner-item__content slow--y">
                {
                    subModules.map((frame, idx) => {
                        const { title, content, phone, emails, cta, isLightbox} = frame;

                        return (
                            <>
                                <h2 className="title--s" slow--y>{title}</h2>
                                <div className="banner-contact banner-contact--emails">
                                    <div>
                                        {emails.map((email, idx) => {
                                            return (
                                                <a href={"mailto:" + email.link} key={"email-" + idx} className="item-email body--l slow--y">
                                                    <IconEmail />
                                                    <span className="title--xxs">{email.caption}</span>
                                                    <span className="link">{email.link}</span>
                                                </a>
                                            );
                                        })}
                                       
                                    </div>
                                </div>
                                {cta && <Link className="slow--y" type={isLightbox ? "lightbox" : "arrow"} path={cta.link} label={cta.caption} target={cta.target} />}
                            </>
                        );
                    })
                }
                <span className="custom-anchor" id={anchor} />
            </div>
        );
    } else {
        return (
            <div ref={moduleRef} className="module module-banners">
                <div className="grid">
                    {moduleTitle && <h2 className={`slow--y title--tag`}>{moduleTitle}</h2>}
                    <div className="banner-container slow--y">
                        {subModules.map((frame, idx) => {
                            const { title, content, phone, emails, cta, isLightbox } = frame;

                            return (
                                <div className="banner-item slow--y" key={`data-point-${idx}`}>
                                    <div className="banner-item__content">
                                        <h2 className="title--s slow--y">{title}</h2>
                                        <p className="body--m slow--y">{content}</p>
                                        {phone && emails && (
                                            <div className="banner-contact slow--y">
                                                <div>
                                                    <a className="title--s slow--y" href={"tel:+" + phone}>
                                                        <IconPhone />
                                                        {phone}
                                                    </a>
                                                </div>
                                                <div>
                                                    <a href={"mailto:" + emails[0].link} className="slow--y item-email body--l">
                                                        <IconEmail />
                                                        {emails[0].caption}
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                        {cta && <Link className="slow--y" type={isLightbox ? "lightbox" : "arrow"} path={cta.link} label={cta.caption} target={cta.target} />}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <span className="custom-anchor" id={anchor} />
                </div>
            </div>
        );
    }
};

export default Banners;
