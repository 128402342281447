import React, { useEffect, useState } from 'react';

import { Link } from 'React/components';
import { TagTitle } from 'React/components';
import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';
import IconsMap from './_iconsMap';

import { useObserver } from 'React/custom_hooks';

import './style.scss';

const ModuleLastNews = ({ tagLine, content, moduleCta, modulePath }) => {
    const [lastNewsState, setLastNewsState] = useState([]);

    var newsCol;

    if (typeof window !== 'undefined') {
        var culture = "es";
        var url = `/api/CmsController/GetLastNewsList?relativeRoute=${modulePath}&language=${culture}`;
        const xhrPages = new XMLHttpRequest();
        xhrPages.open('get', url, true);
        xhrPages.onload = function (e) {
            if (xhrPages.readyState === 4) {
                if (xhrPages.status === 200) {
                    var newsList = JSON.parse(xhrPages.responseText);

                    newsCol = newsList.map((item, idx) => {
                        if (item) {
                            const refList = lastNewsState.map((item) => React.createRef());

                            if (typeof window !== 'undefined') {
                                const [observer, setElements, entries] = useObserver({
                                    root: null,
                                    threshold: 0.25,
                                });

                                const tl = new TimelineMax({ delay: 0, repeat: 0 });

                                useEffect(() => {
                                    // setting elements to observe
                                    const elements = document.querySelectorAll('.module-news .slow--y');
                                    TweenMax.set(elements, { opacity: 0, y: 90 });
                                    setElements(elements);
                                }, [setElements]);

                                useEffect(() => {
                                    entries.forEach((entry) => {
                                        if (entry.isIntersecting) {
                                            let lazyItem = entry.target;
                                            tl.to(lazyItem, 1, { opacity: 1, y: 0 }, 0.2);
                                            observer.unobserve(lazyItem);
                                        }
                                    });
                                }, [entries, observer, TimelineMax]);
                            }

                            const onHover = (event) => {
                                const { refId } = event.currentTarget.dataset;
                                refList[parseInt(refId)].current.classList.add('is-hovered');
                            };

                            const notHover = (event) => {
                                const { refId } = event.currentTarget.dataset;
                                refList[parseInt(refId)].current.classList.remove('is-hovered');
                            };

                            const maxTextLength = 100;


                            const { title, soluciones, tag, date, intro, link } = item;

                            return (
                                <div className="module-news__col slow--y" key={`news-${idx}`} ref={refList[idx]}>
                                    {title && (
                                        <a href={link} className="link" title={title} data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover}>
                                            <h2 className="title--s">{title}</h2>
                                        </a>
                                    )}

                                    <div className="tag title--small" key={`tag-${idx}`}>
                                        {soluciones.slice(0, 2).map((solution, idx) => {
                                            return (
                                                <a href={solution.url} key={`solution-tag-${idx}`} className="link icon--red" title={solution.name}>
                                                    {solution.icon && IconsMap[solution.icon]}
                                                    {solution && <span>{solution.name}</span>}
                                                    {idx === 0 && soluciones.length > 1 && <span>,&nbsp;</span>}
                                                </a>
                                            );
                                        })}
                                        <span className="date">{date}</span>
                                    </div>


                                    <div>
                                        <div className="body--m" dangerouslySetInnerHTML={{ __html: intro.length < maxTextLength ? intro : `${intro.substr(0, maxTextLength)}...` }}></div>
                                    </div>
                                    {link &&
                                        <Link type="arrowLong" className="arrow" path={link} />
                                    }

                                </div>
                            );

                        }
                    });

                } else {
                    console.error(xhrPages.statusText);
                }
            }
        }.bind(this);

        xhrPages.send(null);
    }


    return (
        <section className="module module-news grid">
            {tagLine && (
                <div className="module-news__header slow--y">
                    <TagTitle title={tagLine} />
                    <Link type="arrow" label="Ver todas las noticias" />
                </div>
            )}

            <div className="grid-container content-wrapper">{newsCol}</div>
            {moduleCta && (
                <div className="cta-footer">
                    <Link type="arrow" label={moduleCta.caption} path={moduleCta.link} />
                </div>
            )}
        </section>
    );
};

export default ModuleLastNews;
