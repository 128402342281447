import React, { useRef } from 'react';

import { useStickyHeader } from 'React/custom_hooks';
import { Link } from 'React/components';

import './HeaderLanding.scss';
import LogoFull from 'Images/logos/logo_full.svg';

const HeaderLanding = ({ stickyHeader = true, logo, hrefLogo }) => {
    const headerRef = useRef();

    // STICKY HEADER ON SCROLL UP
    //useStickyHeader(headerRef, stickyHeader);

    if (typeof window !== 'undefined') {
        return (
            <>
                {!logo && (
                    <header className="header-landing" ref={headerRef} id="header">
                        <div className="landing-header-custom">
                            <Link path="https://www.acciona.com/" className="logo-home-link" target="_blank">
                                <img className="logo" src={LogoFull} alt="Logo Acciona" />
                            </Link>
                            {(window.location.hostname === 'annualreport2019.acciona.com' || window.location.hostname === 'annualreport2019-test.acciona.com') &&
                                <div className="change-language"><Link path="https://informeanual2019.acciona.com/" target="_blank" className="">Español</Link></div>
                            }
                            {window.location.hostname !== 'annualreport2019.acciona.com' && window.location.hostname !== 'annualreport2019-test.acciona.com' &&
                                <div className="change-language"><a href="https://annualreport2019.acciona.com/" target="_blank" className="logo-home-right">English</a></div>
                            }
                        </div>
                    </header>
                )}
                {logo && (
                    <header className="page-header grid header-landing header-landing--logo" ref={headerRef} id="header">
                        <Link path="/" className="logo-home-link">
                            <img className="logo" src={LogoFull} alt="Logo Acciona" />
                        </Link>
                        <Link path={hrefLogo ? hrefLogo.link : '/'} className="logo-home-right">
                            <img
                                className="logo"
                                src={logo.url}
                                alt={logo.name}
                            />
                        </Link>

                        {window.location.hostname === 'annualreport2019.acciona.com' || window.location.hostname === 'annualreport2019-test.acciona.com' &&
                            <Link path="https://informeanual2019.acciona.com/" className="">Español</Link>
                        }
                        {window.location.hostname !== 'annualreport2019.acciona.com' && window.location.hostname !== 'annualreport2019-test.acciona.com' &&
                            <Link path="https://annualreport2019.acciona.com/" className="">English</Link>
                        }

                    </header>
                )}
            </>
        );
    }
    else {
        return (
            <>
                {!logo && (
                    <header className="header-landing" ref={headerRef} id="header">
                        <Link path="https://www.acciona.com/" className="logo-home-link" target="_blank">
                            <img className="logo" src={LogoFull} alt="Logo Acciona" />
                        </Link>
                    </header>
                )}
                {logo && (
                    <header className="page-header grid header-landing header-landing--logo" ref={headerRef} id="header">
                        <Link path="/" className="logo-home-link">
                            <img className="logo" src={LogoFull} alt="Logo Acciona" />
                        </Link>
                        <Link path={hrefLogo ? hrefLogo.link : '/'} className="logo-home-right">
                            <img
                                className="logo"
                                src={logo.url}
                                alt={logo.name}
                            />
                        </Link>
                    </header>
                )}

            </>
        );
    }
};

export default HeaderLanding;
