import React, { useState } from 'react';

import './Styles.scss';

const TextInput = ({ label, initValue = '', errorMsg }) => {
  const [value, setValue] = useState(initValue);

  // =================================
  // errorMsg prop should be sanitized
  // =================================

  return (
    <div className="text_input">
      <input
        className={`${value.trim().length && 'not-empty'} ${errorMsg && 'is-invalid'} body--m`}
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <label className="taglabel">{label}</label>
      <span className="error_msg" dangerouslySetInnerHTML={{ __html: errorMsg }}></span>
    </div>
  );
};

export default TextInput;
