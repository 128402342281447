import React, { useState, useContext, useEffect } from 'react';
import { PropTypes as pt } from 'prop-types';
import ReactPlayer from 'react-player';
import ReactMediumImg from 'react-medium-zoom';

import { useSliderSwipe } from 'React/custom_hooks';
import { TagTitle, Link, Button, InputRangeSlider } from 'React/components';

import './style.scss';

import { ReactComponent as CloseIcon } from 'Icons/cross.svg';
import { ReactComponent as GoLeft } from 'Icons/left.svg';
import { ReactComponent as GoRight } from 'Icons/right.svg';
import { ReactComponent as PlayButton } from 'Icons/video_play-white.svg';
import { ReactComponent as InstagramIcon } from 'Icons/instagram-24.svg';
import { ReactComponent as InfografiaIcon } from 'Icons/infografia-24.svg';

const Mediateca = ({ titleTag, subModules, anchor }) => {
    const [modalIsVisible, setModalVisibility] = useState(false);
    const [canStartVideo, setCanStartVideo] = useState('');
    const [activeFrame, setActiveFrame] = useState(1);
    const [playingVideo, setPlayingVideo] = useState(null);
    //const [canPauseVideo, setcanPauseVideo] = useState(false);


    const iconsMap = {
        instagram: <InstagramIcon />,
        infografia: <InfografiaIcon />,
    };


    const swipableComponent = React.createRef();


    const showLightbox = (frameId = 1) => {
        setModalVisibility(true);
        setActiveFrame(frameId);
        if (subModules[frameId - 1].src_url.videoId) {
            handleClickOnVideo(subModules[frameId - 1].src_url.videoId);
        }
        document.querySelector('.app--layout').dataset.canScroll = false;
    };

    const hideLightbox = () => {
        setCanStartVideo(false);
        setModalVisibility(false);
        setActiveFrame(1);
        setPlayingVideo(null);
        document.querySelector('.app--layout').dataset.canScroll = true;
    };

    const shiftSlider = (direction) => {
        setCanStartVideo(false);
        setActiveFrame((oldState) => {
            const curState = parseInt(oldState);

            if (direction === 'right' && curState < subModules.length) {
                return curState + 1;
            } else if (direction === 'left' && curState > 1) {
                return curState - 1;
            } else {
                return oldState;
            }
        });
    };

    useEffect(() => {
        setPlayingVideo(null);
    }, [activeFrame]);

   

    const handleClickOnVideo = (videoId) => {
        //if (canPauseVideo === true) {
        //    setCanStartVideo(false);
        //    setcanPauseVideo(false);
        //}
        //else {
            setCanStartVideo(videoId);
            //setcanPauseVideo(true);
            setPlayingVideo((oldState) => {
                if (oldState === videoId) {
                    return null;
                } else {
                    return videoId;
                }
            });
        //}
    };

    useSliderSwipe(swipableComponent, setActiveFrame, subModules.length);

    return (
        <section className="module grid module-gallery">
            <TagTitle title={titleTag} style="slow--y" />
            <div className="gallery-container grid" id={anchor}>
                <div className="wrapper">
                    {subModules.map((item, idx) => {
                        const img_src = item.src_url.thumbnail ? item.src_url.thumbnail : item.src_url.url;
                        if (item.preview_size === 'full') {
                            return (
                                <div
                                    style={{ backgroundImage: `url(${img_src})` }}
                                    onClick={() => showLightbox(idx + 1)}
                                    className="gallery-featured media-item media-type-full"
                                    key={`media-item-${idx}`}
                                >
                                    {item.src_url.videoId && <PlayButton />}
                                    {item.gallery_icon && <div className="gallery_icon_wrapper">{iconsMap[item.gallery_icon]}</div>}
                                </div>
                            );
                        }

                        if (item.preview_size === 'half') {
                            return (
                                <div
                                    style={{ backgroundImage: `url(${img_src})` }}
                                    onClick={() => showLightbox(idx + 1)}
                                    className="gallery-col gallery-col--left media-item media-type-half"
                                    key={`media-item-${idx}`}
                                >
                                    {item.src_url.videoId && <PlayButton />}
                                    {item.gallery_icon && <div className="gallery_icon_wrapper">{iconsMap[item.gallery_icon]}</div>}
                                </div>
                            );
                        }
                    })}
                </div>
            </div>

            <div className="mediateca-lightbox" data-is-visible={modalIsVisible}>
                <div className="close-button-wrapper grid">
                    <Button onClick={hideLightbox} className="close-lightbox-btn">
                        <CloseIcon />
                    </Button>
                </div>
                {subModules.length > 1 && (
                    <>
                        <Button onClick={() => shiftSlider('left')} className="slide-btn slide-left-btn">
                            <GoLeft />
                        </Button>
                        <Button onClick={() => shiftSlider('right')} className="slide-btn slide-right-btn">
                            <GoRight />
                        </Button>
                    </>
                )}
                <div
                    className="lightbox-content-slider"
                    ref={swipableComponent}
                    data-active-frame={activeFrame}
                    style={{ width: `${100 * subModules.length}vw` }}
                >
                    {subModules.map((frame, idx) => {
                        const { src_url, src_type, descr, media_cta } = frame;

                        return (
                            <div className="frame grid" data-frame-id={idx + 1} key={`media-frame-${idx}`} >
                                <div className="frame-content custom-height-media">
                                    {src_url.type === 'jpg' && (
                                        <div className="image-container">
                                            <ReactMediumImg src={src_url.url} />
                                        </div>
                                    )}
                                    {src_url.type === 'png' && (
                                        <div className="image-container">
                                            <ReactMediumImg src={src_url.url} />
                                        </div>
                                    )}
                                    {src_url.videoId && (
                                        <div
                                            className="react-player-container"
                                            onClick={() => handleClickOnVideo(src_url.videoId)}
                                        >
                                            <ReactPlayer
                                                width="100%"
                                                height="auto"
                                                url={'//www.youtube.com/watch?v=' + src_url.videoId}
                                                playing={canStartVideo === src_url.videoId}
                                                controls
                                            />
                                        </div>
                                    )}

                                    {descr && <p className=" item-description body--l">{descr}</p>}

                                    {media_cta && (
                                        <Link
                                            path={media_cta.link}
                                            label={media_cta.caption}
                                            type={media_cta.type}
                                            fileParams={media_cta.fileParams}
                                            target={media_cta.target}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
                {subModules.length > 1 && (
                    <div className="range-slider-wrapper grid">
                        <InputRangeSlider
                            changeHandler={setActiveFrame}
                            activeFrame={parseInt(activeFrame)}
                            framesCount={subModules.length}
                        />
                    </div>
                )}
            </div>
        </section>
    );
};

//Mediateca.propTypes = {
//    subModules: pt.arrayOf(
//        pt.shape({
//            src_url: pt.string.isRequired,
//            src_type: pt.oneOf(['image', 'video']).isRequired,
//            preview_url: pt.string,
//            preview_size: pt.oneOf(['full', 'half']).isRequired,
//            descr: pt.string,
//            media_cta: pt.shape({
//                label: pt.string.isRequired,
//                path: pt.string.isRequired,
//                type: pt.oneOf(['Media', 'Internal', 'External']).isRequired,
//                fileParams: pt.string,
//            }),
//        })
//    ).isRequired,
//};

export default Mediateca;
