// Atoms
export { default as Button } from './Button/Button';
export { default as Link } from './Link/Link';
export { default as SearchForm } from './SearchForm/SearchForm';
export { default as TagTitle } from './TagTitle/TagTitle';
export { default as TitleLink } from './TitleLink/TitleLink';
export { default as Table } from './Table/Table';
export { default as TableDouble } from './TableDouble/TableDouble';
export { default as InputRangeSlider } from './InputRangeSlider';
export { default as GridWrapper } from './GridWrapper';

// Components
//export { default as ProjectsMap } from './ProjectsMap/ProjectsMap';
//export { default as DistribuidorSimple } from './DistribuidorSimple/';
//export { default as DistribuidorDestacado } from './DistribuidorDestacado/';
//export { default as DistribuidorDestacadoAreas } from './DistribuidorDestacadoAreas/';
//export { default as Distribuidor2Col } from './Distribuidor2Col';
//export { default as DistribuidorMultiple } from './DistribuidorMultiple';
export { default as ModuleNews } from './ModuleNews/';
export { default as HeaderSlider } from './HeaderSlider/HeaderSlider';
export { default as HeroImage } from './HeroImage';
export { default as ItemsFilter } from './ItemsFilter/ItemsFilter';
export { default as Lightbox } from './Lightbox/Lightbox';
export { default as TrabajaConNosotros } from './TrabajaConNosotros/';

//export { default as AreasActividad } from './AreasActividad';
//export { default as CifrasSlider } from './CifrasSlider';
//export { default as Cifras } from './Cifras';
export { default as TrabajaConNosotrosSimple } from './TrabajaConNosotrosSimple';
export { default as BloqueTexto } from './Texto';
//export { default as Banners } from './Banners';
export { default as ShareSocial } from './ShareSocial';
//export { default as DistribuidorIconos } from './DistribuidorIconos';
export { default as Breadcrumbs } from './Breadcrumbs';
export { default as Wysiwyg } from './Wysiwyg';
export { default as WysiwygNoticia } from './WysiwygNoticia';
export { default as WysiwygEmpleo } from './WysiwygEmpleo';
//export { default as Mediateca } from './Mediateca';
export { default as Descargas } from './Descargas';
export { default as DescargasItem } from './DescargaItem';
//export { default as Soluciones } from './Soluciones';
export { default as Paginator } from './Paginator';
export { default as PaginatorSimple } from './Paginator Simple';
//export { default as Proyectos } from './Proyectos';
//export { default as DistribuidorDestacadoSlider } from './DistribuidorDestacadoSlider';
//export { default as SolucionesBotonera } from './SolucionesBotonera';
//export { default as TestimoniosSlider } from './TestimoniosSlider';
export { default as BuscadorFiltros } from './BuscadorFiltros';
export { default as BuscadorFiltrosProyectos } from './BuscadorFiltrosProyectos';
//export { default as Timeline } from './Timeline';
export { default as ButtonCta } from './ButtonCta';
//export { default as Acordeon } from './Acordeon';
//export { default as LogosSlider } from './LogosSlider';
export { default as EnlaceAgrupador } from './EnlaceAgrupador';
export { default as Resultados } from './Resultados';
export { default as ResultadosProyectos } from './ResultadosProyectos';
//export { default as ContactForm } from './ContactForm';
export { default as EquipoDirectivo } from './EquipoDirectivo';

// Forms components
export { default as CheckboxGroup } from './FormInputs/CheckboxGroup';
export { default as Checkbox } from './FormInputs/Checkbox';
export { default as RadioButton } from './FormInputs/Radio';
export { default as TextInput } from './FormInputs/Text';
export { default as SelectInput } from './FormInputs/Select';
export { default as TextArea } from './FormInputs/TextArea';
export { default as ProjectsMapStatic } from './ProjectsMapStatic/index.js';


// dev
//export { default as ModuloContenidoDev } from './ModuloContenidoDev';

// wrapper components
export { default as withSolucionesNav } from './_wrapper_components/withSolucionesNav';

// html-tags components
// those component return standard HTML tags, we use them to allow rendering based on model object
export { default as img } from './_html_tags/img';
