// to add more icon options, update IconsMap file

import React, { useState, useEffect } from 'react';
import { PropTypes as pt } from 'prop-types';

import './styles.scss';
import { TagTitle, InputRangeSlider } from 'React/components';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';

import { useSliderSwipe } from 'React/custom_hooks';

import { ReactComponent as IconArrowRight } from 'Icons/ArrowLong.svg';
import IconsMap from './_iconsMap';
import { useFade } from '../../custom_hooks';

const DistribuidorIconos = ({ title, subModules, anchor }) => {
    if (typeof window !== 'undefined') {
        const [activeFrame, setActiveFrame] = useState(1);
        const thisModule = React.createRef();
        const context = window.innerWidth;

        const prevButton = React.createRef();
        const nextButton = React.createRef();

        const nextHover = () => {
            nextButton.current.classList.add('is-animated');
            prevButton.current.classList.remove('is-animated');
        };

        const prevHover = () => {
            prevButton.current.classList.add('is-animated');
            nextButton.current.classList.remove('is-animated');
        };

        const removeHover = () => {
            prevButton.current.classList.remove('is-animated');
            nextButton.current.classList.remove('is-animated');
        };

        const nextFrame = () => {
            if (parseInt(activeFrame) < sliderFramesCount) {
                setActiveFrame(parseInt(activeFrame) + 1);
            }
        };

        const prevFrame = () => {
            if (parseInt(activeFrame) > 1) {
                setActiveFrame(parseInt(activeFrame) - 1);
            }
        };

        const sliderSpans = (framesCount) => {
            if (context < 1024) return `${100 * framesCount}vw`;

            const spanCount = framesCount % 3 === 0 ? framesCount / 3 : parseInt(framesCount / 3) + 1;

            return `${100 * spanCount}%`;
        };

        const sliderOffset = (framesCount, activeFrame) => {
            if (context < 1024) {
                return `${100 * (activeFrame - 1) * -1}vw`;
            }
            const extraSlots = framesCount % 3 === 0 ? 0 : 3 - (framesCount % 3);

            return (100 / (framesCount + extraSlots)) * (activeFrame - 1) * -1 + '%';
        };

        const framesContainerStyle = {
            width: sliderSpans(subModules.length),
            transform: `translateX(${sliderOffset(subModules.length, activeFrame)})`,
        };

        const refList = subModules.map((frame) => React.createRef());

        const onHover = (event) => {
            const { frameId } = event.currentTarget.dataset;
            refList[parseInt(frameId)].current.classList.add('is-hovered');
        };

        const notHover = (event) => {
            const { frameId } = event.currentTarget.dataset;
            refList[parseInt(frameId)].current.classList.remove('is-hovered');
        };

        const sliderFramesCount = context >= 1024 ? subModules.length - 2 : subModules.length;

        useSliderSwipe(thisModule, setActiveFrame, sliderFramesCount);
        useFade(thisModule);

        return (
            <div className="module grid distribuidor-iconos with-slider" data-active-frame={activeFrame} ref={thisModule}>
                <div className="inner-container slow">
                    {subModules.length > 1 && (
                        <>
                            <button
                                className="ghost-frame-button ghost-frame-button--left"
                                onMouseOver={prevHover}
                                onMouseOut={removeHover}
                                onClick={prevFrame}
                            >
                                Slider anterior
            </button>
                            <button
                                className="ghost-frame-button ghost-frame-button--right"
                                onMouseOver={nextHover}
                                onMouseOut={removeHover}
                                onClick={nextFrame}
                            >
                                Slider siguiente
            </button>
                        </>
                    )}
                    <TagTitle style="slow" title={title} />
                    <div className="frames-container" style={framesContainerStyle}>
                        <span className="custom-anchor-small" id={anchor} />
                        {subModules.map((frame, idx) => {
                            const { icon_type, icon_label, title, text, cta } = frame;

                            return (
                                <div className="frame" data-frame-id={idx + 1} key={`tecno-frame-${idx}`} ref={refList[idx]}>
                                    <div className="icon" data-frame-id={idx + 1}>
                                        {IconsMap[icon_type]}
                                    </div>
                                    <div className="text">
                                        <h2 className="icon-label title--xs">{icon_label}</h2>
                                        <h3 className="frame-title title--m" data-frame-id={idx} onMouseOut={notHover} onMouseOver={onHover}>
                                            <a href={cta ? cta.link : ''} target={cta ? cta.target : ''}>{title}</a>
                                        </h3>
                                        <p className="body--m">{text}</p>
                                        <div data-frame-id={idx} onMouseOut={notHover} onMouseOver={onHover} className="frame-cta">
                                            {cta &&
                                                <a href={cta.link} target={cta.target}>
                                                    <IconArrowRight />
                                                </a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <InputRangeSlider
                        changeHandler={setActiveFrame}
                        noIndicator
                        framesCount={sliderFramesCount}
                        activeFrame={parseInt(activeFrame)}
                    />
                    <div className="slider-controls slow">
                        <button ref={prevButton} className={`prev-button ${parseInt(activeFrame) === 1 ? 'is-disabled' : ''}`} onClick={prevFrame}>
                            <IconArrowRightLong />
                        </button>
                        <button ref={nextButton} className={`next-button ${parseInt(activeFrame) === sliderFramesCount ? 'is-disabled' : ''}`} onClick={nextFrame}>
                            <IconArrowRightLong />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <></>
    );
};

DistribuidorIconos.propTypes = {
    title: pt.string,
    subModules: pt.arrayOf(
        pt.shape({
            icon_type: pt.oneOf(Object.keys(IconsMap)),
            title: pt.string,
            text: pt.string,
        })
    ).isRequired,
};

export default DistribuidorIconos;
