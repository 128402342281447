import React, { useState } from 'react';
import { ReactComponent as IconDownload } from 'Icons/download.svg';
import './styles.scss';
import { Link, Lightbox, Wysiwyg, TagTitle, EnlaceAgrupador } from 'React/components';
import { rndKeyGen } from 'React/helpers';
import { useSlowFade } from 'React/custom_hooks';

const Descargas = ({ anchor, type, moduleTitle, title, link, lightboxContent, leftTitle, leftLink, leftLightboxContent, leftDownloads, leftGroupLink, rightTitle, rightLink, rightLightboxContent, rightDownloads, rightGroupLink }) => {
    const [visibleLightboxState, setVisibleLightboxState] = useState(false);
    const [lightboxContentState, setLightboxContentState] = useState('test');

    let moduleRef = React.createRef();
    useSlowFade(moduleRef);


    const handleLightboxClick = (content) => {
        if (content !== '') {
            setLightboxContentState(content);
            setVisibleLightboxState(true);
        }
    };

    //if (type == 'component') {
    //    return (
    //        <ul className="list list-downloads">
    //            {link &&
    //                <li className="slow--y"><Link type={lightboxContent ? 'lightbox' : link.type} label={link.caption} path={link.link} target={link.target} /></li>
    //            }
    //            {leftLink &&
    //                <li className="slow--y"><Link type={leftLightboxContent ? 'lightbox' : leftLink.type} label={leftLink.caption} path={leftLink.link} target={leftLink.target} /></li>
    //            }
    //            {leftDownloads.map((item, idx) => {
    //                return <li key={`text-p-${idx}`} className="slow--y link--download main-text-paragraph"><a className="icon--red link module--cta" href="#"><IconDownload viewBox="0 0 18 18" width="15" />
    //                    <p>{item.caption}</p><p className="link--download-type">PDF 813KB</p></a>
    //                </li>
    //            })}
    //        </ul>
    //    );
    //}

    function bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    if (type == 'two-columns') {
        return (
            <div ref={moduleRef} className="module descargas grid">
                {moduleTitle &&
                    <div className="descargas__header slow--y">
                        <TagTitle title={moduleTitle} />
                        {link &&
                            <Link type="arrow" label={link.caption} path={link.link} target={link.target} />
                        }
                    </div>
                }
                <h2 className="title--m slow--y">{leftTitle}</h2>
                {leftDownloads && !leftGroupLink &&
                    <div className="descargas__col-left">
                        <ul className="list list-downloads">
                            {leftLink &&
                                <li className="slow--y">
                                    {leftLightboxContent &&
                                        <span onClick={() => handleLightboxClick(leftLightboxContent)}><Link label={leftLink.caption} type='lightbox' /></span>
                                    }
                                    {!leftLightboxContent &&
                                        <Link type={leftLink.type} label={leftLink.caption} path={leftLink.link} target={leftLink.target} />
                                    }
                                </li>
                            }
                            {leftDownloads.map((item, idx) => {
                                if (item.umbracoFile) {
                                    return <li key={`text-p-${idx}`} className="slow--y link--download main-text-paragraph"><a className="icon--red link module--cta" target='_blank' href={item.umbracoFile ? item.umbracoFile : ''}><IconDownload viewBox="0 0 18 18" width="15" />
                                        <p>{item.name ? item.name : ''}</p><p className="link--download-type">{item.umbracoExtension.toUpperCase()} {bytesToSize(item.umbracoBytes)}</p></a>
                                    </li>
                                }
                                else {
                                    return <li key={`text-p-${idx}`} className="slow--y link--download main-text-paragraph"><a className="icon--red link module--cta" target='_blank' href={item.file ? item.file : ''}><IconDownload viewBox="0 0 18 18" width="15" />
                                        <p>{item.title ? item.title : item.recordNumber}</p><p className="link--download-type">PDF </p></a>
                                    </li>
                                }
                            })}
                    </ul>
                    <span className="custom-anchor" id={anchor} />
                    </div>
                }
                {leftDownloads && leftGroupLink &&
                    <div className="descargas__col-left">
                        <EnlaceAgrupador links={leftDownloads} box_label={leftGroupLink} />
                    </div>
                }
                {rightDownloads && !rightGroupLink &&
                    <div className="descargas__col-right">
                        <ul className="list list-downloads">
                            {rightLink &&
                                <li className="slow--y">
                                    {rightLightboxContent &&
                                        <span onClick={() => handleLightboxClick(rightLightboxContent)}><Link label={rightLink.caption} type='lightbox' /></span>
                                    }
                                    {!rightLightboxContent &&
                                        <Link type={rightLink.type} label={rightLink.caption} path={rightLink.link} target={rightLink.target} />
                                    }
                                </li>
                            }
                            {rightDownloads.map((item, idx) => {
                                if (item.umbracoFile) {
                                    return <li key={`text-p-${idx}`} className="slow--y link--download main-text-paragraph"><a className="icon--red link module--cta" target='_blank' href={item.umbracoFile ? item.umbracoFile : ''}><IconDownload viewBox="0 0 18 18" width="15" />
                                        <p>{item.name ? item.name : ''}</p><p className="link--download-type">{item.umbracoExtension.toUpperCase()} {bytesToSize(item.umbracoBytes)}</p></a>
                                    </li>
                                }
                                else {
                                    return <li key={`text-p-${idx}`} className="slow--y link--download main-text-paragraph"><a className="icon--red link module--cta" target='_blank' href={item.file ? item.file : ''}><IconDownload viewBox="0 0 18 18" width="15" />
                                        <p>{item.title ? item.title : item.recordNumber}</p><p className="link--download-type">PDF </p></a>
                                    </li>
                                }
                            })}
                        </ul>
                    </div>
                }
                {rightDownloads && rightGroupLink &&
                    <div className="descargas__col-right">
                        <EnlaceAgrupador links={rightDownloads} box_label={rightGroupLink} />
                    </div>
                }
                <Lightbox
                    key={rndKeyGen()}
                    isVisible={visibleLightboxState}
                    closeMethod={() => setVisibleLightboxState(null)}
                >
                    <Wysiwyg html={lightboxContentState} />
                </Lightbox>
            </div>
        );
    } else {
        return (
            <div ref={moduleRef} className="module descargas descargas--single grid">
                {moduleTitle &&
                    <div className="descargas__header slow--y">
                        <TagTitle title={moduleTitle} />
                        {link &&
                            <Link type={lightboxContent ? 'lightbox' : link.type} label={link.caption} path={link.link} target={link.target} />
                        }
                    </div>
                }

                {leftDownloads && !leftGroupLink &&
                    <div className="descargas__col-left">
                        <h2 className="title--m slow--y">{leftTitle}</h2>
                        <ul className="list list-downloads">
                            {leftLink &&
                                <li className="slow--y">
                                    {leftLightboxContent &&
                                        <span onClick={() => handleLightboxClick(leftLightboxContent)}><Link label={leftLink.caption} type='lightbox' /></span>
                                    }
                                    {!leftLightboxContent &&
                                        <Link type={leftLink.type} label={leftLink.caption} path={leftLink.link} target={leftLink.target} />
                                    }
                                </li>
                            }
                            {leftDownloads.map((item, idx) => {
                                if (item.umbracoFile) {
                                    return <li key={`text-p-${idx}`} className="link--download main-text-paragraph slow--y"><a className="icon--red link module--cta" target='_blank' href={item.umbracoFile ? item.umbracoFile : ''}><IconDownload viewBox="0 0 18 18" width="15" />
                                        <p>{item.name ? item.name : ''}</p><p className="link--download-type">{item.umbracoExtension.toUpperCase()} {bytesToSize(item.umbracoBytes)}</p></a>
                                    </li>
                                }
                                else {
                                    return <li key={`text-p-${idx}`} className="link--download main-text-paragraph slow--y"><a className="icon--red link module--cta" target='_blank' href={item.file ? item.file : ''}><IconDownload viewBox="0 0 18 18" width="15" />
                                        <p>{item.title ? item.title : item.recordNumber}</p><p className="link--download-type">PDF {/*{bytesToSize(item.umbracoBytes)*/}</p></a>
                                    </li>
                                }
                            })}
                    </ul>
                    <span className="custom-anchor" id={anchor} />
                    </div>
                }
                {leftDownloads && leftGroupLink &&
                    <div className="descargas__col-left">
                        <h2 className="title--m slow--y">{leftTitle}</h2>
                        <ul className="list list-downloads">
                            {leftLink &&
                                <li className="slow--y">
                                    {leftLightboxContent &&
                                        <span onClick={() => handleLightboxClick(leftLightboxContent)}><Link label={leftLink.caption} type='lightbox' /></span>
                                    }
                                    {!leftLightboxContent &&
                                        <Link type={leftLink.type} label={leftLink.caption} path={leftLink.link} target={leftLink.target} />
                                    }
                                </li>
                            }
                            <EnlaceAgrupador links={leftDownloads} box_label={leftGroupLink} />
                        </ul>
                    </div>
                }
                {rightDownloads && !rightGroupLink &&
                    <div className="descargas__col-right">
                        <h2 className="title--m slow--y">{rightTitle}</h2>
                        <ul className="list list-downloads">
                            {rightLink &&
                                <li className="slow--y">
                                    {rightLightboxContent &&
                                        <span onClick={() => handleLightboxClick(rightLightboxContent)}><Link label={rightLink.caption} type='lightbox' /></span>
                                    }
                                    {!rightLightboxContent &&
                                        <Link type={rightLink.type} label={rightLink.caption} path={rightLink.link} target={rightLink.target} />
                                    }
                                </li>
                            }
                            {rightDownloads.map((item, idx) => {
                                if (item.umbracoFile) {
                                    return <li key={`text-p-${idx}`} className="link--download main-text-paragraph slow--y"><a className="icon--red link module--cta" target='_blank' href={item.umbracoFile ? item.umbracoFile : ''}><IconDownload viewBox="0 0 18 18" width="15" />
                                        <p>{item.name ? item.name : ''}</p><p className="link--download-type">{item.umbracoExtension.toUpperCase()} {bytesToSize(item.umbracoBytes)}</p></a>
                                    </li>
                                }
                                else {
                                    return <li key={`text-p-${idx}`} className="link--download main-text-paragraph slow--y"><a className="icon--red link module--cta" target='_blank' href={item.file ? item.file : ''}><IconDownload viewBox="0 0 18 18" width="15" />
                                        <p>{item.title ? item.title : item.recordNumber}</p><p className="link--download-type">PDF {/*{bytesToSize(item.umbracoBytes)*/}</p></a>
                                    </li>
                                }
                            })}
                        </ul>
                    </div>
                }
                {rightDownloads && rightGroupLink &&
                    <div className="descargas__col-right">
                        <h2 className="title--m slow--y">{rightTitle}</h2>
                        <ul className="list list-downloads">
                            {rightLink &&
                                <li className="slow--y">
                                    {rightLightboxContent &&
                                        <span onClick={() => handleLightboxClick(rightLightboxContent)}><Link label={rightLink.caption} type='lightbox' /></span>
                                    }
                                    {!rightLightboxContent &&
                                        <Link type={rightLink.type} label={rightLink.caption} path={rightLink.link} target={rightLink.target} />
                                    }
                                </li>
                            }
                            <EnlaceAgrupador links={rightDownloads} box_label={rightGroupLink} />
                        </ul>
                    </div>
                }
                <Lightbox
                    key={rndKeyGen()}
                    isVisible={visibleLightboxState}
                    closeMethod={() => setVisibleLightboxState(null)}
                >
                    <Wysiwyg html={lightboxContentState} />
                </Lightbox>
            </div>
        );
    }
};

export default Descargas;
