import React, { useState, useEffect } from 'react';
import { useSlowFade } from 'React/custom_hooks';
import { TagTitle, Link, Wysiwyg, EnlaceAgrupador } from 'React/components';

import './styles.scss';
import { ReactComponent as ChevronIcon } from 'Icons/chevron_down-18.svg';

const Acordeon = ({ tagTitle, moduleCTA, subModules, columns, anchor }) => {
    let moduleRef = React.createRef();
    useSlowFade(moduleRef);

    const [activeSection, setActiveSection] = useState();

    const toggleSection = (sectionId) => {
        setActiveSection((oldState) => (oldState === sectionId ? null : sectionId));
    };

    return (
        <div
            ref={moduleRef}
            className={`module grid acordeon 
        ${columns === '1' ? 'single-column' : ''} 
        ${subModules[0].image ? 'with-image' : ''}`} // if first item doesn't have image we assume this acordeon doesn't have images
        >
            {tagTitle && <TagTitle style="slow--y" title={tagTitle} />}

            {moduleCTA && typeof window != 'undefined' && useWindowWidth() >= 680 && (
                <Link className="module-cta" type={moduleCTA.type} path={moduleCTA.link} label={moduleCTA.caption} />
            )}
            <div className="distribuidor-2-col-container grid">
                {subModules.map((item, idx) => {
                    const { image, title, content , downloads, groupLink} = item;

                    return (
                        <div
                            key={`data-section-${idx + 1}`}
                            data-is-active={activeSection === idx + 1}
                            className="distribuidor-2-col-item slow--y"
                        >
                            {image && (
                                <div className="image-container">
                                    <img src={image.url} />
                                </div>
                            )}
                            <div className="title-container" onClick={() => toggleSection(idx + 1)}>
                                <h2 className="title--s title">{title}</h2>
                                <ChevronIcon />
                            </div>

                            {content && content.trim().startsWith('<') && (
                                <div className="main-content text-container">
                                    <Wysiwyg html={content} />
                                </div>
                            )}

                            {content && !content.trim().startsWith('<') && (
                                <div className="main-content text-container">
                                    <p className="lead-text body--m">{content}</p>
                                </div>
                            )}

                            {downloads && !groupLink && (
                                <ul className="list list-downloads">
                                    {downloads.map((item, idx) => {
                                        return (
                                            <li key={`text-p-${idx}`} className="slow--y link--download main-text-paragraph">
                                                <Link type="download" path={item.umbracoFile} label={item.name} bytes={item.umbracoBytes} extension={item.umbracoExtension} />
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                            {/*downloads && groupLink && (
                                <ul className="list list-downloads">
                                    <EnlaceAgrupador links={downloads} box_label={groupLink} />
                                    </ul>
                            )*/}
                        </div>
                    );
                })}
                <span className="custom-anchor" id={anchor} />
            </div>
            {moduleCTA && typeof window != 'undefined' && useWindowWidth() < 680 && (
                <Link className="module-cta" type={moduleCTA.type} path={moduleCTA.link} label={moduleCTA.caption} target={moduleCTA.target} />
            )}
        </div>
    );
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};

export default Acordeon;
