import React, { useRef, useState, useEffect } from 'react';

import { Link, Button } from 'React/components';

import './Header.scss';
import './NavSecondary.scss';
import './NavSubsection.scss';

import LogoFull from 'Images/logos/logo_full.svg';

import { useStickyHeader } from 'React/custom_hooks';

import { ReactComponent as User } from 'Icons/usuario-16.svg';
import { ReactComponent as HamburgerMenuIcon } from 'Icons/hamburgermenu.svg';
import { ReactComponent as HamburgerMenuIconTablet } from 'Icons/hamburgermenu-tablet.svg';
import { ReactComponent as Triangle } from 'Icons/triangleup.svg';
import { ReactComponent as SearchLense } from 'Icons/searchlense.svg';
import { ReactComponent as Plus } from 'Icons/plus-10.svg';
import { ReactComponent as IconExternal } from 'Icons/linkexternal.svg';
import { ReactComponent as IconClose } from 'Icons/cross.svg';
import { ReactComponent as ArrowRight } from 'Icons/arrow_circled.svg';

import { ReactComponent as EnergyIcon } from 'Icons/soluciones/energia.svg';
import { ReactComponent as CiudadesIcon } from 'Icons/soluciones/ciudad.svg';
import { ReactComponent as MovilidadIcon } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as AguaIcon } from 'Icons/soluciones/agua.svg';
import { ReactComponent as SocialIcon } from 'Icons/soluciones/social.svg';
import { ReactComponent as CapitalIcon } from 'Icons/soluciones/capital.svg';
import { ReactComponent as InmobiliariaIcon } from 'Icons/soluciones/inmobiliaria.svg';

import { ReactComponent as Chevron_L } from 'Icons/chevron_down-18.svg';

const Header = ({ isVisible, stickyHeader }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const searchInput = useRef(null);
    const searchInputTestStand = useRef(null);

    const swipeTargetHeader = React.createRef();
    const desktopNav = React.createRef();
    const mobileNav = React.createRef();
    const navDrawer = React.createRef();
    const countriesRef = React.createRef();
    const searchRef = React.createRef();

    const headerRef = useRef();

    function toggleMobileSubsection(event) {
        event.currentTarget.classList.toggle('active');

        document.querySelectorAll('.subsection__toggle').forEach(function (item) {
            if (item !== event.currentTarget) {
                item.classList.remove('active');
            }
        });
    }

    const onHover = (event) => {
        const navSubsections = [...document.querySelectorAll('.nav_sub_section')];

        navSubsections.forEach(function (item) {
            if (item.dataset.section === event.currentTarget.dataset.sectionId) {
                item.dataset.isVisible = true;
                item.classList.add('is-visible');
                navDrawer.current.dataset.sectionActive = item.dataset.section;
                navDrawer.current.dataset.isVisible = true;
                navDrawer.current.classList.add('is-visible');
            }

            if (item.dataset.section !== event.currentTarget.dataset.sectionId) {
                item.dataset.isVisible = false;
                item.classList.remove('is-visible');
            }

            if (event.currentTarget.dataset.children === 'no') {
                navDrawer.current.dataset.isVisible = false;
                navDrawer.current.classList.remove('is-visible');
            }
        });
    };

    const notHover = (event) => {
        const navSubsections = [...document.querySelectorAll('.nav_sub_section')];

        navSubsections.forEach(function (item) {
            if (item.dataset.section !== event.currentTarget.dataset.sectionId) {
                item.dataset.isVisible = false;
                item.classList.remove('is-visible');
            }
        });
    };

    const toggleSubsectionVisibility = () => {
        navDrawer.current.dataset.isVisible = false;
        navDrawer.current.classList.remove('is-visible');
    };

    const openMenu = () => {
        mobileNav.current.dataset.isVisible = true;
        document.querySelector('.app--layout').dataset.canScroll = false;
    };

    const closeMenu = () => {
        mobileNav.current.dataset.isVisible = false;
        document.querySelector('.app--layout').dataset.canScroll = true;
    };

    const openCountriesOverlay = () => {
        countriesRef.current.dataset.isVisible = true;
    };

    const closeCountriesOverlay = () => {
        countriesRef.current.dataset.isVisible = false;
    };

    const openSearchOverlay = () => {
        searchRef.current.dataset.isVisible = true;
    };

    const closeSearchOverlay = () => {
        searchRef.current.dataset.isVisible = false;
    };

    useEffect(() => {
        if (isVisible) searchInput.current.focus();
    }, [isVisible]);

    const handleInput = (e) => {
        setSearchTerm(e.target.value);

        const input = searchInput.current;
        const testStand = searchInputTestStand.current;

        // measure the length of the input text and update input width
        testStand.innerText = searchTerm;
        input.style.width = `calc(2em + ${testStand.getBoundingClientRect().width}px)`;
    };

    const handleSearchSubmit = () => {
        console.log('searching for ' + searchTerm);
    };

    const countriesList = [
        { label: 'Australia', path: '#australia' },
        { label: 'Canadá', path: '#canada' },
        { label: 'Chile', path: '#chilie' },
        { label: 'Japón', path: '#japan' },
        { label: 'Alemania', path: '#germany' },
        { label: 'Australia', path: '#australia' },
        { label: 'Canadá', path: '#canada' },
        { label: 'Chile', path: '#chilie' },
        { label: 'Australia', path: '#australia' },
        { label: 'Canadá', path: '#canada' },
        { label: 'Chile', path: '#chilie' },
        { label: 'Japón', path: '#japan' },
        { label: 'Alemania', path: '#germany' },
        { label: 'Australia', path: '#australia' },
        { label: 'Canadá', path: '#canada' },
        { label: 'Chile', path: '#chilie' },
    ];

    useStickyHeader(headerRef, stickyHeader);

    return (
        <>
            <header ref={headerRef} className="page-header grid" id="header">
                <a className="logo-home-link" href="/es/">
                    <img className="logo" src={LogoFull} alt="Logo Acciona" />
                </a>

                <div className="header__row header__top">
                    <p className="stock-ticker">
                        <Triangle width={12} height={12} />
                        <span>(IBEX 35) 46,1 0,7%</span>
                    </p>
                    <div className="header-right">
                        <Button type="link" className="country-selector" onClick={openCountriesOverlay}>
                            <span>Países</span>
                            <Plus width="10px" height="10px" viewBox="0 0 10 10" />
                        </Button>
                        <span className="clientes-touch">
                            <a href="#area_clientes">
                                <User viewBox="0 1 17 17" />
                                Clientes
              </a>
                        </span>
                    </div>
                    <HamburgerMenuIcon className="icon icon__hamburger icon__hamburger--mobile" onClick={openMenu} />
                    <HamburgerMenuIconTablet className="icon icon__hamburger  icon__hamburger--tablet" onClick={openMenu} />

                    <nav className="nav_secondary" aria-label="menú de navegación secundaria" role="navigation">
                        <a href="#contacto">Contacto</a>
                        <a href="#english">English</a>
                        <a href="#area_clientes">
                            <User viewBox="0 1 17 17" />
                            Área de clientes
            </a>
                    </nav>

                    <button className="open-search-form" onClick={openSearchOverlay} aria-label="open search form">
                        <SearchLense />
                    </button>
                </div>

                <div className="header__row header__bottom">
                    <nav
                        ref={desktopNav}
                        className="nav__main nav__main--desktop"
                        role="navigation"
                        aria-label="Navegación principal para escritorio"
                    >
                        <ul className="nav_links">
                            <a
                                className="link link-lvl-0"
                                href="/es/nuestro-proposito/"
                                onMouseOut={notHover}
                                onMouseOver={onHover}
                                data-children="has-children"
                                data-section-id="sobre_acciona"
                            >
                                Nuestro propósito
                <Chevron_L />
                            </a>
                            <a
                                className="link link-lvl-0"
                                href="/soluciones"
                                onMouseOut={notHover}
                                onMouseOver={onHover}
                                data-children="has-children"
                                data-section-id="soluciones"
                            >
                                Soluciones
                <Chevron_L />
                            </a>
                            <a
                                className="link link-lvl-0"
                                href="/proyectos"
                                data-section-id="proyectos"
                                data-children="no"
                                onMouseOut={notHover}
                                onMouseOver={onHover}
                            >
                                Proyectos
              </a>
                            <a
                                className="link link-lvl-0"
                                href="/accionistas_e_inversores"
                                data-children="has-children"
                                onMouseOut={notHover}
                                onMouseOver={onHover}
                                data-section-id="accionistas_e_inversores"
                            >
                                Accionistas e inversores
                <Chevron_L />
                            </a>
                            <a
                                className="link link-lvl-0"
                                href="/actualidad"
                                data-children="no"
                                data-section-id="actualidad"
                                onMouseOut={notHover}
                                onMouseOver={onHover}
                            >
                                Actualidad
              </a>
                        </ul>
                    </nav>

                    <nav
                        className="nav__main nav__main--mobile"
                        ref={mobileNav}
                        data-is-visible="false"
                        role="navigation"
                        aria-label="Navegación principal para móvil"
                    >
                        <svg width="18" height="18" className="close-x" onClick={closeMenu}>
                            <path d="M10 2v6h6v1h-6v6H9V9H3V8h6V2h1z" fill="#1F140F" fillRule="evenodd"></path>
                        </svg>
                        <ul className="nav_links">
                            <button
                                data-section="sobre-acciona"
                                type="link"
                                data-is-open="false"
                                onClick={toggleMobileSubsection}
                                className="button taglabel button--md type__base subsection__toggle"
                            >
                                Nuestro propósito
                <Chevron_L />
                            </button>
                            <div data-section="sobre-acciona" className="nav_sub_section lvl-1" data-subsection-visible="false">
                                <a className="link link-lvl-1" href="/es/nuestro-proposito/historia/">
                                    Historia
                </a>
                                <a className="link link-lvl-1" href="/es/nuestro-proposito/iniciativas-que-apoyamos/">
                                    Iniciativas que apoyamos
                </a>
                                <button
                                    type="link"
                                    data-section="sostenibilidad"
                                    className="button taglabel button--md type__base subsection__toggle"
                                >
                                    Sostenibilidad
                </button>
                                <div data-section="sostenibilidad" className="nav_sub_section lvl-2" data-subsection-visible="false">
                                    <a className="link link-lvl-2" href="/es/nuestro-proposito/sostenibilidad/estrategia">
                                        Nuestra estrategia
                  </a>
                                    <a className="link link-lvl-2" href="/es/nuestro-proposito/sostenibilidad/objetivos">
                                        Objectivos de Desarollo sostenible
                  </a>
                                    <a className="link link-lvl-2" href="/es/nuestro-proposito/sostenibilidad/indices">
                                        Índices de sostenibilidad
                  </a>
                                    <a className="link link-lvl-2" href="/es/nuestro-proposito/sostenibilidad/alianzas-de-desarollo">
                                        Alianzas para el Desarollo Sostenible
                  </a>
                                    <a className="link link-lvl-2" href="/es/nuestro-proposito/sostenibilidad/emergencia-climatica">
                                        Emergencia Climática
                  </a>
                                    <a className="link link-lvl-2" href="/es/nuestro-proposito/sostenibilidad/desarollo-social">
                                        Desarollo Social
                  </a>
                                    <a className="link link-lvl-1" href="/es/nuestro-proposito/sostenibilidad">
                                        Portada de sostenibilidad
                  </a>
                                </div>
                                <button
                                    type="link"
                                    data-section="innovacion"
                                    className="button taglabel button--md type__base subsection__toggle"
                                >
                                    Innovación
                </button>
                                <div data-section="innovacion" className="nav_sub_section lvl-2" data-subsection-visible="false">
                                    <a className="link link-lvl-2" href="/es/nuestro-proposito/innovacion/innovacion-abierta">
                                        Innovación Abierta
                  </a>
                                    <a className="link link-lvl-2" href="/es/nuestro-proposito/innovacion/centros_i_d">
                                        Centros de I+D
                  </a>
                                    <a className="link link-lvl-2" href="/es/nuestro-proposito/innovacion/proyectos-innovacion">
                                        Proyectos de innovación
                  </a>
                                    <a className="link link-lvl-1" href="/es/nuestro-proposito/innovacion">
                                        Portada de innovación
                    <svg width="1em" height="1em" viewBox="0 -2 9 9">
                                            <path
                                                d="M5.067 4.396l-.487.477L.686.979l.88-.842 3.005 3.017L7.67.127l.89.852z"
                                                fill="#1F140F"
                                                fillRule="evenodd"
                                            ></path>
                                        </svg>
                                    </a>
                                </div>
                                <a className="link link-lvl-1" href="/es/nuestro-proposito/nuestra-fundacion">
                                    Nuestra fundación
                </a>
                                <a className="link link-lvl-1" href="/es/nuestro-proposito/razones-para-trabajar-con-acciona">
                                    Por qué trabajar con nosotros
                </a>
                                <a className="link link-lvl-0" href="/es/nuestro-proposito/">
                                    Portada de nuestro propósito
                </a>
                            </div>

                            <button
                                type="link"
                                data-section="soluciones"
                                data-is-open="false"
                                onClick={toggleMobileSubsection}
                                className="button taglabel button--md type__base subsection__toggle"
                            >
                                Soluciones
                <Chevron_L />
                            </button>
                            <div data-section="soluciones" className="nav_sub_section lvl-1" data-subsection-visible="false">
                                <a className="link link-lvl-1" href="/es/soluciones/energia">
                                    <EnergyIcon />
                                    Energía
                </a>
                                <a className="link link-lvl-1" href="/es/soluciones/transporte">
                                    <MovilidadIcon />
                                    Transporte
                </a>
                                <a className="link link-lvl-1" href="/es/soluciones/agua">
                                    <AguaIcon />
                                    Agua
                </a>
                                <a className="link link-lvl-1" href="/es/soluciones/social">
                                    <SocialIcon />
                                    Social
                </a>
                                <a className="link link-lvl-1" href="/es/soluciones/ciudades">
                                    <MovilidadIcon />
                                    Ciudades
                </a>
                                <a className="link link-lvl-1" href="/es/soluciones/inmobiliaria">
                                    <InmobiliariaIcon />
                                    Inmobiliaria
                </a>
                                <a className="link link-lvl-1" href="/es/soluciones/capital">
                                    <CapitalIcon />
                                    Capital
                </a>
                                <a className="link link-lvl-0" href="/es/soluciones">
                                    Portada de soluciones
                </a>
                            </div>
                            <a className="link link-lvl-0" href="/es/proyectos">
                                Proyectos
              </a>

                            <button
                                type="link"
                                onClick={toggleMobileSubsection}
                                data-is-open="false"
                                data-section="accionistas-e-inversores"
                                className="button taglabel button--md type__base subsection__toggle"
                            >
                                Accionistas e inversores
                <Chevron_L />
                            </button>

                            <div
                                data-section="accionistas-e-inversores"
                                className="nav_sub_section lvl-1"
                                data-subsection-visible="false"
                            >
                                <button type="link" className="button taglabel button--md type__base subsection__toggle">
                                    Información Financiera
                </button>
                                <div
                                    data-section="información-financiera"
                                    className="nav_sub_section lvl-2"
                                    data-subsection-visible="false"
                                >
                                    <a className="link link-lvl-2" href="/accionistas_e_inversores/informacion_financiera/informe_anual">
                                        Informe Anual Integrado
                  </a>
                                    <a
                                        className="link link-lvl-2"
                                        href="/accionistas_e_inversores/informacion_financiera/cuentas_anuales"
                                    >
                                        Cuentas anuales
                  </a>
                                    <a
                                        className="link link-lvl-2"
                                        href="/accionistas_e_inversores/informacion_financiera/informes_trimestrales"
                                    >
                                        Informes y Presentaciones Trimestrales
                  </a>
                                    <a
                                        className="link link-lvl-2"
                                        href="/accionistas_e_inversores/informacion_financiera/periodo_medio_de_pago"
                                    >
                                        Periodo Medio de Pago a Proveedores
                  </a>
                                    <a className="link link-lvl-2" href="/accionistas_e_inversores/informacion_financiera/rating">
                                        Rating
                  </a>
                                    <a className="link link-lvl-1" href="/accionistas_e_inversores/informacion_financiera">
                                        Portada de información financiera
                  </a>
                                </div>

                                <button
                                    type="link"
                                    data-section="informacion-bursatil"
                                    className="button taglabel button--md type__base subsection__toggle"
                                >
                                    Información Bursátil
                </button>
                                <div
                                    data-section="informacion-bursatil"
                                    className="nav_sub_section lvl-2"
                                    data-subsection-visible="false"
                                >
                                    <a className="link link-lvl-2" href="/accionistas_e_inversores/informacion_bursatil/dividendos">
                                        Dividendos
                  </a>
                                    <a
                                        className="link link-lvl-2"
                                        href="/accionistas_e_inversores/informacion_bursatil/opinion_analistas"
                                    >
                                        Opinión de Analistas
                  </a>
                                    <a
                                        className="link link-lvl-2"
                                        href="/accionistas_e_inversores/informacion_bursatil/agenda_de_inversor"
                                    >
                                        Agenda del Inversor
                  </a>
                                    <a
                                        className="link link-lvl-2"
                                        href="/accionistas_e_inversores/informacion_bursatil/financiacion_sostenible"
                                    >
                                        Financiación Sostenible
                  </a>
                                    <a className="link link-lvl-1" href="/accionistas_e_inversores/informacion_bursatil">
                                        Portada de información bursátil
                  </a>
                                </div>

                                <button
                                    type="link"
                                    data-section="gobierno-corporativo"
                                    className="button taglabel button--md type__base subsection__toggle"
                                >
                                    Gobierno corporativo
                </button>
                                <div
                                    data-section="gobierno-corporativo"
                                    className="nav_sub_section lvl-2"
                                    data-subsection-visible="false"
                                >
                                    <a className="link link-lvl-2" href="/accionistas_e_inversores/gobierno_corporativo/junta-general">
                                        Junta General de Accionistas
                  </a>
                                    <a
                                        className="link link-lvl-2"
                                        href="/accionistas_e_inversores/gobierno_corporativo/consejo-administracion-comisiones"
                                    >
                                        Consejo de Adminstración y Comisiones
                  </a>
                                    <a className="link link-lvl-2" href="/accionistas_e_inversores/gobierno_corporativo/comite-direccion">
                                        Comite de Dirección
                  </a>
                                    <a
                                        className="link link-lvl-2"
                                        href="/accionistas_e_inversores/gobierno_corporativo/informacion-accionarial"
                                    >
                                        Información Accionarial
                  </a>
                                    <a
                                        className="link link-lvl-2"
                                        href="/accionistas_e_inversores/gobierno_corporativo/informe-anual-gobierno"
                                    >
                                        Informe Anual de Gobierno Corporativo
                  </a>
                                    <a
                                        className="link link-lvl-2"
                                        href="/accionistas_e_inversores/gobierno_corporativo/remuneracion-consejeros"
                                    >
                                        Remuneración de los Consejeros
                  </a>
                                    <a className="link link-lvl-2" href="/accionistas_e_inversores/gobierno_corporativo/normas-gobierno">
                                        Normas de Gobierno
                  </a>
                                    <a className="link link-lvl-1" href="/accionistas_e_inversores/gobierno_corporativo">
                                        Portada de gobierno corporativo
                  </a>
                                </div>
                                <a className="link link-lvl-1" href="/accionistas_e_inversores/emisiones_de_deuda">
                                    Emisiones de deuda
                </a>
                                <a className="link link-lvl-1" href="/accionistas_e_inversores/hechos_relevantes">
                                    Hechos relevantes
                </a>
                                <a className="link link-lvl-0" href="/accionistas_e_inversores">
                                    Portada de accionistas e inversores
                </a>
                            </div>
                            <a className="link link-lvl-0" href="/actualidad">
                                Actualidad
              </a>
                        </ul>
                    </nav>
                </div>
                <>
                    <div ref={navDrawer} className="nav nav__drawer" data-section-active="" data-is-visible="false">
                        <nav
                            data-is-visible="false"
                            data-order="1"
                            className="nav_sub_section"
                            data-section="sobre_acciona"
                            role="navigation"
                            aria-label="Sobre acciona"
                        >
                            <div className="drawer__header grid">
                                <h1 className="drawer__header--title title--m">Somos expertos en diseñar un planeta mejor</h1>
                                <a href="nuestro-proposito" title="Nuestro propósito" className="section-home-link">
                                    <ArrowRight viewBox="0 0 94 94" />
                                </a>
                            </div>
                            <div className="section-links">
                                <div className="subsection-box">
                                    <a className="link" href="/es/nuestro-proposito/nuestro-proposito">
                                        Sobre acciona
                  </a>
                                    <a className="link" href="/es/nuestro-proposito/historia">
                                        Historia
                  </a>
                                    <a className="link" href="/es/nuestro-proposito/iniciativas-que-apoyamos">
                                        Iniciativas que apoyamos
                  </a>
                                    <a className="link" href="/es/nuestro-proposito/nuestra-fundacion">
                                        Nuestra fundación
                  </a>
                                </div>
                                <div className="subsection-box">
                                    <a className="link" href="/es/nuestro-proposito/sostenibilidad">
                                        Sostenibilidad
                  </a>
                                    <a className="link link-lvl-1" href="/es/nuestro-proposito/sostenibilidad/estrategia">
                                        Nuestra estrategia
                  </a>
                                    <a className="link link-lvl-1" href="/es/nuestro-proposito/sostenibilidad/objetivos">
                                        Objectivos de Desarollo sostenible
                  </a>
                                    <a className="link link-lvl-1" href="/es/nuestro-proposito/sostenibilidad/indices">
                                        Índices de sostenibilidad
                  </a>
                                    <a className="link link-lvl-1" href="/es/nuestro-proposito/sostenibilidad/alianzas-de-desarollo">
                                        Alianzas para el Desarollo Sostenible
                  </a>
                                    <a className="link link-lvl-1" href="/es/nuestro-proposito/sostenibilidad/emergencia-climatica">
                                        Emergencia Climática
                  </a>
                                    <a className="link link-lvl-1" href="/es/nuestro-proposito/sostenibilidad/desarollo-social">
                                        Desarollo Social
                  </a>
                                </div>
                                <div className="subsection-box">
                                    <a className="link" href="/es/nuestro-proposito/innovacion">
                                        Innovación
                  </a>
                                    <a className="link link-lvl-1" href="/es/nuestro-proposito/innovacion/innovacion-abierta">
                                        Innovación Abierta
                  </a>
                                    <a className="link link-lvl-1" href="/es/nuestro-proposito/innovacion/centros_i_d">
                                        Centros de I+D
                  </a>
                                    <a className="link link-lvl-1" href="/es/nuestro-proposito/innovacion/proyectos-innovacion">
                                        Proyectos de innovación
                  </a>
                                </div>
                                <div className="subsection-box">
                                    <a className="link" href="/es/nuestro-proposito/razones_para_trabajar_con_acciona">
                                        Por qué trabajar con nosotros
                  </a>
                                </div>
                            </div>
                        </nav>
                        <nav
                            data-is-visible="false"
                            data-order="2"
                            className="nav_sub_section"
                            data-section="soluciones"
                            role="navigation"
                            aria-label="Soluciones"
                        >
                            <div className="drawer__header grid">
                                <h1 className="drawer__header--title title--m">
                                    Ofrecemos soluciones para dar respuesta a los grandes desafíos globales
                </h1>
                                <a href="soluciones" title="Soluciones" className="section-home-link">
                                    <ArrowRight viewBox="0 0 94 94" />
                                </a>
                            </div>
                            <div className="section-links">
                                <a className="link" href="/soluciones/energia">
                                    <EnergyIcon />
                                    Energía
                </a>
                                <a className="link" href="/es/soluciones/transporte">
                                    <MovilidadIcon />
                                    Transporte
                </a>
                                <a className="link" href="/es/soluciones/agua">
                                    <AguaIcon />
                                    Agua
                </a>
                                <a className="link" href="/es/soluciones/social">
                                    <SocialIcon />
                                    Social
                </a>
                                <a className="link" href="/soluciones/ciudades">
                                    <CiudadesIcon />
                                    Ciudades
                </a>
                                <a className="link" href="/es/soluciones/inmobiliaria">
                                    <InmobiliariaIcon />
                                    Inmobiliaria
                </a>
                                <a className="link" href="/es/soluciones/capital">
                                    <CapitalIcon />
                                    Capital
                </a>
                            </div>
                        </nav>
                        <nav
                            data-is-visible="false"
                            data-order="4"
                            className="nav_sub_section"
                            data-section="accionistas_e_inversores"
                            role="navigation"
                            aria-label="Accionistas e inversores"
                        >
                            <div className="drawer__header grid">
                                <h1 className="drawer__header--title title--m">Somos expertos en diseñar un planeta mejor</h1>
                                <a href="accionistas" title="Accionistas e inversores" className="section-home-link">
                                    <ArrowRight viewBox="0 0 94 94" />
                                </a>
                            </div>
                            <div className="section-links">
                                <div className="subsection-box">
                                    <a className="link" href="/accionistas_e_inversores/informacion_financiera">
                                        Información Financiera
                  </a>
                                    <a className="link link-lvl-1" href="/accionistas_e_inversores/informacion_financiera/informe_anual">
                                        Informe Anual Integrado
                  </a>
                                    <a
                                        className="link link-lvl-1"
                                        href="/accionistas_e_inversores/informacion_financiera/cuentas_anuales"
                                    >
                                        Cuentas anuales
                  </a>
                                    <a
                                        className="link link-lvl-1"
                                        href="/accionistas_e_inversores/informacion_financiera/informes_trimestrales"
                                    >
                                        Informes y Presentaciones Trimestrales
                  </a>
                                    <a
                                        className="link link-lvl-1"
                                        href="/accionistas_e_inversores/informacion_financiera/periodo_medio_de_pago"
                                    >
                                        Periodo Medio de Pago a Proveedores
                  </a>
                                    <a className="link link-lvl-1" href="/accionistas_e_inversores/informacion_financiera/rating">
                                        Rating
                  </a>
                                </div>
                                <div className="subsection-box">
                                    <a className="link" href="/accionistas_e_inversores/informacion_bursatil">
                                        Información Bursátil
                  </a>
                                    <a className="link link-lvl-1" href="/accionistas_e_inversores/informacion_bursatil/dividendos">
                                        Dividendos
                  </a>
                                    <a
                                        className="link link-lvl-1"
                                        href="/accionistas_e_inversores/informacion_bursatil/opinion_analistas"
                                    >
                                        Opinión de Analistas
                  </a>
                                    <a
                                        className="link link-lvl-1"
                                        href="/accionistas_e_inversores/informacion_bursatil/agenda_de_inversor"
                                    >
                                        Agenda del Inversor
                  </a>
                                    <a
                                        className="link link-lvl-1"
                                        href="/accionistas_e_inversores/informacion_bursatil/financiacion_sostenible"
                                    >
                                        Financiación Sostenible
                  </a>
                                </div>
                                <div className="subsection-box">
                                    <a className="link" href="/accionistas_e_inversores/gobierno_corporativo">
                                        Gobierno corporativo
                  </a>
                                    <a className="link link-lvl-1" href="/accionistas_e_inversores/gobierno_corporativo/junta-general">
                                        Junta General de Accionistas
                  </a>
                                    <a
                                        className="link link-lvl-1"
                                        href="/accionistas_e_inversores/gobierno_corporativo/consejo-administracion-comisiones"
                                    >
                                        Consejo de Adminstración y Comisiones
                  </a>
                                    <a className="link link-lvl-1" href="/accionistas_e_inversores/gobierno_corporativo/comite-direccion">
                                        Comite de Dirección
                  </a>
                                    <a
                                        className="link link-lvl-1"
                                        href="/accionistas_e_inversores/gobierno_corporativo/informacion-accionarial"
                                    >
                                        Información Accionarial
                  </a>
                                    <a
                                        className="link link-lvl-1"
                                        href="/accionistas_e_inversores/gobierno_corporativo/informe-anual-gobierno"
                                    >
                                        Informe Anual de Gobierno Corporativo
                  </a>
                                    <a
                                        className="link link-lvl-1"
                                        href="/accionistas_e_inversores/gobierno_corporativo/remuneracion-consejeros"
                                    >
                                        Remuneración de los Consejeros
                  </a>
                                    <a className="link link-lvl-1" href="/accionistas_e_inversores/gobierno_corporativo/normas-gobierno">
                                        Normas de Gobierno
                  </a>
                                </div>
                                <div className="subsection-box">
                                    <a className="link" href="/accionistas_e_inversores/emisiones_de_deuda">
                                        Emisiones de deuda
                  </a>
                                    <a className="link" href="/accionistas_e_inversores/hechos_relevantes">
                                        Hechos relevantes
                  </a>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div
                        className="drawer-overlay"
                        onClick={toggleSubsectionVisibility}
                        onMouseOver={toggleSubsectionVisibility}
                    ></div>
                </>
            </header>
            <>
                <div className={'bottom_drawer grid countries_overlay'} ref={countriesRef} data-is-visible="false">
                    <div className="header" ref={swipeTargetHeader}>
                        <h3 className="title title--xs">
                            Nuestra presencia en el mundo
              <IconClose width="24" height="24" viewBox="0 0 16 16" onClick={closeCountriesOverlay} />
                        </h3>
                    </div>
                    <div className="body">
                        {countriesList.map((country, idx) => (
                            <a className="title--m" href={country.path} key={`country-item-${idx}`}>
                                {country.label}
                                <IconExternal width="18" height="18" viewBox="0 0 18 18" />
                            </a>
                        ))}
                    </div>
                </div>

                <div data-is-visible="false" className="module grid search_overlay" ref={searchRef}>
                    <div className="header">
                        <h3 className="title title--xs">
                            <IconClose onClick={closeSearchOverlay} />
                        </h3>
                    </div>
                    <div className="body">
                        <form className="search-input-wrapper">
                            <input onChange={handleInput} className="search-input" ref={searchInput} placeholder="Buscar" />
                            <button className="search-submit" onClick={handleSearchSubmit} type="button">
                                <SearchLense />
                            </button>
                        </form>
                        <div className="search-input-test-stand" ref={searchInputTestStand}></div>
                    </div>
                </div>
            </>
        </>
    );
};

export default Header;
