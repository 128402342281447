// button.component.js
import React, { useState } from 'react';
import './styles.scss';
import { CheckboxGroup, TagTitle } from 'React/components';
import { ReactComponent as IconSearch } from 'Icons/searchlense.svg';
import { ReactComponent as IconShow } from 'Icons/filteropen-16.svg';
import { ReactComponent as IconHide } from 'Icons/filterclose-16.svg';
import { useFade } from '../../custom_hooks';

const BuscadorFiltros = ({ type, title, yearFilter, searchFilter,
    yearsCombo, yearSelected, hideCombo,
    areasCombo, divisionsCombo, countriesCombo,
    relationsFilter, countrySelected, divisionSelected,
    areaSelected, jobTypeSelected, getListOffers}) => {

    const [filterState, setFilterState] = useState(false);
    const [filterAreaState, setFilterAreaState] = useState('');
    const [filterTerritoryState, setFilterTerritoryState] = useState('');
    const _btnIcon = () => (filterState === true ? <IconShow /> : <IconHide />);
    const _btnLabel = () => (filterState === true ? 'Mostrar filtros' : 'Ocultar filtros');

    const setFilterArea = (event) => {
        setFilterAreaState(event.target.value);
    };

    const setFilterTerritory = (event) => {
        setFilterTerritoryState(event.target.value);
    };

    let moduleRef = React.createRef();

    useFade(moduleRef);

    if (type === 'proyectos') {
        return (
            <div
                ref={moduleRef}
                className={`grid module module-buscadorfiltros--proyectos module-buscadorfiltros ${
                    filterState === true ? 'hide' : 'show'
                    }`}
            >
                <div className="grid-container">
                    <button id="button-filters" onClick={() => setFilterState((oldState) => !oldState)}>
                        <span className="taglabel">
                            <_btnIcon /> <_btnLabel />
                        </span>
                    </button>
                </div>
                <div className="grid-container">
                    <form className="filters-container">
                        <div className="selects-container">
                            <div className="select-container">
                                <div className="select">
                                    <select name="slct" id="slct" defaultValue={'DEFAULT'}>
                                        <option value="DEFAULT" disabled>
                                            Solución
                    </option>
                                        <option value="1">Pure CSS</option>
                                        <option value="2">No JS</option>
                                        <option value="3">Nice!</option>
                                    </select>
                                    <label className="taglabel">Solución</label>
                                </div>
                                <div className="select">
                                    <select name="slct" id="slct" defaultValue={'DEFAULT'}>
                                        <option value="DEFAULT" disabled>
                                            Área de actividad
                    </option>
                                        <option value="1">Pure CSS</option>
                                        <option value="2">No JS</option>
                                        <option value="3">Nice!</option>
                                    </select>
                                    <label className="taglabel">Área de actividad</label>
                                </div>
                                <div className="select">
                                    <select name="slct" id="slct" defaultValue={'DEFAULT'}>
                                        <option value="DEFAULT" disabled>
                                            País
                    </option>
                                        <option value="1">Pure CSS</option>
                                        <option value="2">No JS</option>
                                        <option value="3">Nice!</option>
                                    </select>
                                    <label className="taglabel">País</label>
                                </div>
                            </div>
                        </div>
                        <div className="checkbox-filters">
                            <div className="checkbox-filter">
                                <CheckboxGroup label="Tipo de proyecto" check1="innovación" check2="sostenibilidad" />
                            </div>
                            <div className="checkbox-filter">
                                <CheckboxGroup label="Estado" check1="Instalada" check2="En construcción" />
                            </div>
                            <div className="checkbox-filter">
                                <CheckboxGroup label="Titularidad" check1="En propiedad" check2="Cliente" />
                            </div>
                        </div>
                    </form>
                    <button type="button" className="search-btn">
                        <IconSearch />{' '}
                    </button>
                </div>
            </div>
        );
    }

    if (type === 'proyectos-simple') {
        return (
            <div
                ref={moduleRef}
                className={`slow grid module module-buscadorfiltros--proyectos module-buscadorfiltros--proyectos-simple module-buscadorfiltros ${
                    filterState === true ? 'hide' : 'show'
                    }`}
            >
                <div className="grid-container">
                    <form className="filters-container ">
                        <div className="selects-container select-proyectos-simple">
                            <div className="select-container">
                                <div className="select">
                                    <select name="slct" id="slct" defaultValue={'DEFAULT'}>
                                        <option value="DEFAULT" disabled>
                                            Solución
                    </option>
                                        <option value="1">Pure CSS</option>
                                        <option value="2">No JS</option>
                                        <option value="3">Nice!</option>
                                    </select>
                                    <label className="taglabel">Solución</label>
                                </div>
                                <div className="select">
                                    <select name="slct" id="slct" defaultValue={'DEFAULT'}>
                                        <option value="DEFAULT" disabled>
                                            País
                    </option>
                                        <option value="1">Pure CSS</option>
                                        <option value="2">No JS</option>
                                        <option value="3">Nice!</option>
                                    </select>
                                    <label className="taglabel">País</label>
                                </div>
                            </div>
                            <div className="checkbox-filters">
                                <div className="checkbox-filter">
                                    <CheckboxGroup label="Tipo de proyecto" check1="innovación" check2="sostenibilidad" />
                                </div>
                            </div>
                        </div>
                    </form>
                    <button type="button" className="search-btn">
                        <IconSearch />{' '}
                    </button>
                </div>
            </div>
        );
    }

    if (type === 'noticias') {
        return (
            <div ref={moduleRef} className="slow module grid  buscadorfiltros--noticias module-buscadorfiltros">
                <div className="grid-container">
                    {title && <TagTitle title={title} />}
                    {!hideCombo &&
                        <form className="filters-container">
                            <div className="select-container">
                                <div className="select">
                                    <select name="slct" id="slct" onChange={yearFilter} value={yearSelected}>
                                        <option selected value="">
                                            Año
                  </option>
                                        {yearsCombo.map((year, idx) => {
                                            return (
                                                <option value={year}>{year}</option>
                                            );
                                        })}
                                    </select>
                                    <label className="taglabel">Año</label>
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div>
        );
    }
    if (type === 'informes') {
        return (
            <div className={`grid module-buscadorfiltros  module-buscadorfiltros--informes`}>
                <div className="grid-container">
                    <form className="filters-container">
                        <div className="checkbox-filter">
                            <CheckboxGroup check1="Informes trimestrales" check2="Presentaciones trimestrales" event={searchFilter} />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
    if (type === 'empleo') {
        return (
            <div
                ref={moduleRef}
                className={`grid buscadorfiltros--ofertas module-buscadorfiltros ${filterState === true ? 'hide' : 'show'}`}
            >
                <div className="grid-container">
                    <button id="button-filters" onClick={() => setFilterState((oldState) => !oldState)}>
                        <span className="taglabel slow">
                            <_btnIcon /> <_btnLabel />
                        </span>
                    </button>
                </div>
                <div className="grid-container">
                    <form className="filters-container">
                        <div className="select-container slow">
                            <div className="select">
                                <select name="slct" id="countries" onChange={(e) => relationsFilter("countries", e)} value={countrySelected}>
                                    <option>
                                        Todos los países
                  </option>
                                    {countriesCombo.map((country, idx) => {
                                        return (
                                            <option value={country}>{country}</option>
                                        );
                                    })}
                                </select>
                                <label className="taglabel">País</label>
                            </div>
                            <div className="select">
                                <select name="slct" id="divisions" onChange={(e) => relationsFilter("divisions", e)} value={divisionSelected}>
                                    <option>
                                        Todas las soluciones
                  </option>
                                    {divisionsCombo.map((division, idx) => {
                                        return (
                                            <option value={division}>{division}</option>
                                        );
                                    })}
                                </select>
                                <label className="taglabel">Solución</label>
                            </div>
                            <div className="select">
                                <select name="slct" id="areas" onChange={(e) => relationsFilter("areas", e)} value={areaSelected}>
                                    <option>
                                        Todas las áreas
                  </option>
                                    {areasCombo.map((area, idx) => {
                                        return (
                                            <option value={area}>{area}</option>
                                        );
                                    })}
                                </select>
                                <label className="taglabel">Área funcional</label>
                            </div>
                            <div className="select">
                                <select name="slct" id="jobTypes" onChange={(e) => relationsFilter("jobType", e)} value={jobTypeSelected}>
                                    <option>
                                        Elige una opción
                  </option>
                                    <option value="Profesional">Profesional</option>
                                    <option value="Graduados">Graduados</option>
                                    <option value="Becas">Becas</option>
                                </select>
                                <label className="taglabel">Tipo de empleo</label>
                            </div>
                        </div>
                        <button type="button" className="search-btn slow" onClick={(e) => getListOffers(1, e)}>
                            <IconSearch />{' '}
                        </button>
                    </form>
                </div>
            </div>
        );
    }
    if (type === 'equipo') {
        return (
            <div ref={moduleRef} className={`grid buscadorfiltros--ofertas module-buscadorfiltros buscadorfiltros--equipo`}>
                <div className="grid-container">
                    <div className="module-title">{title && <TagTitle title={title} />}</div>
                    <form className="filters-container">
                        <div className="select-container slow">
                            <div className="select">
                                <select name="slct" id="slct" onChange={setFilterArea}>
                                    <option value="" selected>
                                        Todas las áreas
                  </option>
                                    {areasCombo.map((area, idx) => {
                                        return (
                                            <option value={area.value}>{area.name}</option>
                                        );
                                    })}
                                </select>
                                <label className="taglabel">Área/Perfil</label>
                            </div>
                            <div className="select">
                                <select name="slct" id="slct" onChange={setFilterTerritory}>
                                    <option value="" selected>
                                        Todos los territorios
                  </option>
                                    {yearsCombo.map((territory, idx) => {
                                        return (
                                            <option value={territory.value}>{territory.name}</option>
                                        );
                                    })}
                                </select>
                                <label className="taglabel">Territorio</label>
                            </div>
                        </div>
                        <button type="button" className="search-btn slow" onClick={() => searchFilter(filterAreaState, filterTerritoryState)}>
                            <IconSearch />{' '}
                        </button>
                    </form>
                </div>
            </div>
        );
    } else {
        return (
            <div className={`grid buscadorfiltros--ofertas module-buscadorfiltros ${filterState === true ? 'hide' : 'show'}`}>
                <div className="grid-container">
                    <button id="button-filters" onClick={() => setFilterState((oldState) => !oldState)}>
                        <span className="taglabel">
                            <_btnIcon /> <_btnLabel />
                        </span>
                    </button>
                </div>
                <div className="grid-container">
                    <form className="filters-container">
                        <div className="select-container">
                            <div className="select">
                                <select name="slct" id="slct">
                                    <option selected disabled>
                                        División
                  </option>
                                    <option value="1">Pure CSS</option>
                                    <option value="2">No JS</option>
                                    <option value="3">Nice!</option>
                                </select>
                                <label className="taglabel">División</label>
                            </div>
                            <div className="select">
                                <select name="slct" id="slct">
                                    <option selected disabled>
                                        Área funcional
                  </option>
                                    <option value="1">Pure CSS</option>
                                    <option value="2">No JS</option>
                                    <option value="3">Nice!</option>
                                </select>
                                <label className="taglabel">Área funcional</label>
                            </div>
                            <div className="select">
                                <select name="slct" id="slct">
                                    <option selected disabled>
                                        País
                  </option>
                                    <option value="1">Pure CSS</option>
                                    <option value="2">No JS</option>
                                    <option value="3">Nice!</option>
                                </select>
                                <label className="taglabel">País</label>
                            </div>
                        </div>
                        <button type="button" className="search-btn">
                            <IconSearch />{' '}
                        </button>
                    </form>
                </div>
            </div>
        );
    }
};

const changeFilterButton = (e) => {
    let textContent = e.currentTarget.textContent;

    if (textContent === 'Mostrar filtros') {
        e.currentTarget.textContent = 'Ocultar filtros';
    }
    if (textContent === 'Ocultar filtros') {
        e.currentTarget.textContent = 'Mostrar filtros';
    }
};

export default BuscadorFiltros;
