import React, { useState, useEffect } from 'react';
import { Link } from 'React/components';
import { rndKeyGen } from 'React/helpers';

import './styles.scss';

import { ReactComponent as IconClose } from 'Icons/cross.svg';
import { ReactComponent as IconDownload } from 'Icons/download.svg';
import { ReactComponent as ArrowRight } from 'Icons/arrow_circled.svg';
import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';

const EnlaceAgrupador = ({ box_label, box_type = 'link', links }) => {

    if (typeof window !== 'undefined') {
        const backdrop = React.createRef();
        const currentBox = React.createRef();
        const currentDrawer = React.createRef();
        const swipeTargetHeader = React.createRef();

        const appLayout = document.querySelector('.app--layout') != null;

        const useWindowWidth = () => {
            const [windowWidth, setWindowWidth] = useState(window.innerWidth);

            const handleWindowResize = () => {
                setWindowWidth(window.innerWidth);
                if (currentDrawer.current != null) {
                    currentDrawer.current.dataset.isVisible = false;
                    backdrop.current.dataset.isVisible = false;
                    document.querySelector('.app--layout').dataset.canScroll = true;
                }
            };

            useEffect(() => {
                window.addEventListener('resize', handleWindowResize);
                return () => window.removeEventListener('resize', handleWindowResize);
            }, []);

            return windowWidth;
        };

        const closeDrawer = () => {
            if (currentDrawer != null) {
                currentDrawer.current.dataset.isVisible = false;
                backdrop.current.dataset.isVisible = false;
                document.querySelector('.app--layout').dataset.canScroll = true;
            }
        }

        const setBoxVisibility = (value) => {


            if (value === null || value === undefined) {

                if (typeof window != undefined && window.innerWidth > 1024) {
                    const boxesArray = [...document.querySelectorAll('.links-tooltip')];

                    boxesArray.forEach(function (item) {
                        item.dataset.isVisible = false;
                    });

                    backdrop.current.dataset.isVisible = false;
                    currentBox.current.dataset.isVisible = true;

                } else {

                    const drawersArray = [...document.querySelectorAll('.bottom_drawer')];

                    drawersArray.forEach(function (item) {
                        item.dataset.isVisible = false;
                    });

                    currentBox.current.dataset.isVisible = false;
                    currentDrawer.current.dataset.isVisible = true;
                    backdrop.current.dataset.isVisible = true;

                    if (appLayout) {
                        document.querySelector('.app--layout').dataset.canScroll = false;
                    }
                }

            } else {

                if (currentBox.current != null) {
                    currentBox.current.dataset.isVisible = false;
                    backdrop.current.dataset.isVisible = true;
                }

                if (appLayout) {
                    document.querySelector('.app--layout').dataset.canScroll = true;
                }
            }
        };

        const BoxContent = () => (
            <>
                <div className="body">
                    <div className="links-list" onMouseLeave={() => currentBox.current.dataset.isVisible = false}>
                        {links.map((link) => {
                            if (link.umbracoFile) {
                                const { name, umbracoFile, umbracoBytes, umbracoExtension, type } = link;
                                return <Link type="download" path={umbracoFile} label={name} bytes={umbracoBytes} extension={umbracoExtension} key={rndKeyGen()} />;
                            }
                            else if (link.file) {
                                return <Link type="download" path={link.file} label={link.title} bytes={''} extension={'PDF'} key={rndKeyGen()} />;
                            }
                            else {
                                //const { caption, link, type } = link;
                                return <Link type={link.type ? link.type : 'download'} path={link.link ? link.link : ''} label={link.caption ? link.caption : ''} target={link.target} key={rndKeyGen()} />;
                            }

                        })}
                    </div>
                </div>
            </>
        );

        const Tooltip = ({ children }) => (
            <div data-is-visible="false" ref={currentBox} className="enlace-agrupador links-tooltip">
                {children}
            </div>
        );

        const BottomDrawer = ({ children }) => (
            <>
                <div className="bottom_drawer grid enlace-agrupador links-drawer" data-is-visible="false" ref={currentDrawer}>
                    <div className="header" ref={swipeTargetHeader}>
                        <h3 className="title title--xs">
                            <IconClose width="24" height="24" viewBox="0 0 16 16" onClick={closeDrawer} />
                        </h3>
                    </div>
                    <div className="body">
                        {children}
                    </div>
                </div>
                <div
                    className="bottom_drawer-backdrop" ref={backdrop}
                    data-is-visible="false"
                    onClick={closeDrawer}
                >
                </div>
            </>
        );

        return (
            <>
                <div className={`enalce--agrupador__label ${box_type}`} onClick={() => setBoxVisibility()}>
                    {box_type === 'link' && (
                        <>
                            <span className="link link--icon icon--red link--download">
                                <IconDownload />
                                {box_label}
                            </span>
                        </>
                    )}

                    {box_type === 'subscribe' && (
                        <>
                            <span className="link link--icon link--subscribe">
                                <div className="title--xs">
                                    <IconCalendar />
                                    Añadir al calendario
              </div>
                            </span>
                        </>
                    )}

                    {box_type === 'round-button' && <ArrowRight viewBox="0 0 94 94" />}
                    <Tooltip data-is-visible={useWindowWidth() >= 1024}>
                        <BoxContent />
                    </Tooltip>
                </div>
                <BottomDrawer>
                    <BoxContent />
                </BottomDrawer>
            </>
        );
    }
    else {
        return (<></>);
    }

};

export default EnlaceAgrupador;
