// button.component.js
import React from 'react';
import './styles.scss';
//import { Link } from 'React/components';

import { ReactComponent as LinkedinIcon } from 'Icons/linkedin.svg';
import { ReactComponent as FacebookIcon } from 'Icons/facebook.svg';
import { ReactComponent as TwitterIcon } from 'Icons/twitter.svg';
import { ReactComponent as WhatsappIcon } from 'Icons/whatsapp.svg';


const ShareSocial = ({ moduleTitle }) => {
    return (
        <div className="module grid module-sharesocial">
            <div className="module-sharesocial-container grid full-width">
                <h2 className="title--s">{moduleTitle}</h2>
                <nav className="sharesocial-nav">
                    <a href="#" ><LinkedinIcon /></a>
                    <a href="#" ><FacebookIcon /></a>
                    <a href="#" ><TwitterIcon /></a>
                    <a href="#" className="link mobile-visible" ><WhatsappIcon /></a>
                </nav>
            </div>
        </div>
    )
}


export default ShareSocial;
