import React from 'react';

import { ReactComponent as Agua } from 'Icons/soluciones/agua.svg';
import { ReactComponent as Civil } from 'Icons/soluciones/civil.svg';
import { ReactComponent as EconomiaCircular } from 'Icons/soluciones/economiacircular.svg';
import { ReactComponent as Energy } from 'Icons/soluciones/energia.svg';
import { ReactComponent as Inmo } from 'Icons/inmobiliaria-24.svg';
import { ReactComponent as Movilidad } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as Social } from 'Icons/soluciones/social.svg';

const iconsMap = {
    'agua': <Agua width="18" height="18" viewBox="0 0 24 24" />,
    'civil': <Civil width="18" height="18" viewBox="0 0 24 24" />,
    'economia circular': <EconomiaCircular width="18" height="18" viewBox="0 0 24 24" />,
    'energía': <Energy width="18" height="18" viewBox="0 0 24 24" />,
    'inmobiliaria': <Inmo width="18" height="18" viewBox="0 0 24 24" />,
    'movilidad': <Movilidad width="18" height="18" viewBox="0 0 24 24" />,
    'social': <Social width="18" height="18" viewBox="0 0 24 24" />,
};

const iconsMapLarge = {
    'agua': <Agua width="24" height="24" viewBox="0 0 24 24" />,
    'civil': <Civil width="24" height="24" viewBox="0 0 24 24" />,
    'economia circular': <EconomiaCircular width="24" height="24" viewBox="0 0 24 24" />,
    'energía': <Energy width="24" height="24" viewBox="0 0 24 24" />,
    'inmobiliaria': <Inmo width="24" height="24" viewBox="0 0 24 24" />,
    'movilidad': <Movilidad width="24" height="24" viewBox="0 0 24 24" />,
    'social': <Social width="24" height="24" viewBox="0 0 24 24" />,
};

export { iconsMap, iconsMapLarge };
