import React, {useState, useEffect} from 'react';
import { BuscadorFiltros, TrabajaConNosotrosSimple, Paginator } from 'React/components';

//ToDo: Cojer el lenguaje del idioma de la app
const WorkDay = () => {

    const [areasState, setAreasState] = useState([]);
    const [divisionsState, setDivisionsState] = useState([]);
    const [countriesState, setCountriesState] = useState([]);
    const [areasSelectedState, setAreasSelectedState] = useState('');
    const [divisionsSelectedState, setDivisionsSelectedState] = useState('');
    const [countriesSelectedState, setCountriesSelectedState] = useState('');
    const [jobTypeSelectedState, setJobTypeSelectedState] = useState('');
    const [listOffersState, setlistOffersState] = useState([]);
    const [actualPagesList, setActualPagesList] = useState([]);
    const [pagesListState, setPagesListState] = useState([]);
    const [actualPageState, setActualPageState] = useState(1);


    var country = '';
    var area = '';
    var division = '';
    var jobtype = '';
   
    const getComboData = () => {

        try {
            //ToDo: mirar como hacerlo async
            if (areasState.length === 0 && divisionsState.length === 0 && countriesState.length === 0) {
                Promise.all([
                    fetch('/api/CmsController/GetAreas?language=es', {
                        method: 'GET'
                    }).then(data => data.json()),
                    fetch('/api/CmsController/GetDivisions?language=es', {
                        method: 'GET'
                    }).then(data => data.json()),
                    fetch('/api/CmsController/GetCountries?language=es', {
                        method: 'GET'
                    }).then(data => data.json())

                ]).then((data) => {
                    setAreasState(data[0]);
                    setDivisionsState(data[1]);
                    setCountriesState(data[2]);
                });
            }
    }

    catch (err) {
        console.log(err);
    }
    };

   
    const getOffers = (page, event) => {

                var url = `/api/CmsController/GetListOffersPaginated?language=es&pageIndex=${page}&includeFeaturedItems=true&source=${jobTypeSelectedState}&division=${divisionsSelectedState}&country=${countriesSelectedState}&area=${areasSelectedState}`;
                const xhrPages = new XMLHttpRequest();
                xhrPages.open('get', url, true);
                xhrPages.onload = function (e) {
                    if (xhrPages.readyState === 4) {
                        if (xhrPages.status === 200) {

                    var offerList = JSON.parse(xhrPages.responseText);
                   
                    const sidePages = 7;

                    var nextList = [];
                    var befList = [];
                            nextList = offerList.pagePagination.pagesList.slice(page - 1, page + sidePages);
                            befList = offerList.pagePagination.pagesList.slice((page - (sidePages + 1) < 0 ? 0 : page - (sidePages + 1)), page);

                    if (actualPagesList.length === 0 && page !== 1) {

                        setActualPagesList(nextList);
                    }

                    else {

                        if (page === 1) {
                            setActualPagesList(nextList);
                        }
                        else if (page === offerList.pagePagination.pagesList[offerList.pagePagination.pagesList.length - 1].num) {
                            setActualPagesList(befList);
                        }
                        else if (actualPagesList.length > 0 && page === actualPagesList[actualPagesList.length - 1].num) {
                            setActualPagesList(nextList);
                        }
                        else if (page === offerList.pagePagination.pagesList[offerList.pagePagination.pagesList.length - 1].num || page === actualPagesList[0].num) {
                            setActualPagesList(befList);
                        }
                        else if (actualPagesList.length > 0 && page < actualPagesList[0].num) {
                            befList = offerList.pagePagination.pagesList.slice(((page + 1) - (sidePages + 1) < 0 ? 0 : (page + 1) - (sidePages + 1)), page + 1);
                            setActualPagesList(befList);
                        }
                        else if (page > offerList.pagePagination.pagesList[offerList.pagePagination.pagesList.length - 1].num) {
                            nextList = offerList.pagePagination.pagesList.slice(((page - 1) - (sidePages + 1) < 0 ? 0 : (page - 1) - (sidePages + 1)), page - 1);
                            setActualPagesList(nextList);
                        }
                    }

                        //if (data[0].offerList.length < 9) {
                        //    var offer = {
                        //        id: "",
                        //        title: "",
                        //        areaId: "",
                        //        division: "",
                        //        countryId: "",
                        //        region: "",
                        //        location: "",
                        //        description: "",
                        //        date: "",
                        //        url: "",
                        //        subtype: ""
                        //    };

                        //    var size = 9 - data[0].offerList.length;
                        //    for (var i = 0; i < size; i++) {
                        //        data[0].offerList.push(offer);
                        //    }

                        //}

                            setlistOffersState(offerList.offerList);
                            setPagesListState(offerList.pagePagination.pagesList);

                    } else {
                        console.error(xhrPages.statusText);
                    }
                }
            }.bind(this);

            xhrPages.send(null);


        };

    const changePage = (page) => {

        document.getElementById("header").scrollIntoView();

        setActualPageState(page);
        getOffers(page);
    };

    const nextPage = () => {

        document.getElementById("header").scrollIntoView();

        if (actualPageState < pagesListState.length) {
            setActualPageState(actualPageState + 1);
            getOffers(actualPageState + 1);
        }
    };

    const prevPage = () => {

        document.getElementById("header").scrollIntoView();

        if (actualPageState > 1) {
            setActualPageState(actualPageState - 1);
            getOffers(actualPageState - 1);
        }
    };

    //ToDo: Mejorar éste metodo, intentar no cojer literales
    const onChangeFilter = (valueSelected, event) => {
         
        country = countriesSelectedState ? countriesSelectedState : '';
        area = areasSelectedState ? areasSelectedState : '';
        division = divisionsSelectedState ? divisionsSelectedState : '';
        jobtype = jobTypeSelectedState ? jobTypeSelectedState : "Profesional";

        if (valueSelected === 'countries') {
            if (event.target.value === 'Todos los países') {
                setCountriesSelectedState(event.target.value);
                country = '';
            }
            else {
                setCountriesSelectedState(event.target.value);
                country = event.target.value;
            }
        }
        if (valueSelected === 'areas') {
            if (event.target.value === 'Todas las áreas') {
                setAreasSelectedState(event.target.value);
                area = '';
            }
            else {
                setAreasSelectedState(event.target.value);
                area = event.target.value;
            }          
        }
        if (valueSelected === 'divisions') {
            if (event.target.value === 'Todas las soluciones') {
                setDivisionsSelectedState(event.target.value);
                division = '';
            }
            else {
                setDivisionsSelectedState(event.target.value);
                division = event.target.value;
            }
        }
        if (valueSelected === 'jobType') {
            if (event.target.value === 'Elige una opción') {
                setJobTypeSelectedState(event.target.value);
                jobtype = '';
            }
            else {
                setJobTypeSelectedState(event.target.value);
                jobtype = event.target.value;
            }
        }

        if (country !== '' && country !== undefined && event.target.value !== 'Todos los países'
            || area !== '' && area !== undefined && event.target.value !== 'Todas las áreas'
            || division !== '' && division !== undefined && event.target.value !== 'Todas las soluciones'
            || jobtype !== '' && jobtype !== undefined && event.target.value !== 'Elige una opción') {

            try {
                Promise.all([
                    fetch(`/api/CmsController/GetFilter?source=${jobtype}&language=es&nameDivision=${division}&nameCountry=${country}&nameArea=${area}`, {
                        method: 'GET'
                    }).then(data => data.json())
                ]).then((data) => {
                    setAreasState(data[0].areas);
                    setDivisionsState(data[0].divisions);
                    setCountriesState(data[0].countries);
                });
            }
            catch (err) {
                console.log(err);
            }
                }
       
        else {
            try {
                Promise.all([
                    fetch('/api/CmsController/GetAreas?language=es', {
                        method: 'GET'
                    }).then(data => data.json()),
                    fetch('/api/CmsController/GetDivisions?language=es', {
                        method: 'GET'
                    }).then(data => data.json()),
                    fetch('/api/CmsController/GetCountries?language=es', {
                        method: 'GET'
                    }).then(data => data.json())

                ]).then((data) => {
                    setAreasState(data[0]);
                    setDivisionsState(data[1]);
                    setCountriesState(data[2]);
                });
            }
            catch (err) {
                console.log(err);
            }
        }      
    };

    useEffect(() => {
        getComboData();
        getOffers(1);
    }, [setPagesListState]);

    return (
        <div className=''>
            <BuscadorFiltros type='empleo' areasCombo={areasState} divisionsCombo={divisionsState} countriesCombo={countriesState} relationsFilter={onChangeFilter} countrySelected={countriesSelectedState} divisionSelected={divisionsSelectedState} areaSelected={areasSelectedState} jobTypeSelected={jobTypeSelectedState} getListOffers={getOffers} />
            <TrabajaConNosotrosSimple frames={listOffersState} />
            {pagesListState.length > 1 &&
                <Paginator pagesList={pagesListState} actualPage={actualPageState} changePage={changePage} nextPage={nextPage} prevPage={prevPage} actualPagesList={actualPagesList} />
            }
        </div>
    );
};

export default WorkDay;